import { removeAlertFromMessage, setAlert, AlertSeverity } from './alertActions';

// API
import { getSetupMode } from '../../api';

export const SETUP_MODE_LOADING = 'SETUP_MODE_LOADING';
export const SETUP_MODE_ON = 'SETUP_MODE_ON';
export const SETUP_MODE_OFF = 'SETUP_MODE_OFF';
const SETUP_MODE_MESSAGE = 'SECURITY VULNERABILITY: Setup Mode is on.';

export const setupModeLoading = () => ({
  type: SETUP_MODE_LOADING
});

export const setupModeOn = () => ({
  type: SETUP_MODE_ON
});

export const setupModeOff = () => ({
  type: SETUP_MODE_OFF
});

export const turnOnSetupMode = (dispatch) => {
  setAlert(dispatch, SETUP_MODE_MESSAGE, AlertSeverity.WARNING, false, null, false);
  dispatch(setupModeOn());
};

export const turnOffSetupMode = (dispatch) => {
  dispatch(removeAlertFromMessage(SETUP_MODE_MESSAGE));
  dispatch(setupModeOff());
};

export const checkSetupMode = (dispatch) => {
  dispatch(setupModeLoading());
  getSetupMode().then(setupMode => {
    if (setupMode.setupMode) turnOnSetupMode(dispatch);
    else turnOffSetupMode(dispatch);
  }).catch(() => turnOffSetupMode(dispatch));
};

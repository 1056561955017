import { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import AuthRedirect from '../navigation/AuthRedirect';
import PropTypes from 'prop-types';
import React from 'react';

// Redux
import { connect } from 'react-redux';
import { stopShowAlertRoom } from '../redux/actions/statusActions';

class PageWrapper extends Component {
  render() {
    if (this.props.redirectToRoom) {
      this.props.stopShowAlertRoom();
      return <Redirect to={{
        pathname: '/dashboard/map',
        search: '?roomID=' + this.props.roomID
      }} />;
    }
    return AuthRedirect(this.props.location.pathname, this.props.authProps) || this.props.children;
  }
}

const mapStateToProps = state => ({
  authProps: state.auth,
  schoolStatus: state.status.schoolStatus,
  redirectToRoom: state.status.showAlertRoom,
  roomID: state.status.roomID
});

const mapDispatchToProps = dispatch => ({
  stopShowAlertRoom: () => dispatch(stopShowAlertRoom())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageWrapper));

PageWrapper.propTypes = {
  redirectToRoom: PropTypes.bool,
  stopShowAlertRoom: PropTypes.func,
  roomID: PropTypes.number,
  location: PropTypes.object,
  authProps: PropTypes.any,
  children: PropTypes.object
};

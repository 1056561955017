import React from 'react';
import PropTypes from 'prop-types';

// Components
import MapPanel from '../../../components/MapPanel';
import RoomStatusZone from '../../../components/RoomStatusZone';

// Constants
import { DEFAULT, AlertTypes } from '../../../constants';

// Props: rooms, onRoomSelection, selectedRoom
export default class MapRoomView extends React.Component {

  render() {
    return (
      <MapPanel floorPlanImagePath={this.props.floorPlanImagePath} statusColor={this.props.statusColor} style={this.props.style}>
        {this.props.rooms.map((room, index) => (
          <RoomStatusZone
            key={index}
            statusColor={room.getAlertStatus() ? room.getAlertStatus().getColor() : DEFAULT}
            vertices={room.getVertices()}
            onClick={() => this.props.onRoomSelection(room)}
            isSelected={this.props.selectedRoom && this.props.selectedRoom.getID() === room.getID()}
            blinking={room?.getAlertStatus()?.getDashboardStatus() === AlertTypes.EMERGENCY.getDashboardStatus() && room?.getAlertUpdates()?.reverse()?.find(au => au.getAlertType()?.getDashboardStatus() === AlertTypes.EMERGENCY.getDashboardStatus() && au.isActive) != null}
          />
        ))}
      </MapPanel>
    );
  }
}

MapRoomView.propTypes = {
  rooms: PropTypes.array,
  floorPlanImagePath: PropTypes.string,
  statusColor: PropTypes.object,
  onRoomSelection: PropTypes.func,
  selectedRoom: PropTypes.object,
  style: PropTypes.object
};

import React, { Component } from 'react';
import { Grid, Fab, InputBase, Typography } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Icons
import SearchIcon from '@material-ui/icons/Search';

// Constants
import UserListCard from './UserListCard';

// Components
import FilterWidget from '../../../components/FilterWidget';
import { FilterOptions } from '../../../constants';

function UserListPanel({ statusColor, onCardSelection, panelHeight, selectedCardObject, users, onRefresh }) {
  const [filteredUsers, setFilteredUsers] = React.useState(users);
  const [searchCriteria, setSearchCriteria] = React.useState('');

  const getCards = () => {
    if (!filteredUsers) {
      return <Typography variant='body1' style={{ color: 'white' }}>Loading...</Typography>;
    }
    else if (filteredUsers.length === 0) {
      return <Typography variant='body1' style={{ color: 'white' }}>Nothing to show.</Typography>;
    }

    var regex = new RegExp(searchCriteria, 'i');

    return filteredUsers.filter(user => {
      return regex.test(user.getName()) || regex.test(user.getEmail());
    }).map((user, index) => (
      <UserListCard key={index} user={user} onClick={() => onCardSelection(user)} selectedCardObject={selectedCardObject} />
    ));
  };

  return (
    <div style={{ width: '100%' }}>

      {/* --- Search Bar --- */}
      <Grid container direction='row' style={{ alignItems: 'center', marginBottom: '15px' }}>
        <FilterWidget
          statusColor={statusColor}
          filterOptions={FilterOptions.USER_LIST}
          data={users}
          updateFilteredData={(filteredUsers) => setFilteredUsers(filteredUsers)}
        />

        <Fab variant="extended" style={{ backgroundColor: statusColor.main, flex: 1, marginLeft: '10px' }}>
          <InputBase
            style={{ marginLeft: '10px', flex: 1 }}
            placeholder="Search"
            inputProps={{ 'aria-label': 'search list' }}
            onChange={(e) => setSearchCriteria(e.target.value)}
          />
          <SearchIcon />
        </Fab>

        <Fab style={{ marginLeft: '10px' }} onClick={onRefresh}>
          <RefreshIcon />
        </Fab>
      </Grid>

      {/* --- User Cards --- */}
      <div style={{ width: '100%', height: panelHeight, overflow: 'auto' }}>
        <Typography variant='h1' style={{ textAlign: 'center' }}>
          {getCards()}
        </Typography>
      </div>
    </div>
  );
}

class UserListPanelWrapper extends Component {
  render() {
    return (
      <UserListPanel {...this.props} />
    );
  }
}

const mapStateToProps = (state) => ({
  panelHeight: state.ui.windowSize.height - 175 // Maintain nice margins
});

export default connect(mapStateToProps, null)(UserListPanelWrapper);

UserListPanel.propTypes = {
  statusColor: PropTypes.object,
  onCardSelection: PropTypes.func,
  selectedCardObject: PropTypes.object,
  panelHeight: PropTypes.number,
  onRefresh: PropTypes.func,
  users: PropTypes.array
};

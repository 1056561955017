import React, { Component } from 'react';
import {
  Button,
  Container, TextField, Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ReactPhoneInput from 'react-phone-input-material-ui';

// Redux
import { connect } from 'react-redux';
import { clearErrors, setAlert, setSuccess } from '../../../../../redux/actions/alertActions';

// Constants
import { DEFAULT } from '../../../../../constants';
import { getRedAlertTextGroup, addRedAlertTextContact } from '../../../../../api';

class AddRedAlertTextContact extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      redAlertTextGroup: null,
      name: '',
      phoneNumber: '',
    };
  }

  componentDidMount() {
    const id = this.props.match?.params?.id;

    getRedAlertTextGroup(id).then(redAlertTextGroup => {
      this.setState({ redAlertTextGroup });
    }).catch(error => {
      this.props.setError(error ?? 'Failed to load red alert text group.');
    });
  }

  onSubmit = (e) => {
    e.preventDefault();

    const { phoneNumber, name } = this.state;

    if (phoneNumber.length != 11) {
      this.props.setError('Phone number must be 11 digits (including country code)');
      return;
    }

    this.setState({ isLoading: true });
    addRedAlertTextContact(this.state.redAlertTextGroup?.getID(), { phoneNumber, name }).then(() => {
      this.props.setSuccess('Successfully added contact');
      this.setState({ name: '', phoneNumber: '', isLoading: false });
    }).catch(error => {
      this.props.setError(error ?? 'Failed to add contact');
      this.setState({ isLoading: false });
    });
  }

  render() {
    return (
      <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant='h1'>Add a Contact to {this.state.redAlertTextGroup?.getName()}</Typography>
        <form onSubmit={this.onSubmit} style={{ width: '100%' }}>
          <TextField required name='name' label='Name' disabled={this.state.isLoading} style={{ width: '100%', marginBottom: '10px' }} onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} variant='filled' />
          <ReactPhoneInput
            country='us'
            onlyCountries={['us']}
            value={this.state.phoneNumber}
            onChange={(phoneNumber) => this.setState({ phoneNumber })}
            component={TextField}
            inputProps={{ variant: 'filled', required: true, label: 'Phone Number', disabled: this.state.isLoading }}
            containerStyle={{ width: '100%', marginBottom: '10px' }}
          />

          <Button type='submit' variant='contained' disabled={this.state.isLoading} style={{ width: '100%', backgroundColor: this.props.statusColor.main, color: this.props.statusColor.text }}>Add Contact</Button>
        </form>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  statusColor: state.status.schoolStatus ? state.status.schoolStatus.getColor() : DEFAULT,
  isMobile: state.ui.isMobile,
});

const mapDispatchToProps = dispatch => ({
  setSuccess: (msg) => setSuccess(dispatch, msg),
  setError: (msg) => {
    dispatch(clearErrors());
    setAlert(dispatch, msg);
  },
  clearErrors: () => dispatch(clearErrors())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddRedAlertTextContact));

AddRedAlertTextContact.propTypes = {
  clearErrors: PropTypes.func,
  setSuccess: PropTypes.func,
  statusColor: PropTypes.object,
  setError: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired
};

import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

// Icons
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Settings from '@material-ui/icons/Settings';

// Constants
import { GREY,  GREY_SCALE, GREEN, isNotNull } from '../constants';

// Icons
import Hallway from '../images/customIcons/Hallway.png';
import Room from '../images/customIcons/Room.png';
import Door from '../images/customIcons/Door.png';

function getIcon(hubType) {
  switch (hubType) {
    case 'Hallway':
      return Hallway;
    case 'Room':
      return Room;
    case 'Door':
      return Door;
    default:
      return;
  }
}

function StatusAndVersion(hub, isInfoPanel) {
  return (
    <div>
      <Grid container direction='row' justify={isInfoPanel ? 'flex-start' : 'center'}>
        {/* --- ICON --- */}
        <Grid item style={{ display: 'flex', alignItems: 'center' }}>
          {hub.getIsConnected() ? <CheckCircleIcon style={{ color: GREEN.main, fontSize: 40 }} /> : <CancelIcon style={{ color: GREY.main, fontSize: 40 }} />}
        </Grid>

        {/* --- TEXT --- */}
        <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Grid container direction='column' justify='center'>
            {/* Connection Status */}
            <Grid item style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Typography variant='h6' style={{ marginLeft: '5px', color: GREY.dark }}>{hub.getIsConnected() ? 'Connected' : 'Not Connected'}</Typography>
            </Grid>

            {/* Battery Level */}
            {hub.getIsConnected() && hub.getBatteryLevel() !== null && hub.getBatteryLevel() != null && <Grid item style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Typography variant='button' style={{ marginLeft: '5px', color: GREY.main, marginTop: '-7px' }}>Battery {Math.floor(hub.getBatteryLevel())}%</Typography>
            </Grid>}
          </Grid>
        </Grid>
      </Grid>
      {isNotNull(hub.getFirmwareVersion()) &&
        <>
          <Grid item container direction='row' justify={'center'}>
            {/* --- ICON --- */}
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Settings style={{ color: GREEN.main, fontSize: (isInfoPanel ? 20 : 40) }} />
            </Grid>
            <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Grid container direction='column' justify='center'>
                {/* Firmware Version */}
                <Grid item style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Typography variant={isInfoPanel ? 'body1' : 'h6'} style={{ marginLeft: '5px', color: GREY.dark }}>{'v' + hub.getFirmwareVersion()}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      }
    </div>
  );
}

function Widget({ hub, isInfoPanel, isMobile, isFullWidth }) {
  return (isInfoPanel ?
    <>
      <div style={{ display: 'flex', flexDirection: (!isMobile && !isFullWidth ? 'column' : 'row'), width: '85%', justifyContent: 'center', alignItems: 'center', backgroundColor: GREY_SCALE[1], paddingTop: '15px', paddingBottom: '15px', borderRadius: '20px', margin: 'auto', boxShadow: '1px 7px 15px -4px rgba(0,0,0,0.7)' }}>
        <Grid item xs={4} md={3} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: (isMobile ? 'center' : 'center'), marginRight: (!isMobile && !isFullWidth ? '0px' : '15px') }}>
          <img src={getIcon(hub.getHubType())}
            alt={hub.getHubType() + ' Image'}
            style={{ width: '80px', height: '80px' }}
          />
          <Typography variant='h6'>{hub.getHubType()}</Typography>
        </Grid>
        <Grid item xs={8} md={9} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: (isMobile ? 'center' : 'flex-start'), overflow: 'hidden' }}>
          <Typography noWrap variant='h5' style={{ fontWeight: 'bold' }}>{hub.getSerialNumber()}</Typography>
          {StatusAndVersion(hub, isInfoPanel)}
        </Grid>
      </div>
      <div style={{ height: '20px' }}></div>
    </>
    :
    <Grid container direction='column' justify='center'>
      {StatusAndVersion(hub, isInfoPanel)}
    </Grid>
  );
}

export default function HubStatusWidget({ style = {}, hub, hubs, isInfoPanel = false, isMobile, isFullWidth }) {
  return (
    <Grid container direction='column' style={{ ...style }} justify='center'>

      <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
        <Typography variant='overline' style={{ color: GREY.dark }}>Hub Status</Typography>
      </Grid>

      {hub && <Widget hub={hub} isInfoPanel={isInfoPanel} isMobile={isMobile} isFullWidth={isFullWidth} />}
      {hubs && hubs.map((hub, index) => <Widget key={index} hub={hub} isInfoPanel={isInfoPanel} isMobile={isMobile} isFullWidth={isFullWidth} />)}
    </Grid>
  );
}

HubStatusWidget.propTypes = {
  hub: PropTypes.object,
  hubs: PropTypes.array,
  isInfoPanel: PropTypes.bool,
  isMobile: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  style: PropTypes.object,
};

Widget.propTypes = {
  hub: PropTypes.object,
  isInfoPanel: PropTypes.bool,
  isMobile: PropTypes.bool,
  isFullWidth: PropTypes.bool
};
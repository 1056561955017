import React from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { checkSchoolStatus } from './redux/actions/statusActions';

class StatusUpdate extends React.Component {
  componentDidMount() {
    if (!this.props.isLoading) this.props.checkSchoolStatus();
    this.retrieveStatus();
  }

  retrieveStatus() {
    this.timer = setInterval(
      () => {
        clearInterval(this.timer);
        if (!this.props.isLoading) {
          this.props.checkSchoolStatus();
        }
        this.retrieveStatus();
      },
      5000
    );
  }

  componentWillUnmount() {
    this.timer && clearInterval(this.timer);
  }

  render() {
    return null;
  }
}

const mapStateToProps = state => ({
  isLoading: state.status.isLoading
});

const mapDispatchToProps = dispatch => ({
  checkSchoolStatus: () => checkSchoolStatus(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(StatusUpdate);

StatusUpdate.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  checkSchoolStatus: PropTypes.func.isRequired
};

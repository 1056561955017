import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

// Constants
import { GREY, DeviceTypes } from '../constants';
import DefaultPortrait from '../images/DefaultPortrait.png';

export default function StaffWidget({ style = {}, displayType, staff }) {
  return (
    <Grid container direction='column' justify='center' style={style}>

      {staff && staff.length > 0 &&
      <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
        <Typography variant='overline' style={{ color: GREY.dark, marginBottom: '-0.5em' }}>
          {displayType === DeviceTypes.ROOMS && 'Staff Near Classroom'}
          {displayType === DeviceTypes.HUBS && 'Staff Connected'}
        </Typography>
      </Grid>}

      {staff && staff.map((staffMember, index) =>
        <StaffMemberView key={index} staffMember={staffMember} />
      )}

    </Grid>
  );
}

function StaffMemberView({ staffMember }) {
  if (!staffMember) return null;
  return (
    <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
      <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: '10px', paddingRight: '10px' }}>
        <img
          height={50}
          mode={'fill'}
          src={staffMember.getImagePath() ?? DefaultPortrait}
          alt={'Staff Member'}
          style={{ marginTop: '10px', borderRadius: '65px' }} />
      </Grid>
      <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant='h6' style={{ paddingLeft: '10px', paddingRight: '10px', color: 'black' }}>
          {staffMember.getName()}
        </Typography>
      </Grid>
    </Grid>
  );
}

StaffWidget.propTypes = {
  style: PropTypes.object,
  staff: PropTypes.array,
  displayType: PropTypes.any
};

StaffMemberView.propTypes = {
  style: PropTypes.object,
  staffMember: PropTypes.object
};

import {
  SETUP_MODE_LOADING,
  SETUP_MODE_ON,
  SETUP_MODE_OFF
} from '../actions/setupModeActions';

const initialState = {
  mode: false,
  isLoading: true
};

const setupMode = (state = initialState, action) => {
  switch (action.type) {
    case SETUP_MODE_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case SETUP_MODE_ON:
      return {
        mode: true,
        isLoading: false
      };
    case SETUP_MODE_OFF:
      return {
        mode: false,
        isLoading: false
      };
    default:
      return state;
  }
};

export default setupMode;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { DEFAULT } from '../../../constants';

// Redux
import { connect } from 'react-redux';
import { setSuccess, clearErrors, setAlert } from '../../../redux/actions/alertActions';

// Icons
import DeleteIcon from '@material-ui/icons/Delete';

// Components
import ListTable from '../../../components/ListTable';
import ConfirmationModal from '../../../components/ConfirmationModal';

// API
import { listFloors, deleteFloor } from '../../../api';

// Constants
const COLUMNS = (setupMode, onDelete) => [
  ...(setupMode ? [
    {
      title: 'Delete',
      render: rowData => (
        <IconButton onClick={() => onDelete(rowData)}><DeleteIcon /></IconButton>
      ),
      width: null,
      cellStyle: {
        width: 25
      }
    }
  ] : []),
  {
    field: 'identifier',
    title: 'Identifier'
  },
  {
    title: 'Building Name',
    render: rowData => rowData?.getBuilding()?.getName(),
    customFilterAndSearch: (term, rowData) => rowData?.getBuilding()?.getName()?.toLowerCase().includes(term.toLowerCase())
  }
];

class Floors extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmDeleteFloor: null,
      isLoading: false
    };
  }

  onDelete = (floor) => {
    this.setState({ confirmDeleteFloor: floor });
  }

  deleteFloor = () => {
    this.setState({ isLoading: true });
    deleteFloor(this.state.confirmDeleteFloor.getID()).then(() => {
      this.setState({ isLoading: false, confirmDeleteFloor: null });
      this.props.setSuccess('Floor deleted successfully.');
      // Temp: Refresh page
      window.location.reload();
    }).catch(error => {
      this.setState({ isLoading: false });
      this.props.setError(error ?? 'Error: Unable to delete floor.');
    });
  }

  render() {
    return (
      <>
        <ConfirmationModal
          open={this.state.confirmDeleteFloor != null}
          onClose={() => this.setState({ confirmDeleteFloor: null })}
          onConfirm={this.deleteFloor}
          color={this.props.statusColor}
          title={`Are you sure you want to delete floor ${this.state.confirmDeleteFloor?.getIdentifier()}?`}
          body={'All associated rooms and devices will be deleted.'}
          isLoading={this.state.isLoading}
        />

        <ListTable
          title='Floors'
          buttonLink='/admin/floors/create'
          buttonText='Create New'
          columns={COLUMNS(this.props.setupMode, this.onDelete)}
          retrievalFunction={listFloors}
          hideButton={!this.props.setupMode}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  setupMode: state.setupMode.mode || state.setupMode.isLoading,
  statusColor: state.status.schoolStatus ? state.status.schoolStatus.getColor() : DEFAULT,
});

const mapDispatchToProps = dispatch => ({
  setError: (msg) => {
    dispatch(clearErrors());
    setAlert(dispatch, msg);
  },
  setSuccess: (msg) => setSuccess(dispatch, msg),
  clearErrors: () => dispatch(clearErrors())
});

Floors.propTypes = {
  setupMode: PropTypes.bool.isRequired,
  statusColor: PropTypes.string.isRequired,
  setError: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(Floors);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { DEFAULT } from '../../../constants';

// Redux
import { connect } from 'react-redux';
import { setSuccess, clearErrors, setAlert } from '../../../redux/actions/alertActions';

// Icons
import DeleteIcon from '@material-ui/icons/Delete';

// Components
import ListTable from '../../../components/ListTable';
import ConfirmationModal from '../../../components/ConfirmationModal';

// API
import { listBuildings, deleteBuilding } from '../../../api';

// Constants
const COLUMNS = (setupMode, onDelete) => [
  ...(setupMode ? [
    {
      title: 'Delete',
      render: rowData => (
        <IconButton onClick={() => onDelete(rowData)}><DeleteIcon /></IconButton>
      ),
      width: null,
      cellStyle: {
        width: 25
      }
    }
  ] : []),
  {
    field: 'name',
    title: 'Name'
  }
];

class Buildings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmDeleteBuilding: null,
      isLoading: false
    };
  }

  componentWillUnmount() {
    this.props.clearErrors();
  }

  onDelete = (building) => {
    this.setState({ confirmDeleteBuilding: building });
  }

  deleteBuilding = () => {
    this.setState({ isLoading: true });
    deleteBuilding(this.state.confirmDeleteBuilding.getID()).then(() => {
      this.setState({ isLoading: false, confirmDeleteBuilding: null });
      this.props.setSuccess('Building deleted successfully.');
      // Temp: Refresh page
      window.location.reload();
    }).catch(error => {
      this.setState({ isLoading: false });
      this.props.setError(error ?? 'Error: Unable to delete building.');
    });
  }

  render() {
    return (
      <>
        <ConfirmationModal
          open={this.state.confirmDeleteBuilding != null}
          onClose={() => this.setState({ confirmDeleteBuilding: null })}
          onConfirm={this.deleteBuilding}
          color={this.props.statusColor}
          title={`Are you sure you want to delete building ${this.state.confirmDeleteBuilding?.getName()}?`}
          body={'All associated floors, rooms, and devices will be deleted.'}
          isLoading={this.state.isLoading}
        />

        <ListTable
          title='Buildings'
          buttonLink='/admin/buildings/create'
          buttonText='Create New'
          columns={COLUMNS(this.props.setupMode, this.onDelete)}
          retrievalFunction={listBuildings}
          hideButton={!this.props.setupMode}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  setupMode: state.setupMode.mode || state.setupMode.isLoading,
  statusColor: state.status.schoolStatus ? state.status.schoolStatus.getColor() : DEFAULT,
});

const mapDispatchToProps = dispatch => ({
  setError: (msg) => {
    dispatch(clearErrors());
    setAlert(dispatch, msg);
  },
  setSuccess: (msg) => setSuccess(dispatch, msg),
  clearErrors: () => dispatch(clearErrors())
});

Buildings.propTypes = {
  setupMode: PropTypes.bool.isRequired,
  statusColor: PropTypes.object.isRequired,
  setError: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(Buildings);

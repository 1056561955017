export default class StaffType {
  static thaw(staffTypeObj) {
    if (staffTypeObj == null) return null;
    const staffType = new StaffType();
    if (staffTypeObj.id != null) staffType.setID(staffTypeObj.id);
    if (staffTypeObj.name != null) staffType.setName(staffTypeObj.name);
    return staffType;
  }

  static thawList(staffTypeObjList) {
    if (staffTypeObjList == null) return [];
    var staffTypes = [];
    for (var i = 0; i < staffTypeObjList.length; i++) {
      staffTypes.push(StaffType.thaw(staffTypeObjList[i]));
    }
    return staffTypes;
  }

  static freeze(staffType) {
    if (staffType == null) return null;
    var staffTypeObj = {};
    if (staffType.getID() != null) staffTypeObj.id = staffType.getID();
    if (staffType.getName() != null) staffTypeObj.name = staffType.getName();
    return staffTypeObj;
  }

  static freezeList(staffTypes) {
    if (staffTypes == null) return [];
    var staffTypeObjList = [];
    for (var i = 0; i < staffTypes.length; i++) {
      staffTypeObjList.push(StaffType.freeze(staffTypes[i]));
    }
    return staffTypeObjList;
  }

  constructor() {
    this.setID(null);
    this.setName(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setName(name) {
    this.name = name;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getName() {
    return this.name;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

}

import { StaffMember, Badge, AlertUpdate, Hub, Floor, AlertType, RoomVertex } from './';
import { DateTime } from 'luxon';

export default class Room {
  static thaw(roomObj) {
    if (roomObj == null) return null;
    const room = new Room();
    if (roomObj.id != null) room.setID(roomObj.id);
    if (roomObj.identifier != null) room.setIdentifier(roomObj.identifier);
    if (roomObj.isActive != null) room.setIsActive(roomObj.isActive == null ? null : (roomObj.isActive ? true : false));
    if (roomObj.floorId != null) room.setFloorID(roomObj.floorId);
    if (roomObj.alertStatusId != null) room.setAlertStatusID(roomObj.alertStatusId);
    if (roomObj.alertStatusTimestamp != null) room.setAlertStatusTimestamp(DateTime.fromISO(roomObj.alertStatusTimestamp));
    if (roomObj.assignedStaffMembers != null) room.setAssignedStaffMembers(StaffMember.thawList(roomObj.assignedStaffMembers));
    if (roomObj.alertUpdates != null) room.setAlertUpdates(AlertUpdate.thawList(roomObj.alertUpdates));
    if (roomObj.hubs != null) room.setHubs(Hub.thawList(roomObj.hubs));
    if (roomObj.floor != null) room.setFloor(Floor.thaw(roomObj.floor));
    if (roomObj.alertStatus != null) room.setAlertStatus(AlertType.thaw(roomObj.alertStatus));
    if (roomObj.vertices != null) room.setVertices(RoomVertex.thawList(roomObj.vertices));
    if (roomObj.lastSeenBadges != null) room.setLastSeenBadges(Badge.thawList(roomObj.lastSeenBadges));
    return room;
  }

  static thawList(roomObjList) {
    if (roomObjList == null) return [];
    var rooms = [];
    for (var i = 0; i < roomObjList.length; i++) {
      rooms.push(Room.thaw(roomObjList[i]));
    }
    return rooms;
  }

  static freeze(room) {
    if (room == null) return null;
    var roomObj = {};
    if (room.getID() != null) roomObj.id = room.getID();
    if (room.getIdentifier() != null) roomObj.identifier = room.getIdentifier();
    if (room.getIsActive() != null) roomObj.isActive = room.getIsActive() == null ? null : (room.getIsActive() ? 1 : 0);
    if (room.getFloorID() != null) roomObj.floorId = room.getFloorID();
    if (room.getAlertStatusID() != null) roomObj.alertStatusId = room.getAlertStatusID();
    if (room.getAlertStatusTimestamp() != null) roomObj.alertStatusTimestamp = room.getAlertStatusTimestamp().toISO();
    if (room.getAssignedStaffMembers() != null) roomObj.assignedStaffMembers = StaffMember.freezeList(room.getAssignedStaffMembers());
    if (room.getAlertUpdates() != null) roomObj.alertUpdates = AlertUpdate.freezeList(room.getAlertUpdates());
    if (room.getHubs() != null) roomObj.hubs = Hub.freezeList(room.getHubs());
    if (room.getFloor() != null) roomObj.floor = Floor.freeze(room.getFloor());
    if (room.getAlertStatus() != null) roomObj.alertStatus = AlertType.freeze(room.getAlertStatus());
    if (room.getVertices() != null) roomObj.vertices = RoomVertex.freezeList(room.getVertices());
    if (room.getLastSeenBadges() != null) roomObj.lastSeenBadges = Badge.freezeList(room.getLastSeenBadges());
    return roomObj;
  }

  static freezeList(rooms) {
    if (rooms == null) return [];
    var roomObjList = [];
    for (var i = 0; i < rooms.length; i++) {
      roomObjList.push(Room.freeze(rooms[i]));
    }
    return roomObjList;
  }

  constructor() {
    this.setID(null);
    this.setIdentifier(null);
    this.setIsActive(null);
    this.setFloorID(null);
    this.setAlertStatusID(null);
    this.setAlertStatusTimestamp(null);
    this.setAssignedStaffMembers(null);
    this.setAlertUpdates(null);
    this.setHubs(null);
    this.setFloor(null);
    this.setAlertStatus(null);
    this.setVertices(null);
    this.setLastSeenBadges(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setIdentifier(identifier) {
    this.identifier = identifier;
  }

  setIsActive(isActive) {
    this.isActive = isActive;
  }

  setFloorID(floorID) {
    this.floorID = floorID;
  }

  setAlertStatusID(alertStatusID) {
    this.alertStatusID = alertStatusID;
  }

  setAlertStatusTimestamp(alertStatusTimestamp) {
    this.alertStatusTimestamp = alertStatusTimestamp;
  }

  setAssignedStaffMembers(assignedStaffMembers) {
    this.assignedStaffMembers = assignedStaffMembers;
  }

  setAlertUpdates(alertUpdates) {
    this.alertUpdates = alertUpdates;
  }

  setHubs(hubs) {
    this.hubs = hubs;
  }

  setFloor(floor) {
    this.floor = floor;
  }

  setAlertStatus(alertStatus) {
    this.alertStatus = alertStatus;
  }

  setVertices(vertices) {
    this.vertices = vertices;
  }

  setLastSeenBadges(lastSeenBadges) {
    this.lastSeenBadges = lastSeenBadges;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getIdentifier() {
    return this.identifier;
  }

  getIsActive() {
    return this.isActive;
  }

  getFloorID() {
    return this.floorID;
  }

  getAlertStatusID() {
    return this.alertStatusID;
  }

  getAlertStatusTimestamp() {
    return this.alertStatusTimestamp;
  }

  getAssignedStaffMembers() {
    return this.assignedStaffMembers;
  }

  getAlertUpdates() {
    return this.alertUpdates;
  }

  getHubs() {
    return this.hubs;
  }

  getFloor() {
    return this.floor;
  }

  getAlertStatus() {
    return this.alertStatus;
  }

  getVertices() {
    return this.vertices;
  }

  getLastSeenBadges() {
    return this.lastSeenBadges;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

}

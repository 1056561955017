import React, { Component } from 'react';
import { CircularProgress, Typography, TextField, Button } from '@material-ui/core';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { setSuccess, clearErrors, setAlert } from '../../../redux/actions/alertActions';

// Components
import InfoButton from '../../../components/InfoButton';

// Constants
import { AlertTypes, isEmail, isValidPhoneNumber } from '../../../constants';
import { getUser, updateUser } from '../../../api';
const MOBILE_ACCESS_HELP = 'If you have mobile access, you are able to use a smaller-screen device, such as a phone or tablet, to view the dashboard.';
const TWO_FACTOR_AUTH_HELP = 'For security purposes, you are required to authenticate yourself in two ways. Once with a password, and second with a device you have on a you-- typically, a phone.';

class AccountSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      nameError: false,
      emailError: false,
      phoneNumberError: false,
      firstName: null,
      lastName: null,
      email: '',
      phoneNumber: '',
      hasLoaded: false
    };

    this.onChange = this.onChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.loadUser = this.loadUser.bind(this);
  }

  componentDidMount() {
    this.loadUser();
  }

  loadUser() {
    getUser().then((user) => {
      this.setState({
        user: user,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phoneNumber: user.phoneNumber,
        hasLoaded: true
      });
    }).catch(error => {
      this.props.setError(error ? error : 'Error loading user settings.');
    });
  }

  onChange(e) {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
    this.setState({
      nameError: false,
      emailError: false,
      phoneNumberError: false
    });
  }

  validateForm() {
    const { email, phoneNumber, firstName, lastName, user } = this.state;
    var isValid = true;

    // Return if no changes were made
    if (email === user.getEmail() && phoneNumber === user.getPhoneNumber() && firstName === user.getFirstName() && lastName === user.getLastName()) {
      this.props.setSuccess('No changes');
      return;
    }

    if (!email || email === '' || !isEmail(email)) {
      this.setState({
        emailError: true
      });
      this.props.setError('Your new email must be a valid email.');
      isValid = false;
    }

    if (!phoneNumber || phoneNumber === '' || !isValidPhoneNumber(this.state.phoneNumber)) {
      this.setState({
        phoneNumberError: true
      });
      this.props.setError('Your new phone number must be in a valid format.');
      isValid = false;
    }

    if (!firstName || firstName === '' || !lastName || lastName === '') {
      this.props.setError('Your name cannot be left blank.');
      this.setState({
        nameError: true
      });
      isValid = false;
    }

    return isValid;
  }

  submitForm() {
    this.props.clearErrors();

    if (!this.validateForm()) {
      return;
    }

    var updateBody = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      phoneNumber: this.state.phoneNumber
    };

    this.setState({
      isLoading: true
    });

    updateUser(updateBody).then(() => {
      this.props.setSuccess('Successfully updated account settings.');
      this.setState({ isLoading: false });
      this.loadUser();
    }).catch(error => {
      this.props.setError(error ? error : 'Error updating account settings.');
      this.setState({ isLoading: false });
    });
  }

  render() {
    return this.state.hasLoaded && (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'flex-start' }}>
        <Typography variant='h3' style={{ margin: '25px 20px 50px 20px', textAlign: 'center' }}>Update Account Settings</Typography>
        <form noValidate autoComplete="off" style={{ width: (this.props.isFullWidth ? '50%' : '90%') }}>

          {/* Name Input */}
          <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between', marginBottom: '10px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: (this.props.isMobile ? '100%' : '40%') }}>
              <Typography variant='h6'>Name</Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: (this.props.isMobile ? '100%' : '60%') }}>
              <TextField error={this.state.nameError} name="firstName" label="First Name" style={{ width: '49%' }} defaultValue={this.state.firstName} onChange={this.onChange} variant='filled' />
              <TextField error={this.state.nameError}  name="lastName" label="Last Name" style={{ width: '49%' }} defaultValue={this.state.lastName} onChange={this.onChange} variant='filled' />
            </div>
          </div>

          {/* Email Input */}
          <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between', marginBottom: '10px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: (this.props.isMobile ? '100%' : '40%') }}>
              <Typography variant='h6'>Email</Typography>
            </div>
            <TextField error={this.state.emailError} name="email" label="Email" type="email" style={{ width: (this.props.isMobile ? '100%' : '60%') }} defaultValue={this.state.email} onChange={this.onChange} variant='filled' />
          </div>

          {/* Phone Number Input */}
          <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between', marginBottom: '10px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: (this.props.isMobile ? '100%' : '40%') }}>
              <Typography variant='h6'>Phone Number</Typography>
            </div>
            <TextField error={this.state.phoneNumberError} name="phoneNumber" label="Phone Number" type="tel" style={{ width: (this.props.isMobile ? '100%' : '60%') }} defaultValue={this.state.phoneNumber} onChange={this.onChange} variant='filled' />
          </div>

          {/* Mobile Access */}
          <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between', marginBottom: '10px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: (this.props.isMobile ? '100%' : '40%') }}>
              <Typography variant='h6'>Mobile Access</Typography>
              <InfoButton name='Mobile Access' body={MOBILE_ACCESS_HELP} color={this.props.schoolAlertStatus.color} />
            </div>
            <Typography variant='body1'>{this.state.user.getMobileAccess() ? 'Yes' : 'No'}</Typography>
          </div>

          {/* Password */}
          <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between', marginBottom: '10px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: (this.props.isMobile ? '100%' : '40%') }}>
              <Typography variant='h6'>Password</Typography>
            </div>
            <Typography variant='body1'>{(this.state.user.getUserType() === 'Super' || this.state.user.getUserType() === 'IT') ? 'Change Password on View Users Page' : 'Notify IT to Change Password'}</Typography>
          </div>

          {/* 2FA */}
          <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between', marginBottom: '10px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: (this.props.isMobile ? '100%' : '40%') }}>
              <Typography variant='h6'>Two Factor Auth (2FA)</Typography>
              <InfoButton name='Two Factor Authentication (2FA)' body={TWO_FACTOR_AUTH_HELP} color={this.props.schoolAlertStatus.color} />
            </div>
            <Typography variant='body1'>{(this.state.user.getUserType() === 'Super' || this.state.user.getUserType() === 'IT') ? 'Update 2FA on View Users Page' : 'Notify IT to Update 2FA'}</Typography>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <Button onClick={this.submitForm} variant="contained" style={{ width: '200px', height: '50px', margin: '30px', color: this.props.schoolAlertStatus.color.text, backgroundColor: this.props.schoolAlertStatus.color.main }} disabled={this.state.isLoading}>
              Confirm Changes
              {this.state.isLoading && <CircularProgress size={25} style={{ color: this.props.schoolAlertStatus.color.text, marginLeft: '1em' }} />}
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  schoolAlertStatus: state.status.schoolStatus || AlertTypes.UNKNOWN,
  isMobile: state.ui.isMobile,
  isFullWidth: state.ui.isFullWidth
});

const mapDispatchToProps = (dispatch) => ({
  setError: (msg) => setAlert(dispatch, msg),
  setSuccess: (msg) => setSuccess(dispatch, msg),
  clearErrors: () => dispatch(clearErrors())
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);

AccountSettings.propTypes = {
  setError: PropTypes.func,
  setSuccess: PropTypes.func,
  isMobile: PropTypes.bool,
  clearErrors: PropTypes.func,
  schoolAlertStatus: PropTypes.object,
  isFullWidth: PropTypes.bool
};

import { DateTime } from 'luxon';
import { StaffMember, User, AlertType, Room, Badge } from './';

export default class AlertUpdate {
  static thaw(alertUpdateObj) {
    if (alertUpdateObj == null) return null;
    const alertUpdate = new AlertUpdate();
    if (alertUpdateObj.id != null) alertUpdate.setID(alertUpdateObj.id);
    if (alertUpdateObj.timestamp != null) alertUpdate.setTimestamp(DateTime.fromISO(alertUpdateObj.timestamp));
    if (alertUpdateObj.didAlertSchool != null) alertUpdate.setDidAlertSchool(alertUpdateObj.didAlertSchool == null ? null : (alertUpdateObj.didAlertSchool ? true : false));
    if (alertUpdateObj.isActive != null) alertUpdate.setIsActive(alertUpdateObj.isActive == null ? null : (alertUpdateObj.isActive ? true : false));
    if (alertUpdateObj.staffMemberId != null) alertUpdate.setStaffMemberID(alertUpdateObj.staffMemberId);
    if (alertUpdateObj.userId != null) alertUpdate.setUserID(alertUpdateObj.userId);
    if (alertUpdateObj.alertTypeId != null) alertUpdate.setAlertTypeID(alertUpdateObj.alertTypeId);
    if (alertUpdateObj.roomId != null) alertUpdate.setRoomID(alertUpdateObj.roomId);
    if (alertUpdateObj.badgeSerialNumber != null) alertUpdate.setBadgeSerialNumber(alertUpdateObj.badgeSerialNumber);
    if (alertUpdateObj.staffMember != null) alertUpdate.setStaffMember(StaffMember.thaw(alertUpdateObj.staffMember));
    if (alertUpdateObj.user != null) alertUpdate.setUser(User.thaw(alertUpdateObj.user));
    if (alertUpdateObj.alertType != null) alertUpdate.setAlertType(AlertType.thaw(alertUpdateObj.alertType));
    if (alertUpdateObj.room != null) alertUpdate.setRoom(Room.thaw(alertUpdateObj.room));
    if (alertUpdateObj.badge != null) alertUpdate.setBadge(Badge.thaw(alertUpdateObj.badge));
    return alertUpdate;
  }

  static thawList(alertUpdateObjList) {
    if (alertUpdateObjList == null) return [];
    var alertUpdates = [];
    for (var i = 0; i < alertUpdateObjList.length; i++) {
      alertUpdates.push(AlertUpdate.thaw(alertUpdateObjList[i]));
    }
    return alertUpdates;
  }

  static freeze(alertUpdate) {
    if (alertUpdate == null) return null;
    var alertUpdateObj = {};
    if (alertUpdate.getID() != null) alertUpdateObj.id = alertUpdate.getID();
    if (alertUpdate.getTimestamp() != null) alertUpdateObj.timestamp = alertUpdate.getTimestamp().toISO();
    if (alertUpdate.getDidAlertSchool() != null) alertUpdateObj.didAlertSchool = alertUpdate.getDidAlertSchool() == null ? null : (alertUpdate.getDidAlertSchool() ? 1 : 0);
    if (alertUpdate.getIsActive() != null) alertUpdateObj.isActive = alertUpdate.getIsActive() == null ? null : (alertUpdate.getIsActive() ? 1 : 0);
    if (alertUpdate.getStaffMemberID() != null) alertUpdateObj.staffMemberId = alertUpdate.getStaffMemberID();
    if (alertUpdate.getUserID() != null) alertUpdateObj.userId = alertUpdate.getUserID();
    if (alertUpdate.getAlertTypeID() != null) alertUpdateObj.alertTypeId = alertUpdate.getAlertTypeID();
    if (alertUpdate.getRoomID() != null) alertUpdateObj.roomId = alertUpdate.getRoomID();
    if (alertUpdate.getBadgeSerialNumber() != null) alertUpdateObj.badgeSerialNumber = alertUpdate.getBadgeSerialNumber();
    if (alertUpdate.getStaffMember() != null) alertUpdateObj.staffMember = StaffMember.freeze(alertUpdate.getStaffMember());
    if (alertUpdate.getUser() != null) alertUpdateObj.user = User.freeze(alertUpdate.getUser());
    if (alertUpdate.getAlertType() != null) alertUpdateObj.alertType = AlertType.freeze(alertUpdate.getAlertType());
    if (alertUpdate.getRoom() != null) alertUpdateObj.room = Room.freeze(alertUpdate.getRoom());
    if (alertUpdate.getBadge() != null) alertUpdateObj.badge = Badge.freeze(alertUpdate.getBadge());
    return alertUpdateObj;
  }

  static freezeList(alertUpdates) {
    if (alertUpdates == null) return [];
    var alertUpdateObjList = [];
    for (var i = 0; i < alertUpdates.length; i++) {
      alertUpdateObjList.push(AlertUpdate.freeze(alertUpdates[i]));
    }
    return alertUpdateObjList;
  }

  constructor() {
    this.setID(null);
    this.setTimestamp(null);
    this.setDidAlertSchool(null);
    this.setIsActive(null);
    this.setStaffMemberID(null);
    this.setUserID(null);
    this.setAlertTypeID(null);
    this.setRoomID(null);
    this.setBadgeSerialNumber(null);
    this.setStaffMember(null);
    this.setUser(null);
    this.setAlertType(null);
    this.setRoom(null);
    this.setBadge(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setTimestamp(timestamp) {
    this.timestamp = timestamp;
  }

  setDidAlertSchool(didAlertSchool) {
    this.didAlertSchool = didAlertSchool;
  }

  setIsActive(isActive) {
    this.isActive = isActive;
  }

  setStaffMemberID(staffMemberID) {
    this.staffMemberID = staffMemberID;
  }

  setUserID(userID) {
    this.userID = userID;
  }

  setAlertTypeID(alertTypeID) {
    this.alertTypeID = alertTypeID;
  }

  setRoomID(roomID) {
    this.roomID = roomID;
  }

  setBadgeSerialNumber(badgeSerialNumber) {
    this.badgeSerialNumber = badgeSerialNumber;
  }

  setStaffMember(staffMember) {
    this.staffMember = staffMember;
  }

  setUser(user) {
    this.user = user;
  }

  setAlertType(alertType) {
    this.alertType = alertType;
  }

  setRoom(room) {
    this.room = room;
  }

  setBadge(badge) {
    this.badge = badge;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getTimestamp() {
    return this.timestamp;
  }

  getDidAlertSchool() {
    return this.didAlertSchool;
  }

  getIsActive() {
    return this.isActive;
  }

  getStaffMemberID() {
    return this.staffMemberID;
  }

  getUserID() {
    return this.userID;
  }

  getAlertTypeID() {
    return this.alertTypeID;
  }

  getRoomID() {
    return this.roomID;
  }

  getBadgeSerialNumber() {
    return this.badgeSerialNumber;
  }

  getStaffMember() {
    return this.staffMember;
  }

  getUser() {
    return this.user;
  }

  getAlertType() {
    return this.alertType;
  }

  getRoom() {
    return this.room;
  }

  getBadge() {
    return this.badge;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

}

// Constants
import { isNull } from '../../constants';
const DEFAULT_TIMEOUT = 3000;

export const ADD_ANNOUNCEMENT = 'ADD_ANNOUNCEMENT';
export const REMOVE_ANNOUNCEMENT = 'REMOVE_ANNOUNCEMENT';
export const REMOVE_ANNOUNCEMENT_BY_MESSAGE = 'REMOVE_ANNOUNCEMENT_BY_MESSAGE';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const CLEAR_ANNOUNCEMENTS = 'CLEAR_ANNOUNCEMENTS';
export const CLEAR_INFOS = 'CLEAR_INFOS';
/** @type {{ ERROR: 'error', WARNING: 'warning', SUCCESS: 'success', INFO: 'info' }} */
export const AnnouncementSeverity = {
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
  INFO: 'info'
};

/**
 * @typedef {{id: string, title?: string, message?: string, severity?: keyof AnnouncementSeverity, color?: import('../../constants').Color, showClose?: boolean, timeout?: number | null}} Announcement
 */

/**
 * @param {Omit<Announcement, "id">} announcement
 */
export const addAnnouncement = (announcement) => ({
  type: ADD_ANNOUNCEMENT,
  announcement
});

export const removeAnnouncement = (id) => ({
  type: REMOVE_ANNOUNCEMENT,
  id
});

export const removeAnnouncementByMessage = (message) => ({
  type: REMOVE_ANNOUNCEMENT_BY_MESSAGE,
  message
});

export const clearErrors = () => ({
  type: CLEAR_ERRORS
});

export const clearAnnouncements = () => ({
  type: CLEAR_ANNOUNCEMENTS
});

export const clearInfos = () => ({
  type: CLEAR_INFOS
});

/**
 * Shows an announcement for a given message/title (at least one of them is required)
 * 
 * Timeout defaults to 3000ms, setting to null will disable the timeout.
 * @param {function} dispatch The Redux dispatch function
 * @param {Omit<Announcement, "id">} announcement
 */
export const setAnnouncement = (
  dispatch,
  announcement
) => {
  window.scrollTo(0, 0);

  if (isNull(announcement) || ((announcement.message ?? '') === '' && (announcement.message ?? '') === '')) {
    switch (announcement?.severity) {
      case 'ERROR':
        announcement = { title: 'Error: something went wrong. Please try again later.', ...(announcement ?? {}) };
        break;
      case 'WARNING':
        announcement = { title: 'Warning!', ...(announcement ?? {}) };
        break;
      case 'SUCCESS':
        announcement = { title: 'Success!', ...(announcement ?? {}) };
        break;
      default:
        announcement = { title: 'An unknown event occurred.', ...(announcement ?? {}) };
    }
  }

  // Add New Announcement to App State
  const newAnnouncement = addAnnouncement(announcement);
  dispatch(newAnnouncement);

  // Remove the New Announcement After Timeout (3 sec default)
  if (announcement?.timeout !== null) {
    setTimeout(() => dispatch(removeAnnouncement(newAnnouncement.announcement.id)), announcement.timeout ?? DEFAULT_TIMEOUT);
  }
};

/**
 * Should be called whenever any alert is received
 * @param {*} dispatch 
 * @param {{status: import('../../lib').AlertType, room?: import('../../lib').Room, floor?: import('../../lib').Floor, building?: import('../../lib').Building}} updatedStatus 
 */
export const announceSchoolStatus = (dispatch, { status, room, floor, building }) => {
  /** @type {keyof AnnouncementSeverity | undefined} */
  let severity;

  if (status.getDashboardStatus() === 'Normal') {
    severity = 'INFO';
  }
  else if (status.getDashboardStatus() === 'All Clear') {
    severity = 'SUCCESS';
  }
  else if (status.getDashboardStatus() === 'Admin' || status.getDashboardStatus() === 'Medical') {
    severity = 'WARNING';
  }
  else if (status.getDashboardStatus() === 'Emergency') {
    severity = 'ERROR';
  }

  let message = `Room${Number.isNaN(parseInt(room?.getIdentifier())) ? ':' : ''} ${room?.getIdentifier() ?? '[UNKNOWN]'}`;

  if (floor) {
    message += `, Floor${Number.isNaN(parseInt(floor?.getIdentifier())) ? ':' : ''} ${floor.getIdentifier()}`;
  }

  if (building) {
    message += `, Building${Number.isNaN(parseInt(building.getName())) ? ':' : ''} ${building.getName()}`;
  }

  dispatch(removeAnnouncementByMessage(message));

  /** @type {Omit<Announcement, 'id'>} */
  const announcement = {
    id: room?.getID() ?? null,
    title: status.getDashboardStatus(),
    message,
    color: status.getColor(),
    severity,
    timeout: null
  };
  setAnnouncement(dispatch, announcement);
};

import React from 'react';
import { Typography } from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';
import PropTypes from 'prop-types';

// Components
import ListCard from '../../../components/ListCard';
import { GREY, RED } from '../../../constants';

export default function UserListCard({ user, onClick, selectedCardObject }) {
  return (
    <ListCard onClick={onClick} isSelected={selectedCardObject === user} selectedColor='grey'>
      <div style={{ display: 'flex', flexDirection: 'row' }}>

        {/* Name and Email */}
        <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography variant='h4' style={{ textAlign: 'center' }}>{user.getName()}</Typography>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <MailIcon style={{ fontSize: 20, color: GREY.main, marginRight: '5px' }} />
            <Typography variant='h6'>{user.getEmail()}</Typography>
          </div>
        </div>

        {/* Active Status */}
        <div style={{ display: 'flex', justifyContent: 'flex-end', width: 100, alignItems: 'center' }}>
          <Typography variant='body1' style={{ color: RED.main }}>{!user.getIsActive() ? 'Inactive' : ''}</Typography>
        </div>
      </div>
    </ListCard>
  );
}

UserListCard.propTypes = {
  user: PropTypes.object,
  onClick: PropTypes.func,
  selectedCardObject: PropTypes.object
};

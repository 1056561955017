import { Room, AlertUpdate, Badge } from './';
import StaffType from './StaffType';

export default class StaffMember {
  static thaw(staffMemberObj) {
    if (staffMemberObj == null) return null;
    const staffMember = new StaffMember();
    if (staffMemberObj.id != null) staffMember.setID(staffMemberObj.id);
    if (staffMemberObj.firstName != null) staffMember.setFirstName(staffMemberObj.firstName);
    if (staffMemberObj.lastName != null) staffMember.setLastName(staffMemberObj.lastName);
    if (staffMemberObj.email != null) staffMember.setEmail(staffMemberObj.email);
    if (staffMemberObj.phoneNumber != null) staffMember.setPhoneNumber(staffMemberObj.phoneNumber);
    if (staffMemberObj.imagePath != null) staffMember.setImagePath(staffMemberObj.imagePath);
    if (staffMemberObj.isActive != null) staffMember.setIsActive(staffMemberObj.isActive == null ? null : (staffMemberObj.isActive ? true : false));
    if (staffMemberObj.assignedRoomId != null) staffMember.setAssignedRoomID(staffMemberObj.assignedRoomId);
    if (staffMemberObj.assignedRoom != null) staffMember.setAssignedRoom(Room.thaw(staffMemberObj.assignedRoom));
    if (staffMemberObj.alertUpdates != null) staffMember.setAlertUpdates(AlertUpdate.thawList(staffMemberObj.alertUpdates));
    if (staffMemberObj.badge != null) staffMember.setBadge(Badge.thaw(staffMemberObj.badge));
    if (staffMemberObj.staffTypeId != null) staffMember.setStaffTypeID(staffMemberObj.staffTypeId);
    if (staffMemberObj.staffType != null) staffMember.setStaffType(StaffType.thaw(staffMemberObj.staffType));
    return staffMember;
  }

  static thawList(staffMemberObjList) {
    if (staffMemberObjList == null) return [];
    var staffMembers = [];
    for (var i = 0; i < staffMemberObjList.length; i++) {
      staffMembers.push(StaffMember.thaw(staffMemberObjList[i]));
    }
    return staffMembers;
  }

  static freeze(staffMember) {
    if (staffMember == null) return null;
    var staffMemberObj = {};
    if (staffMember.getID() != null) staffMemberObj.id = staffMember.getID();
    if (staffMember.getFirstName() != null) staffMemberObj.firstName = staffMember.getFirstName();
    if (staffMember.getLastName() != null) staffMemberObj.lastName = staffMember.getLastName();
    if (staffMember.getEmail() != null) staffMemberObj.email = staffMember.getEmail();
    if (staffMember.getPhoneNumber() != null) staffMemberObj.phoneNumber = staffMember.getPhoneNumber();
    if (staffMember.getImagePath() != null) staffMemberObj.imagePath = staffMember.getImagePath();
    if (staffMember.getIsActive() != null) staffMemberObj.isActive = staffMember.getIsActive() == null ? null : (staffMember.getIsActive() ? 1 : 0);
    if (staffMember.getAssignedRoomID() != null) staffMemberObj.assignedRoomId = staffMember.getAssignedRoomID();
    if (staffMember.getAssignedRoom() != null) staffMemberObj.assignedRoom = Room.freeze(staffMember.getAssignedRoom());
    if (staffMember.getAlertUpdates() != null) staffMemberObj.alertUpdates = AlertUpdate.freezeList(staffMember.getAlertUpdates());
    if (staffMember.getBadge() != null) staffMemberObj.badge = Badge.freeze(staffMember.getBadge());
    if (staffMember.getStaffTypeID() != null) staffMemberObj.staffTypeId = staffMember.getStaffTypeID();
    if (staffMember.getStaffType() != null) staffMemberObj.staffType = StaffType.freeze(staffMember.getStaffType());
    return staffMemberObj;
  }

  static freezeList(staffMembers) {
    if (staffMembers == null) return [];
    var staffMemberObjList = [];
    for (var i = 0; i < staffMembers.length; i++) {
      staffMemberObjList.push(StaffMember.freeze(staffMembers[i]));
    }
    return staffMemberObjList;
  }

  constructor() {
    this.setID(null);
    this.setFirstName(null);
    this.setLastName(null);
    this.setEmail(null);
    this.setPhoneNumber(null);
    this.setImagePath(null);
    this.setIsActive(null);
    this.setAssignedRoomID(null);
    this.setAssignedRoom(null);
    this.setAlertUpdates(null);
    this.setBadge(null);
    this.setStaffTypeID(null);
    this.setStaffType(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setFirstName(firstName) {
    this.firstName = firstName;
  }

  setLastName(lastName) {
    this.lastName = lastName;
  }

  setEmail(email) {
    this.email = email;
  }

  setPhoneNumber(phoneNumber) {
    this.phoneNumber = phoneNumber;
  }

  setImagePath(imagePath) {
    this.imagePath = imagePath;
  }

  setIsActive(isActive) {
    this.isActive = isActive;
  }

  setAssignedRoomID(assignedRoomID) {
    this.assignedRoomID = assignedRoomID;
  }

  setAssignedRoom(assignedRoom) {
    this.assignedRoom = assignedRoom;
  }

  setAlertUpdates(alertUpdates) {
    this.alertUpdates = alertUpdates;
  }

  setBadge(badge) {
    this.badge = badge;
  }

  setStaffTypeID(staffTypeID) {
    this.staffTypeID = staffTypeID;
  }

  setStaffType(staffType) {
    this.staffType = staffType;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getFirstName() {
    return this.firstName;
  }

  getLastName() {
    return this.lastName;
  }

  getEmail() {
    return this.email;
  }

  getPhoneNumber() {
    return this.phoneNumber;
  }

  getImagePath() {
    return this.imagePath;
  }

  getIsActive() {
    return this.isActive;
  }

  getAssignedRoomID() {
    return this.assignedRoomID;
  }

  getAssignedRoom() {
    return this.assignedRoom;
  }

  getAlertUpdates() {
    return this.alertUpdates;
  }

  getBadge() {
    return this.badge;
  }

  getStaffTypeID() {
    return this.staffTypeID;
  }

  getStaffType() {
    return this.staffType;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */
  getName() {
    return this.firstName + ' ' + this.lastName;
  }
}

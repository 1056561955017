import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import StatusUpdate from './StatusUpdate';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import 'react-phone-input-material-ui/lib/style.css';

// Redux
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { screenResize } from './redux/actions/uiActions';
import { store, persistor } from './redux/store';

// Navigation
import Navigation from './navigation';

function App() {

  useEffect(() => {
    // Add event listener
    window.addEventListener('resize', () => store.dispatch(screenResize(window.innerWidth, window.innerHeight)));

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', () => store.dispatch(screenResize(window.innerWidth, window.innerHeight)));
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <ScrollToTop />
            <StatusUpdate />
            <>
              <Navigation />
            </>
          </BrowserRouter>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;

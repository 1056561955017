import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

// Components
import InfoPanel from './InfoPanel';
import SetStatusWidget from './SetStatusWidget';
import HubStatusWidget from './HubStatusWidget';
import StaffWidget from './StaffWidget';
import AlertUpdateWidget from './AlertUpdateWidget';
import InfoDialog from './InfoDialog';

import { DeviceTypes, isNull } from '../constants';

const HEADER = (room) => {
  return (
    <>
      <Typography variant='h4' style={{ textAlign: 'center' }}>{room.getIdentifier()} </Typography>
      {room.getAlertStatus() &&
        <Typography variant='h6' style={{ color: room.getAlertStatus().color.main, textTransform: 'uppercase', textAlign: 'center' }}>{room.getAlertStatus().name || 'Normal' } Status </Typography>}
    </>
  );
};

const CHILDREN = (room, isMobile, isFullWidth) => {
  const lastSeenStaff = room?.getLastSeenBadges()?.map(badge => badge.getBelongsTo()) || [];

  return (
    <>
      {room.getHubs() &&
        <HubStatusWidget hubs={room.getHubs()} isInfoPanel={true} isMobile={isMobile} isFullWidth={isFullWidth} />
      }

      {lastSeenStaff.length > 0 &&
        <StaffWidget displayType={DeviceTypes.ROOMS} style={{ margin: '30px 0px 30px 0px' }} staff={lastSeenStaff} />
      }
      <AlertUpdateWidget updates={room.getAlertUpdates()} />
    </>
  );
};

const FOOTER = (onSetStatus) => {
  return (
    <SetStatusWidget onSetStatus={onSetStatus} />
  );
};

export default function RoomInfoPanel({ room, onSetStatus, isMobile, isFullWidth, handleClose/*, setupMode*/ }) {
  return isMobile ? (
    <InfoDialog
      statusColor={room.getAlertStatus().color.main}
      header={HEADER(room)}
      footer={FOOTER(onSetStatus)}
      handleClose={handleClose}
      open={!isNull(room)}
      editHref={null /*setupMode ? '/admin/rooms/' + room.getID() : null*/}
    >
      {CHILDREN(room, isFullWidth, isMobile)}
    </InfoDialog>
  ) : (
    <InfoPanel
      statusColor={room.getAlertStatus().color.main}
      header={HEADER(room)}
      footer={FOOTER(onSetStatus)}
      editHref={null /*setupMode ? '/admin/rooms/' + room.getID() : null*/}
    >
      {CHILDREN(room, isFullWidth, isMobile)}
    </InfoPanel>
  );
}

RoomInfoPanel.propTypes = {
  room: PropTypes.object,
  onSetStatus: PropTypes.func,
  isMobile: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  handleClose: PropTypes.func,
  setupMode: PropTypes.bool.isRequired
};

import React, { Component } from 'react';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { removeAlert } from '../redux/actions/alertActions';
import AnnouncementBar from '../components/AnnouncementBar';
import { removeAnnouncement } from '../redux/actions/announcementActions';

/**
 * @extends {Component<{announcements: import('../redux/actions/announcementActions').Announcement[]; alerts: { id: string; msg: string; severity: 'error' | 'warning' | 'success' | 'info'; showClose: boolean }[], removeAlert: (id: string) => unknown, removeAnnouncement: (id: string) => unknown}>}
 */
class AlertList extends Component {
  render() {
    return (
      <>
        {this.props.alerts.map((alert) => (
          <Alert key={alert.id} severity={alert.severity} onClose={alert.showClose ? () => this.props.removeAlert(alert.id) : undefined}>
            {alert.msg}
          </Alert>
        ))}
        {this.props.announcements.map((announcement) => (
          <AnnouncementBar key={announcement.id} title={announcement.title} message={announcement.message} severity={announcement.severity} color={announcement.color} onClose={announcement.showClose ?? true ? () => this.props.removeAnnouncement(announcement.id) : undefined} />
        ))}
      </>
    );
  }
}

const mapStateToProps = state => ({
  alerts: state.alerts,
  announcements: state.announcements
});

const mapDispatchToProps = dispatch => ({
  removeAlert: id => dispatch(removeAlert(id)),
  removeAnnouncement: id => dispatch(removeAnnouncement(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertList);

AlertList.propTypes = {
  alerts: PropTypes.array.isRequired,
  announcements: PropTypes.array.isRequired,
  removeAlert: PropTypes.func.isRequired,
  removeAnnouncement: PropTypes.func.isRequired
};

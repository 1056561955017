import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

// Components
import ListCard from '../../../components/ListCard';
import HubStatusWidget from '../../../components/HubStatusWidget';

// Icons
import Hallway from '../../../images/customIcons/Hallway.png';
import Room from '../../../images/customIcons/Room.png';
import Door from '../../../images/customIcons/Door.png';

function getIcon(hubType) {
  switch (hubType){
    case 'Hallway':
      return Hallway;
    case 'Room':
      return Room;
    case 'Door':
      return Door;
    default:
      return;
  }
}

export default function HubListCard({ hub, onClick, selectedCardObject, isFullWidth }) {
  return (
    <ListCard statusColor={hub?.getRoom()?.getAlertStatus() ? hub?.getRoom()?.getAlertStatus().color.main : null} onClick={onClick} isSelected={selectedCardObject === hub}>
      <Grid container direction={isFullWidth ? 'row' : 'column'}>
        {/* Content */}
        <Grid lg item style={{ display: 'flex', alignItems: 'center', paddingLeft: (isFullWidth ? '20px' : '0px') }}>
          <div style={isFullWidth ? { display: 'flex', flexDirection: 'row' } : { display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img src={getIcon(hub.getHubType())}
              alt={hub.getHubType() + ' Image'}
              style={{ width: '50px', height: '50px' }}
            />
            <Grid container id="inner" direction='column' justify='center' alignItems={isFullWidth ? 'flex-start' : 'center'} style={{ marginLeft: (isFullWidth ? '5px' : '0px') }}>
              <Grid item style={{ textAlign: 'left' }}><Typography variant='h5'>{hub.getSerialNumber()}</Typography></Grid>
              <Grid item style={{ textAlign: 'left' }}><Typography variant='h6' style={{ textTransform: 'uppercase' }}>{hub.getHubType()}</Typography></Grid>
            </Grid>
          </div>
        </Grid>

        {/* Device Status */}
        <Grid lg={3} item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: (isFullWidth ? '0px' : '30px') }}>
          <HubStatusWidget hub={hub} />
        </Grid>
      </Grid>
    </ListCard>
  );
}

HubListCard.propTypes = {
  hub: PropTypes.object,
  onClick: PropTypes.func,
  selectedCardObject: PropTypes.object,
  isFullWidth: PropTypes.bool
};

import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function StatusAlertModal({ isVisible, alertStatus, dismissAlert }) {
  return isVisible ? (
    <div style={{ zIndex: 100, position: 'absolute', width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.3)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ width: '90%', height: '90%', backgroundColor: alertStatus.color.main, borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Grid container direction='column' justify='center' align='center'>
          {/* Message */}
          <Grid item style={{ marginBottom: '2em' }}>
            <Typography variant='h4' style={{ textTransform: 'uppercase', color: alertStatus.color.light }}>Alert</Typography>
            <Typography variant='h1' style={{ textTransform: 'uppercase', color: alertStatus.color.text }}>{alertStatus.getDashboardStatus()}</Typography>
          </Grid>

          {/* Button */}
          <Grid item>
            <Button onClick={dismissAlert} style={{ backgroundColor: alertStatus.color.dark, color: alertStatus.color.light, padding: '2em 5em 2em 5em' }}>
              <Typography variant='h4'>OK</Typography>
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  ) : null;
}

StatusAlertModal.propTypes = {
  isVisible: PropTypes.bool,
  alertStatus: PropTypes.object,
  dismissAlert: PropTypes.func
};
/*
 * Copyright © 2021 Lexcelon LLC <info@lexcelon.com>
 * Licensed for non-distributable use
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Typography,
  Paper,
  IconButton
} from '@material-ui/core';

// Icons
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Close';

function descendingComparator(a, b, orderBy) {
  if (getDataFromObjectField(b, orderBy) < getDataFromObjectField(a, orderBy)) {
    return -1;
  }
  if (getDataFromObjectField(b, orderBy) > getDataFromObjectField(a, orderBy)) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function getDataFromObjectField(object, field) {
  // Retrieve the value of the cell (in case the field is a nested object)
  let remainingColumnField = field;
  let value = object;
  let indexOfPeriod = remainingColumnField.indexOf('.');
  while (indexOfPeriod !== -1) {
    // Get the substring and find the value
    let substring = remainingColumnField.substring(0, indexOfPeriod);
    remainingColumnField = remainingColumnField.substring(indexOfPeriod + 1);
    value = value[substring];
    indexOfPeriod = remainingColumnField.indexOf('.');
  }
  return value != null ? value[remainingColumnField] : null;
}

function EnhancedTableHead({ order, orderByIdx, onRequestSort, columns }) {
  const createSortHandler = (propertyIdx) => (event) => {
    onRequestSort(event, propertyIdx);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((column, columnIdx) => (
          <TableCell
            key={columnIdx}
            align='left'
            padding='normal'
            sortDirection={orderByIdx === columnIdx ? order : false}
          >
            <TableSortLabel
              active={orderByIdx === columnIdx && (column.field || column.customSort)}
              direction={orderByIdx === columnIdx ? order : 'asc'}
              onClick={createSortHandler(columnIdx)}
              disabled={(!column.field && !column.customSort)}
            >
              {column.title}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderByIdx: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired
};

class EnhancedTableToolbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: ''
    };
  }

  onSearchChange = (e) => {
    this.setState({ search: e.target.value });
    this.props.onSearch(e.target.value);
  }

  handleClearSearch = () => {
    this.setState({ search: '' });
    this.props.onSearch('');
  }

  render() {
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 }
        }}
      >
        <Typography
          sx={{ flex: '1 1 100%' }}
          style={{ width: '50%', display: 'flex' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {this.props.title}
        </Typography>

        {/* Search Field */}
        <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
          <TextField
            placeholder="Search"
            style={{ width: 275, marginRight: 10 }}
            onChange={this.onSearchChange}
            value={this.state.search}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClearSearch}
                    edge="end"
                  >
                    {this.state.search?.length > 0 ? <ClearIcon /> : null}
                  </IconButton>
                </InputAdornment>
              )
            }}
            variant="standard"
          />
        </div>
      </Toolbar>
    );
  }
}

EnhancedTableToolbar.propTypes = {
  title: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired
};

class MaterialTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      order: 'asc',
      orderByIdx: 0,
      page: 0,
      rowsPerPage: this.props.options?.pageSize || 5,
      searchTerm: ''
    };
  }

  handleRequestSort = (event, propertyIdx) => {
    const isAsc = this.state.orderByIdx === propertyIdx && this.state.order === 'asc';
    this.setState({
      order: isAsc ? 'desc' : 'asc',
      orderByIdx: propertyIdx
    });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    });
  };

  getOrderedData = (data) => {
    const { order, orderByIdx } = this.state;
    const column = this.props.columns[orderByIdx];
    let sortedData = data.slice();

    // Prioritize a custom sort over the default one
    if (column?.customSort) {
      sortedData.sort(column.customSort);
      if (order === 'desc') sortedData.reverse();
    }

    // Otherwise, return the default sort
    else if (column?.field) {
      sortedData.sort(getComparator(order, column.field));
    }

    return sortedData;
  }

  getSearchedData = (data) => {
    const { searchTerm } = this.state;
    let searchedData = data.slice();

    return searchedData.filter(row => {
      for (const column of this.props.columns) {
        // Prioritize a custom search over the default one
        if (column?.customFilterAndSearch) {
          if (column?.customFilterAndSearch(searchTerm, row)) return true;
        }

        // Otherwise, return the default search
        else if (column?.field) {
          const value = getDataFromObjectField(row, column.field);
          if (value != null && (typeof value !== 'string' || (typeof value === 'string' && value.toLowerCase()?.includes(searchTerm?.toLowerCase())))) return true;
        }

        else if (searchTerm === '') return true;
      }
      return false;
    });
  }

  getOrderedAndSearchedData = () => {
    return this.getOrderedData(this.getSearchedData(this.props.data));
  }

  render() {
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
      this.state.page > 0 ? Math.max(0, (1 + this.state.page) * this.state.rowsPerPage - this.props.data.length) : 0;
    return (
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <EnhancedTableToolbar
            title={this.props.title}
            onSearch={(search) => this.setState({ searchTerm: search })}
          />

          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size='medium'
            >
              <EnhancedTableHead
                order={this.state.order}
                orderByIdx={this.state.orderByIdx}
                onRequestSort={this.handleRequestSort}
                rowCount={this.props.data.length}
                columns={this.props.columns}
              />
              <TableBody>
                {this.getOrderedAndSearchedData()
                  .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow
                        hover
                        key={index}
                      >
                        {this.props.columns.map((column, columnIdx) => {
                          if (column.hidden) return null;
                          let value = null;

                          // Prioritize the render function, if provided
                          if (column.render) {
                            value = column.render(row);
                          }

                          // Display the column field otherwise, if provided
                          else if (column.field) {
                            // Retrieve the value of the cell (in case the field is a nested object)
                            value = getDataFromObjectField(row, column.field);
                          }

                          // Display boolean values
                          if (value === true) value = 'true';
                          else if (value === false) value = 'false';

                          return (
                            <TableCell key={columnIdx} padding='normal'>{value}</TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 100]}
            component="div"
            count={this.props.data.length}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onPageChange={this.handleChangePage}
            onChangePage={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    );
  }
}

MaterialTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  title: PropTypes.any.isRequired,
  options: PropTypes.object
};

export default MaterialTable;

import { AlertType } from '../lib';

/**
 * @typedef {{main: string, dark: string, text: string, light: string}} Color
 */

// Colors
/** @type {Color} */
export const RED = {
  main: '#d90000',
  dark: '#690000',
  text: '#ffffff',
  light: '#FF9999'
};

/** @type {Color} */
export const YELLOW = {
  main: '#ffe11c',
  dark: '#88780f',
  text: '#303030',
  light: '#FFF199'
};

/** @type {Color} */
export const GREEN = {
  main: '#6b8f00',
  dark: '#1f2a00',
  text: '#ffffff',
  light: '#D1FF47'
};

/** @type {Color} */
export const BLUE = {
  main: '#14a9fa',
  dark: '#084262',
  text: '#ffffff',
  light: '#87D3FD'
};

/** @type {Color} */
export const DEFAULT = {
  main: '#b9d07f',
  dark: '#1f2a01',
  text: '#303030',
  light: '#DEE9C3'
};

/** @type {Color} */
export const GREY = {
  main: '#7f7f7f',
  dark: '#303030',
  light: '#d5d5d5',
  text: '#ffffff'
};

export const GREY_SCALE = [
  '#f5f5f5',
  '#e0e0e0',
  '#dcdcdc',
  '#d5d5d5',
  '#7f7f7f',
  '#404040',
  '#303030'
];

// Alert Types
export const AlertTypeObjs = {
  EMERGENCY: {
    dashboardStatus: 'Emergency',
    color: RED,
    id: 10
  },
  ADMINISTRATOR: {
    dashboardStatus: 'Admin',
    color: YELLOW,
    id: 8
  },
  MEDICAL: {
    dashboardStatus: 'Medical',
    color: BLUE,
    id: 6
  },
  ALL_CLEAR: {
    dashboardStatus: 'All Clear',
    color: GREEN,
    id: 4
  },
  NORMAL: {
    dashboardStatus: 'Normal',
    color: DEFAULT,
    id: 2
  },
  UNKNOWN: {
    dashboardStatus: 'Unknown',
    color: GREY,
    id: 0
  }
};

const alertTypes = {};
for (const type in AlertTypeObjs) {
  alertTypes[type] = AlertType.thaw(AlertTypeObjs[type]);
}
export const AlertTypes = alertTypes;

export const StaffTypes = {
  TEACHER: 'Teacher',
  ADMINISTRATOR: 'Administrator',
  SUBSTITUTE_TEACHER: 'Substitute Teacher',
  NURSE: 'Nurse',
  OFFICE_PERSONNEL: 'Office Personnel',
  SECURITY: 'Security',
  CUSTODIAN: 'Custodian',
  COACH: 'Coach',
  FOOD_SERVICE: 'Food Service',
  BUS_DRIVER: 'Bus Driver'
};

// List Views
export const DeviceTypes = {
  STAFF: 'Staff',
  ROOMS: 'Rooms',
  HUBS: 'Hubs'
};
export const DEVICE_TYPES = [
  DeviceTypes.STAFF,
  DeviceTypes.ROOMS,
  DeviceTypes.HUBS
];

export const HubTypes = {
  ROOM: 'Room',
  DOOR: 'Door',
  HALLWAY: 'Hallway'
};

export const UserTypes = {
  SUPER: {
    name: 'Super',
    index: 4
  },
  IT: {
    name: 'IT',
    index: 3
  },
  ADMIN: {
    name: 'Admin',
    index: 2
  },
  STAFF: {
    name: 'Staff',
    index: 1
  }
};

export const ValidCreateUserTypes = {
  ADMIN: 'Admin'
};

/**
 * @param {any} obj
 * @return {obj is Exclude<any, undefined | null>}
 */
export const isNotNull = (obj) => {
  return obj !== null && obj !== undefined;
};


/**
 * @param {any} variable
 * @return {variable is (null | undefined)}
 */
export function isNull(variable) {
  return variable === null || variable === undefined;
}

export function isValidPassword(password) {
  if (password.length < 8) {
    return false;
  }

  var hasUpperCase = /[A-Z]/.test(password);
  var hasLowerCase = /[a-z]/.test(password);
  var hasNumbers = /\d/.test(password);
  var hasNonalphas = /\W/.test(password);

  if (!(hasUpperCase && hasLowerCase && hasNumbers && hasNonalphas)) {
    return false;
  }

  return true;
}

export function isEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function isValidPhoneNumber(phoneNumber) {
  var re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
  return re.test(phoneNumber);
}

export const HelpDescriptions = {
  TEMP: {
    name: 'Temporary',
    body: 'This is a temporary holder for the actual description.'
  }
};

/**
 * @type {Record<string, {name: string, value: function, values?: {name: string, value: any, active: boolean, nullMatch?: boolean}[], autoPopulate?: { name: string, value: any }}[]>}
 */
export const FilterOptions = {
  USER_LIST: [
    {
      'name': 'User Type',
      /** @param {import('../lib').User} user */
      value: (user) => user.getUserType(),
      'values': [
        {
          'name': 'Staff',
          'value': 'Staff',
          'active': false
        },
        {
          'name': 'Admin',
          'value': 'Admin',
          'active': false
        },
        {
          'name': 'IT',
          'value': 'IT',
          'active': false
        },
        {
          'name': 'Super',
          'value': 'Super',
          'active': false
        },
      ]
    },
    {
      'name': 'Activity',
      /** @param {import('../lib').User} user */
      value: (user) => user.getIsActive(),
      'values': [
        {
          'name': 'Active',
          'value': true,
          'active': false
        },
        {
          'name': 'Inactive',
          'value': false,
          'active': false
        }
      ]
    },
    {
      'name': '2FA Setup',
      /** @param {import('../lib').User} user */
      value: (user) => user.getMultiFactorSetupRequired(),
      'values': [
        {
          'name': 'Yes',
          'value': 0,
          'active': false
        },
        {
          'name': 'No',
          'value': 1,
          'active': false
        }
      ]
    },
    {
      'name': 'Password Changed',
      /** @param {import('../lib').User} user */
      value: (user) => user.getPasswordResetRequired(),
      'values': [
        {
          'name': 'Yes',
          'value': 0,
          'active': false
        },
        {
          'name': 'No',
          'value': 1,
          'active': false
        }
      ]
    }
  ],
  ROOM_LIST: [
    {
      'name': 'Status',
      /** @param {import('../lib').Room} room */
      value: (room) => room.getAlertStatusID(),
      'values': [
        {
          'name': 'Emergency',
          'value': 9,
          'active': false
        },
        {
          'name': 'Admin',
          'value': 7,
          'active': false
        },
        {
          'name': 'Medical',
          'value': 5,
          'active': false
        },
        {
          'name': 'All Clear',
          'value': 3,
          'active': false
        },
        {
          'name': 'Normal',
          'value': 2,
          'active': false
        },
        {
          'name': 'Unknown',
          'value': 0,
          'active': false,
          'nullMatch': true
        }
      ]
    }
  ],
  STAFF_LIST: [
    // ----- THE BELOW SECTION ISN'T CURRENTLY NEEDED BUT MAY BE LATER ---
    // {
    //   'name': 'Status',
    //   /** @param {import('../lib').StaffMember} staff */
    //   value: (staff) => {
    //     /** @type {import('../lib').AlertUpdate[] | null} */
    //     const alertUpdates = staff.getAlertUpdates();
    //     if (alertUpdates != null && alertUpdates.length > 0) {
    //       alertUpdates?.reduce((mostRecent, curr) => {
    //         if (curr.getTimestamp().toMillis() > mostRecent.getTimestamp().toMillis()) {
    //           return curr;
    //         }
    //         else {
    //           return mostRecent;
    //         }
    //       }, alertUpdates[0]);
    //     }
    //     else {
    //       return null;
    //     }
    //   },
    //   'values': [
    //     {
    //       'name': 'Emergency',
    //       'value': 9,
    //       'active': false
    //     },
    //     {
    //       'name': 'Admin',
    //       'value': 7,
    //       'active': false
    //     },
    //     {
    //       'name': 'Medical',
    //       'value': 5,
    //       'active': false
    //     },
    //     {
    //       'name': 'All Clear',
    //       'value': 3,
    //       'active': false
    //     },
    //     {
    //       'name': 'Normal',
    //       'value': 2,
    //       'active': false
    //     },
    //     {
    //       'name': 'Unknown',
    //       'value': 0,
    //       'active': false,
    //       'nullMatch': true
    //     }
    //   ]
    // },
    {
      'name': 'Badge Status',
      /** @param {import('../lib').StaffMember} staff */
      value: (staff) => {
        /** @type {import('../lib').Badge}*/
        const badge = staff.getBadge();
        if (badge != null) {
          return badge.getIsConnected();
        }
        else {
          return null;
        }
      },
      'values': [
        {
          'name': 'Connected',
          'value': true,
          'active': false
        },
        {
          'name': 'Not Connected',
          'value': false,
          'active': false
        },
        {
          'name': 'Not Assigned',
          'value': null,
          'active': false
        }
      ]
    },
    {
      name: 'Badge Power',
      /** @param {import('../lib').StaffMember} staff */
      value: (staff) => {
        /** @type {import('../lib').Badge}*/
        const badge = staff.getBadge();
        if (badge?.getBatteryLevel() != null) {
          return badge.getBatteryLevel() < 30;
        }
        else {
          return null;
        }
      },
      'values': [
        {
          'name': 'Low',
          'value': true,
          'active': false
        },
        {
          'name': 'OK',
          'value': false,
          'active': false
        },
        {
          'name': 'Unknown',
          'value': null,
          'active': false
        }
      ]
    },
    {
      'name': 'Staff Type',
      /** @param {import('../lib').StaffMember} staff */
      value: (staff) => staff.getStaffType()?.getName() ?? null,
      'values': [
        {
          'name': 'Administrator',
          'value': 'Administrator',
          'active': false
        },
        {
          'name': 'Substitute Teacher',
          'value': 'Substitute Teacher',
          'active': false
        },
        {
          'name': 'Nurse',
          'value': 'Nurse',
          'active': false
        },
        {
          'name': 'Office Personnel',
          'value': 'Office Personnel',
          'active': false
        },
        {
          'name': 'Security',
          'value': 'Security',
          'active': false
        },
        {
          'name': 'Teacher',
          'value': 'Teacher',
          'active': false
        }
      ]
    }
  ],
  HUB_LIST: [
    {
      'name': 'Hub Type',
      /** @param {import('../lib').Hub} hub */
      value: (hub) => hub.getHubType(),
      'values': [
        {
          'name': 'Room',
          'value': 'Room',
          'active': false
        },
        {
          'name': 'Door',
          'value': 'Door',
          'active': false
        },
        {
          'name': 'Hallway',
          'value': 'Hallway',
          'active': false
        }
      ]
    },
    {
      'name': 'Status',
      /** @param {import('../lib').Hub} hub */
      value: (hub) => hub.getRoom()?.getAlertStatusID() ?? null,
      'values': [
        {
          'name': 'Emergency',
          'value': 9,
          'active': false
        },
        {
          'name': 'Admin',
          'value': 7,
          'active': false
        },
        {
          'name': 'Medical',
          'value': 5,
          'active': false
        },
        {
          'name': 'All Clear',
          'value': 3,
          'active': false
        },
        {
          'name': 'Normal',
          'value': 2,
          'active': false
        },
        {
          'name': 'Unknown',
          'value': 0,
          'active': false,
          'nullMatch': true
        }
      ]
    },
    {
      'name': 'Connected',
      /** @param {import('../lib').Hub} hub */
      value: (hub) => hub.getIsConnected(),
      'values': [
        {
          'name': 'Connected',
          'value': true,
          'active': false
        },
        {
          'name': 'Not Connected',
          'value': false,
          'active': false,
          'nullMatch': true
        }
      ]
    },
    {
      'name': 'Firmware Version',
      /** @param {import('../lib').Hub} hub */
      value: (hub) => hub.getFirmwareVersion(),
      'autoPopulate': {
        name: 'v#{value}',
        value: '#{value}'
      }
    }
  ]
};

export const MOBILE_WIDTH = 970;
export const FULL_WIDTH = 1280;

/**
 * @param {any} variable
 * @return {variable is string}
 */
export const isString = (variable) => {
  return typeof variable === 'string' || variable instanceof String;
};

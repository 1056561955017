import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import DefaultPortrait from '../images/DefaultPortrait.png';

// Icons
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';

// Components
import InfoPanel from './InfoPanel';
import BadgeStatusWidget from './BadgeStatusWidget';
import AlertUpdateWidget from './AlertUpdateWidget';
import InfoDialog from './InfoDialog';

// Constants
import { GREY, isNull } from '../constants';
import { DateTime } from 'luxon';

const HEADER = (staffMember) => {
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <img
          height={130}
          width={130}
          mode={'contain'}
          src={staffMember.getImagePath() ?? DefaultPortrait}
          alt={'Staff Member'}
          style={{ marginTop: '10px', marginBottom: '10px', borderRadius: '65px' }} />
        <Typography variant='h4'>{staffMember.getName()}</Typography>
        <Typography variant='overline' style={{ color: GREY.dark }}>{staffMember?.getStaffType()?.getName()}</Typography>
      </div>
    </>
  );
};

const CHILDREN = (staffMember) => {
  return (
    <>
      {/* --- Contact Info --- */}
      <div>
        <Grid container direction='column' style={{ marginBottom: '1em' }}>
          {/* Phone Number */}
          {staffMember.getPhoneNumber() &&
          <Grid item container direction='row' justify='center' align='center'>
            <Grid item style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <PhoneIcon style={{ fontSize: 20, color: GREY.main, marginRight: '10px' }} />
            </Grid>
            <Grid item>
              <Typography variant='h6' style={{ color: GREY.main }}>{staffMember.getPhoneNumber()}</Typography>
            </Grid>
          </Grid>}

          {/* Email */}
          {staffMember.getEmail() &&
          <Grid item container direction='row' justify='center' align='center'>
            <Grid item style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <MailIcon style={{ fontSize: 20, color: GREY.main, marginRight: '10px' }} />
            </Grid>
            <Grid item>
              <Typography variant='h6' style={{ color: GREY.main }}>{staffMember.getEmail()}</Typography>
            </Grid>
          </Grid>}
        </Grid>
      </div>

      {/* --- Last Seen --- */}
      {(staffMember.getBadge()?.getLastSeenRoom()?.getIdentifier() && staffMember.getBadge()?.getLastSeenTimestamp()) ?
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1em' }}>
          <Typography variant='overline' style={{ color: GREY.dark }}>Last Seen</Typography>
          <Typography variant='h6' style={{ color: GREY.dark }}>Room {staffMember.getBadge()?.getLastSeenRoom()?.getIdentifier()}</Typography>
          <Typography variant='h6' style={{ color: GREY.dark }}>@ {staffMember.getBadge()?.getLastPositionTimestamp().toLocaleString(DateTime.DATETIME_SHORT)}</Typography>
        </div> :
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1em' }}>
          <Typography variant='overline' style={{ color: GREY.dark }}>Last Seen: Unknown</Typography>
        </div>
      }

      {/* --- Device Status --- */}
      {staffMember.getBadge() &&
        <BadgeStatusWidget badge={staffMember.getBadge()} />}

      {/* Logs */}
      <AlertUpdateWidget updates={staffMember.getAlertUpdates()} />
    </>
  );
};

export default function StaffMemberInfoPanel({ staffMember, isMobile, handleClose }) {
  return isMobile ? (
    <InfoDialog
      statusColor={staffMember.getBadge()?.getLastSeenRoom()?.getAlertStatus().color.main}
      header={HEADER(staffMember)}
      handleClose={handleClose}
      open={!isNull(staffMember)}
      editHref={'/admin/staff/' + staffMember.getID()}
    >
      {CHILDREN(staffMember)}
    </InfoDialog>
  ) : (
    <InfoPanel
      statusColor={staffMember.getBadge()?.getLastSeenRoom()?.getAlertStatus().color.main}
      header={HEADER(staffMember)}
      editHref={'/admin/staff/' + staffMember.getID()}
    >
      {CHILDREN(staffMember)}
    </InfoPanel>
  );
}

StaffMemberInfoPanel.propTypes = {
  staffMember: PropTypes.object,
  isMobile: PropTypes.bool,
  handleClose: PropTypes.func,
  setupMode: PropTypes.bool.isRequired
};

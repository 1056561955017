const { Room } = require('./');

export default class RoomVertex {
  static thaw(roomVertexObj) {
    if (roomVertexObj == null) return null;
    const roomVertex = new RoomVertex();
    if (roomVertexObj.id != null) roomVertex.setID(roomVertexObj.id);
    if (roomVertexObj.x != null) roomVertex.setX(roomVertexObj.x);
    if (roomVertexObj.y != null) roomVertex.setY(roomVertexObj.y);
    if (roomVertexObj.order != null) roomVertex.setOrder(roomVertexObj.order);
    if (roomVertexObj.roomId != null) roomVertex.setRoomId(roomVertexObj.roomId);
    if (roomVertexObj.room != null) roomVertex.setRoom(Room.thaw(roomVertexObj.room));
    return roomVertex;
  }

  static thawList(roomVertexObjList) {
    if (roomVertexObjList == null) return [];
    var roomVertexs = [];
    for (var i = 0; i < roomVertexObjList.length; i++) {
      roomVertexs.push(RoomVertex.thaw(roomVertexObjList[i]));
    }
    return roomVertexs;
  }

  static freeze(roomVertex) {
    if (roomVertex == null) return null;
    var roomVertexObj = {};
    if (roomVertex.getID() != null) roomVertexObj.id = roomVertex.getID();
    if (roomVertex.getX() != null) roomVertexObj.x = roomVertex.getX();
    if (roomVertex.getY() != null) roomVertexObj.y = roomVertex.getY();
    if (roomVertex.getOrder() != null) roomVertexObj.order = roomVertex.getOrder();
    if (roomVertex.getRoomId() != null) roomVertexObj.roomId = roomVertex.getRoomId();
    if (roomVertex.getRoom() != null) roomVertexObj.room = Room.freeze(roomVertex.getRoom());
    return roomVertexObj;
  }

  static freezeList(roomVertexs) {
    if (roomVertexs == null) return [];
    var roomVertexObjList = [];
    for (var i = 0; i < roomVertexs.length; i++) {
      roomVertexObjList.push(RoomVertex.freeze(roomVertexs[i]));
    }
    return roomVertexObjList;
  }

  constructor() {
    this.setID(null);
    this.setX(null);
    this.setY(null);
    this.setOrder(null);
    this.setRoomId(null);
    this.setRoom(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setX(x) {
    this.x = x;
  }

  setY(y) {
    this.y = y;
  }

  setOrder(order) {
    this.order = order;
  }

  setRoomId(roomId) {
    this.roomId = roomId;
  }

  setRoom(room) {
    this.room = room;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getX() {
    return this.x;
  }

  getY() {
    return this.y;
  }

  getOrder() {
    return this.order;
  }

  getRoomId() {
    return this.roomId;
  }

  getRoom() {
    return this.room;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

}

import React, { Component } from 'react';
import {
  Avatar, Typography, TextField, Button, MenuItem
} from '@material-ui/core';
import { Container as DragContainer, Draggable } from 'react-smooth-dnd';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { setSuccess, clearErrors, setAlert } from '../redux/actions/alertActions';

// Server Code
import { listFloors, listBuildings, createFloor, updateFloor } from '../api';

// Components
import InfoButton from './InfoButton';

// Library
import { Floor } from '../lib';

// Constants
import { DEFAULT } from '../constants';
const FLOOR_PLAN_HELP = 'This floor plan will be visible on the map view. This is where you will map our your rooms to show where the alerts are coming from. This must be in an image format (png/jpg/jpeg).';
const FLOOR_IDENTIFIER_HELP = 'This is a 2-character identifier for a floor, typically something like B, 1, 2, 3, etc.';
const FLOOR_POSITION_HELP = 'Drag the new floor to its correct order position.';
const IMAGE_WIDTH_METERS_HELP = 'Total width of the image (including any whitespace on the sides) in meters. It is important this is relatively accurate for the location pinpointing to be accurate.';
const LATITUDE_HELP = 'Latitude of the top left corner of the floor plan image. An approximation is fine.';
const LONGITUDE_HELP = 'Longitude of the top left corner of the floor plan image. An approximation is fine.';
const NEW_FLOOR = new Floor();
NEW_FLOOR.setIdentifier('NEW FLOOR');

class FloorForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      buildingID: '',
      floorPlanImagePath: '',
      floorPlanImage: null,
      floorIdentifier: '',
      imageWidthM: '',
      latLeftTop: '',
      longLeftTop: '',
      formError: false,
      floors: [],
      buildings: [],
      isLoading: false
    };

    this.onImageInput = this.onImageInput.bind(this);
  }

  componentDidMount() {

    // If floor is passed in we should populate our state with its values.
    if (this.props.floor != null) {
      const floor = this.props.floor;
      this.setState({
        buildingID: floor.getBuildingID() || '',
        floorIdentifier: floor.getIdentifier() || '',
        imageWidthM: floor.getImageWidthM() || '',
        latLeftTop: floor.getLatLeftTop() || '',
        longLeftTop: floor.getLongLeftTop() || '',
      });

      this.updateFloorPositionWidget(floor.getBuildingID());
    }

    listBuildings().then(buildings => {
      this.setState({ buildings: buildings });
    }).catch(error => {
      this.props.setError(error ? error : 'Unable to retrieve buildings.');
    });
  }

  componentWillUnmount() {
    this.props.clearErrors();
  }

  hasFormError() {
    return this.state.buildingID === '' || this.state.floorIdentifier === '' || this.state.imageWidthM === '' || this.state.latLeftTop === '' || this.state.longLeftTop === '';
  }

  onChange = (e) => {
    if (e.target.name === 'buildingID') {
      this.updateFloorPositionWidget(e.target.value);
    }
    else if (e.target.name === 'floorIdentifier') {
      if (e.target.value.length > 2) return;
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  updateFloorPositionWidget = (buildingID) => {
    listFloors(buildingID).then(floors => {
      if (this.props.floor == null) floors.push(NEW_FLOOR);
      this.setState({ floors });
    }).catch(error => {
      this.props.setError(error ? error : 'Unable to retrieve floors');
    });
  }

  onImageInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });

    // Set Image for Display
    var input = document.getElementById('floorPlanImagePath');
    var fReader = new FileReader();
    fReader.readAsDataURL(input.files[0]);
    this.setState({ floorPlanImage: input.files[0] });
    fReader.onloadend = (event) => {
      document.getElementById('displayImage').innerHTML = '<image src=\'' + event.target.result + '\' style=\'height: 60px;\'/>';
    };
  };

  clearImage = () => {
    this.setState({ floorPlanImagePath: '', floorPlanImage: null });
    document.getElementById('displayImage').innerHTML = null;
    this.fileInput.value = '';
  }

  submitForm = (e) => {
    e.preventDefault();

    // Ensure there is no form error
    const formError = this.hasFormError();
    this.setState({ formError });
    if (formError) return;

    const { floorIdentifier, buildingID, floorPlanImage, latLeftTop, longLeftTop, imageWidthM } = this.state;

    // Update the floor
    if (this.props.floor != null) {
      // Retrieve zIndex
      const zIndex = this.state.floors.findIndex(floor => floor.id === this.props.floor.id);

      this.setState({ isLoading: true });
      updateFloor({ id: this.props.floor.getID(), buildingID, identifier: floorIdentifier, zIndex, floorPlanImage, latLeftTop, longLeftTop, imageWidthM }).then(() => {
        this.props.setSuccess('Successfully updated Building Floor');
        this.clearImage();
        this.setState({ isLoading: false, floorIdentifier: '', buildingID: '', floors: [], floorPlanImagePath: '', floorPlanImage: null, latLeftTop: '', longLeftTop: '', imageWidthM: '' });
        this.props.onSuccess();
      }).catch(error => {
        this.props.setError(error ? error : 'Error: Unable to update floor.');
        this.setState({ isLoading: false });
      });
    }

    // Create a new floor
    else {
      // Retrieve zIndex
      const zIndex = this.state.floors.findIndex(floor => floor === NEW_FLOOR);

      this.setState({ isLoading: true });
      createFloor({ buildingID, zIndex, identifier: floorIdentifier, floorPlanImage, latLeftTop, longLeftTop, imageWidthM }).then(() => {
        this.props.setSuccess('Successfully created Building Floor');
        this.clearImage();
        this.setState({ isLoading: false, floorIdentifier: '', buildingID: '', floors: [], floorPlanImagePath: '', floorPlanImage: null, latLeftTop: '', longLeftTop: '', imageWidthM: '' });
        this.props.onSuccess();
      }).catch(error => {
        this.props.setError(error ? error : 'Error: Unable to create floor.');
        this.setState({ isLoading: false });
      });
    }
  }

  onDropFloor = ({ removedIndex, addedIndex }) => {
    this.setState({ floors: this.arrayMove(this.state.floors, removedIndex, addedIndex) });
  };

  arrayMove = (arr, removedIndex, addedIndex) => {
    if (removedIndex === addedIndex) return arr;

    let newArr = [];
    let i = 0;
    while (i < arr.length) {
      let push1 = null;
      let push2 = null;

      if (i === addedIndex) {
        if (addedIndex < removedIndex) push1 = arr[removedIndex];
        else push2 = arr[removedIndex];
      }

      if (i !== removedIndex) {
        if (addedIndex < removedIndex) push2 = arr[i];
        else push1 = arr[i];
      }

      if (push1 != null) newArr.push(push1);
      if (push2 != null) newArr.push(push2);

      i++;
    }
    return newArr;
  }

  render() {
    return (
      <form onSubmit={this.submitForm} autoComplete='off' style={{ width: '100%' }}>

        {/* Select Building */}
        <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between', marginBottom: '10px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: (this.props.isMobile ? '100%' : '40%') }}>
            <Typography variant='h6'>Select Building</Typography>
          </div>
          <TextField
            required
            select
            error={this.state.formError && (this.state.buildingID === '' || this.state.buildingID == null)}
            name='buildingID'
            label='Building Name'
            value={this.state.buildingID}
            style={{ width: (this.props.isMobile ? '100%' : '60%') }}
            onChange={this.onChange}
            variant='filled'>
            {this.state.buildings.map((building) => (
              <MenuItem key={building.getID()} value={building.getID()}>
                { building.getName()}
              </MenuItem>
            ))}
          </TextField>
        </div>

        {/* Select Floor Plan */}
        <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between', marginBottom: '10px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: (this.props.isMobile ? '100%' : '40%') }}>
            <Typography variant='h6'>Upload Floor Plan</Typography>
            <InfoButton name={'Floor Plan'} body={FLOOR_PLAN_HELP} color={this.props.statusColor} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: (this.props.isMobile ? '100%' : '60%') }}>
            <input type="file" id='floorPlanImagePath' name='floorPlanImagePath' label="Floor Plan" ref={ref=> this.fileInput = ref} onChange={this.onImageInput} style={{ width: '60%' }} />
            <div id="displayImage">
              <Avatar />
            </div>
          </div>
        </div>

        {/* Floor Identifier Input */}
        <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between', marginBottom: '10px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: (this.props.isMobile ? '100%' : '40%') }}>
            <Typography variant='h6'>Floor Identifier</Typography>
            <InfoButton name='Floor Identifier' body={FLOOR_IDENTIFIER_HELP} color={this.props.statusColor} />
          </div>
          <TextField required error={this.state.formError && this.state.floorIdentifier === ''} name='floorIdentifier' label='Floor Identifier' style={{ width: (this.props.isMobile ? '100%' : '60%') }} onChange={this.onChange} value={this.state.floorIdentifier} variant='filled' />
        </div>

        {/* Image Width Meters Input */}
        <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between', marginBottom: '10px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: (this.props.isMobile ? '100%' : '40%') }}>
            <Typography variant='h6'>Image Width (Meters)</Typography>
            <InfoButton name='Image Width (Meters)' body={IMAGE_WIDTH_METERS_HELP} color={this.props.statusColor} />
          </div>
          <TextField type='number' required error={this.state.formError && this.state.imageWidthM === ''} name='imageWidthM' label='Image Width (Meters)' style={{ width: (this.props.isMobile ? '100%' : '60%') }} onChange={this.onChange} value={this.state.imageWidthM} variant='filled' />
        </div>

        {/* Latitude Input */}
        <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between', marginBottom: '10px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: (this.props.isMobile ? '100%' : '40%') }}>
            <Typography variant='h6'>Latitude (Top Left)</Typography>
            <InfoButton name='Latitude (Top Left)' body={LATITUDE_HELP} color={this.props.statusColor} />
          </div>
          <TextField type='number' required error={this.state.formError && this.state.latLeftTop === ''} name='latLeftTop' label='Latitude (Top Left)' style={{ width: (this.props.isMobile ? '100%' : '60%') }} onChange={this.onChange} value={this.state.latLeftTop} variant='filled' />
        </div>

        {/* Longitude Input */}
        <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between', marginBottom: '10px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: (this.props.isMobile ? '100%' : '40%') }}>
            <Typography variant='h6'>Longitude (Top Left)</Typography>
            <InfoButton name='Longitude (Top Left)' body={LONGITUDE_HELP} color={this.props.statusColor} />
          </div>
          <TextField type='number' required error={this.state.formError && this.state.longLeftTop === ''} name='longLeftTop' label='Longitude (Top Left)' style={{ width: (this.props.isMobile ? '100%' : '60%') }} onChange={this.onChange} value={this.state.longLeftTop} variant='filled' />
        </div>

        {/* Select Floor Position */}
        <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: (this.props.isMobile ? '100%' : '40%') }}>
            <Typography variant='h6'>Floor Position</Typography>
            <InfoButton name='Floor Position' body={FLOOR_POSITION_HELP} color={this.props.statusColor} />
          </div>
          <div style={{ width: (this.props.isMobile ? '100%' : '60%') }}>
            <DragContainer onDrop={this.onDropFloor}>
              {this.state.floors.map((floor, index) =>
                <FloorDraggableTile key={index} floor={floor} color={this.props.statusColor} draggable={floor === NEW_FLOOR || floor.getID() === this.props.floor?.getID()} />
              )}
            </DragContainer>
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <Button type='submit' disabled={this.state.isLoading} variant='contained' style={{ width: '200px', height: '50px', margin: '30px', backgroundColor: this.props.statusColor.main, color: this.props.statusColor.text }}>Save</Button>
        </div>
      </form>
    );
  }
}

function FloorDraggableTile({ floor, color, draggable = false }) {

  let item = (
    <div style={{ display: 'flex', flexDirection: 'row', backgroundColor: draggable ? color.main : 'lightgrey', color: draggable ? color.text : 'white', marginBottom: '1px', padding: 5 }}>
      <Typography variant='body1' style={{ userSelect: 'none', flex: 1 }}>{floor.getIdentifier()}</Typography>
      {draggable && <DragHandleIcon style={{ color: color.text }} />}
    </div>
  );

  return draggable ?
    <Draggable>{item}</Draggable> :
    item;
}

const mapStateToProps = state => ({
  statusColor: state.status.schoolStatus ? state.status.schoolStatus.getColor() : DEFAULT,
  isMobile: state.ui.isMobile,
  isFullWidth: state.ui.isFullWidth
});

const mapDispatchToProps = (dispatch) => ({
  setError: (msg) => {
    dispatch(clearErrors());
    setAlert(dispatch, msg);
  },
  setSuccess: (msg) => setSuccess(dispatch, msg),
  clearErrors: () => dispatch(clearErrors())
});

export default connect(mapStateToProps, mapDispatchToProps)(FloorForm);

FloorForm.propTypes = {
  setError: PropTypes.func,
  setSuccess: PropTypes.func,
  isMobile: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  statusColor: PropTypes.object,
  color: PropTypes.object,
  clearErrors: PropTypes.func,
  floor: PropTypes.object,
  onSuccess: PropTypes.func
};

FloorDraggableTile.propTypes = {
  floor: PropTypes.object,
  color: PropTypes.object,
  draggable: PropTypes.bool
};

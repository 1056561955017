import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Typography } from '@material-ui/core';
import Table from './Table';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Constants
import { DEFAULT } from '../constants';

// Props: title, columns, retrievalFunction, buttons (array)
class ListTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: []
    };
  }

  componentDidMount() {
    this.props.retrievalFunction().then(rows => {
      this.setState({ rows: rows });
    }).catch(error => {
      console.log(error);
    });
  }

  render() {
    return (
      <Container maxWidth={false} style={{ padding: '0em 2em 1em 2em' }}>
        <Typography variant='h1'>{this.props.title}</Typography>

        <Table
          title={
            this.props.hideButton ? '' : (
              <Button variant='contained' component={Link} to={this.props.buttonLink} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: this.props.statusColor.main, color: this.props.statusColor.text, margin: '5px 0px 5px 0px' }}>
                {this.props.buttonText}
                <AddIcon style={{ marginLeft: '5px' }} />
              </Button>
            )
          }
          data={this.state.rows}
          columns={this.props.columns.map((column) => { /* Workaround for a known material-table bug https://github.com/mbrn/material-table/issues/2451 */
            return { ...column };
          })}
          options={{
            pageSize: 10
          }}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  statusColor: state.status.schoolStatus ? state.status.schoolStatus.getColor() : DEFAULT,
});

export default connect(mapStateToProps, null)(ListTable);

ListTable.propTypes = {
  retrievalFunction: PropTypes.func,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  title: PropTypes.string,
  statusColor: PropTypes.object,
  columns: PropTypes.array,
  hideButton: PropTypes.bool
};

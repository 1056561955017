import React, { Component } from 'react';
import {
  Typography, TextField, Button, CircularProgress
} from '@material-ui/core';
import PropTypes from 'prop-types';

// Components
import InfoButton from './InfoButton';

// Redux
import { connect } from 'react-redux';
import { setSuccess, clearErrors, setAlert } from '../redux/actions/alertActions';

// Constants
import { DEFAULT, HelpDescriptions } from '../constants';

// Server Code
import { createBuilding, updateBuilding } from '../api';

class CreateBuilding extends Component {

  constructor(props) {
    super(props);

    this.state = {
      buildingName: '',
      buildingCode: '',
      formError: false,
      isLoading: false
    };
  }

  componentDidMount() {
    // If building is passed in we should populate our state with its values.
    if (this.props.building != null) {
      const building = this.props.building;
      this.setState({
        buildingName: building.getName() || '',
        buildingCode: building.getCode() || ''
      });
    }
  }

  componentWillUnmount() {
    this.props.clearErrors();
  }

  hasFormError() {
    return this.state.buildingName === '' || this.state.buildingCode === '';
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitForm = (e) => {
    e.preventDefault();

    // Ensure there is no form error
    const formError = this.hasFormError();
    this.setState({ formError });
    if (formError) return;

    const { buildingName, buildingCode } = this.state;

    // Update Existing Building
    if (this.props.building != null) {
      this.setState({ isLoading: true });
      updateBuilding({ id: this.props.building.getID(), name: buildingName }).then(() => {
        this.props.setSuccess('Successfully updated Building');
        this.setState({ isLoading: false, buildingName: '', buildingCode: '' });
        this.props.onSuccess();
      }).catch(error => {
        this.props.setError(error ? error : 'Error: Unable to updated building.');
        this.setState({ isLoading: false });
      });
    }

    // Create New Building
    else {
      this.setState({ isLoading: true });
      createBuilding({ name: buildingName, code: buildingCode }).then(() => {
        this.props.setSuccess('Successfully created Building');
        this.setState({ isLoading: false, buildingName: '', buildingCode: '' });
        this.props.onSuccess();
      }).catch(error => {
        this.props.setError(error ? error : 'Error: Unable to create building.');
        this.setState({ isLoading: false });
      });
    }
  }

  render() {
    return (
      <form onSubmit={this.submitForm} autoComplete="off" style={{ width: '100%' }}>
        {/* Building Name Input */}
        <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: (this.props.isMobile ? '100%' : '40%') }}>
            <Typography variant='h6'>Building Name</Typography>
            <InfoButton name={HelpDescriptions.TEMP.name} body={HelpDescriptions.TEMP.body} color={this.props.statusColor} />
          </div>
          <TextField required error={this.state.formError && this.state.buildingName === ''} name="buildingName" label="Building Name (ex: Main Building)" style={{ width: (this.props.isMobile ? '100%' : '60%') }} value={this.state.buildingName} onChange={this.onChange} variant='filled' />
        </div>

        {/* Building Code Input */}
        <div style={{ marginTop: '10px', display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: (this.props.isMobile ? '100%' : '40%') }}>
            <Typography variant='h6'>Building Code</Typography>
            <InfoButton name={HelpDescriptions.TEMP.name} body={HelpDescriptions.TEMP.body} color={this.props.statusColor} />
          </div>
          <TextField disabled={this.props.building != null} required error={this.state.formError && this.state.buildingCode === ''} name="buildingCode" label="Building Code (ex: MAIN)" style={{ width: (this.props.isMobile ? '100%' : '60%') }} value={this.state.buildingCode} onChange={this.onChange} variant='filled' />
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <Button type='submit' variant="contained" style={{ width: '200px', height: '50px', margin: '30px', backgroundColor: this.props.statusColor.main, color: this.props.statusColor.text }} disabled={this.state.isLoading}>
            Save
            {this.state.isLoading && <CircularProgress size={25} style={{ color: this.props.statusColor.text, marginLeft: '1em' }} />}
          </Button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  statusColor: state.status.schoolStatus ? state.status.schoolStatus.getColor() : DEFAULT,
  isMobile: state.ui.isMobile,
  isFullWidth: state.ui.isFullWidth
});

const mapDispatchToProps = (dispatch) => ({
  setError: (msg) => {
    dispatch(clearErrors());
    setAlert(dispatch, msg);
  },
  setSuccess: (msg) => setSuccess(dispatch, msg),
  clearErrors: () => dispatch(clearErrors())
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateBuilding);

CreateBuilding.propTypes = {
  clearErrors: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  statusColor: PropTypes.object,
  building: PropTypes.object,
  onSuccess: PropTypes.func.isRequired
};

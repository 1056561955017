import { createTransform } from 'redux-persist';

import { AlertType } from '../../lib';

const StatusTransform = createTransform(
  // Transform state on its way to being serialized and persisted (frozen)
  (inboundState) => {
    return {
      ...inboundState,
      schoolStatus: AlertType.freeze(AlertType.thaw(inboundState.schoolStatus))
    };
  },
  // Transform state being rehydrated (thawed)
  (outboundState) => {
    return {
      ...outboundState,
      isLoading: false,
      schoolStatus: AlertType.thaw(outboundState.schoolStatus)
    };
  },
  // Define which reducers this transform gets called for.
  { whitelist: ['status'] }
);

export default StatusTransform;

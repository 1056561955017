import { AlertType } from './';

export default class BadgeType {
  static thaw(badgeTypeObj) {
    if (badgeTypeObj == null) return null;
    const badgeType = new BadgeType();
    if (badgeTypeObj.id != null) badgeType.setID(badgeTypeObj.id);
    if (badgeTypeObj.name != null) badgeType.setName(badgeTypeObj.name);
    if (badgeTypeObj.isActive != null) badgeType.setIsActive(badgeTypeObj.isActive == null ? null : (badgeTypeObj.isActive ? true : false));
    if (badgeTypeObj.specialAlertTypes != null) badgeType.setSpecialAlertTypes(AlertType.thawList(badgeTypeObj.specialAlertTypes));
    return badgeType;
  }

  static thawList(badgeTypeObjList) {
    if (badgeTypeObjList == null) return [];
    var badgeTypes = [];
    for (var i = 0; i < badgeTypeObjList.length; i++) {
      badgeTypes.push(BadgeType.thaw(badgeTypeObjList[i]));
    }
    return badgeTypes;
  }

  static freeze(badgeType) {
    if (badgeType == null) return null;
    var badgeTypeObj = {};
    if (badgeType.getID() != null) badgeTypeObj.id = badgeType.getID();
    if (badgeType.getName() != null) badgeTypeObj.name = badgeType.getName();
    if (badgeType.getIsActive() != null) badgeTypeObj.isActive = badgeType.getIsActive() == null ? null : (badgeType.getIsActive() ? 1 : 0);
    if (badgeType.getSpecialAlertTypes() != null) badgeTypeObj.specialAlertTypes = AlertType.freezeList(badgeType.getSpecialAlertTypes());
    return badgeTypeObj;
  }

  static freezeList(badgeTypes) {
    if (badgeTypes == null) return [];
    var badgeTypeObjList = [];
    for (var i = 0; i < badgeTypes.length; i++) {
      badgeTypeObjList.push(BadgeType.freeze(badgeTypes[i]));
    }
    return badgeTypeObjList;
  }

  constructor() {
    this.setID(null);
    this.setName(null);
    this.setIsActive(null);
    this.setSpecialAlertTypes(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setName(name) {
    this.name = name;
  }

  setIsActive(isActive) {
    this.isActive = isActive;
  }

  setSpecialAlertTypes(specialAlertTypes) {
    this.specialAlertTypes = specialAlertTypes;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getName() {
    return this.name;
  }

  getIsActive() {
    return this.isActive;
  }

  getSpecialAlertTypes() {
    return this.specialAlertTypes;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

}

import Cookies from 'js-cookie';
import {
  loginUser as loginUserFromServer,
  logoutUser as logoutUserFromServer,
  getUser as getUserFromServer,
  verifyMultiFactor as verifyMultiFactorFromServer,
  confirmMultiFactor as confirmMultiFactorFromServer
} from '../../api';
import { setAlert, clearAlerts, clearErrors, AlertSeverity } from './alertActions';
import { checkSetupMode } from './setupModeActions';

import { isNull } from '../../constants';

export const IS_AUTH_LOADING = 'IS_AUTH_LOADING';
export const IS_AUTH_NOT_LOADING = 'IS_AUTH_NOT_LOADING';
export const INITIAL_LOGIN_SUCCESS = 'INITIAL_LOGIN_SUCCESS';
export const NOT_AUTHENTICATED = 'NOT_AUTHENTICATED';
export const NOT_TWO_FACTORED_AUTHENTICATED = 'NOT_TWO_FACTORED_AUTHENTICATED';
export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS';
export const PASSWORD_UPDATED = 'PASSWORD_UPDATED';

export const startLoading = () => ({
  type: IS_AUTH_LOADING
});

export const stopLoading = () => ({
  type: IS_AUTH_NOT_LOADING
});

export const initialLoginSuccess = (userID, userType, passwordResetRequired, multiFactorSetupRequired) => ({
  type: INITIAL_LOGIN_SUCCESS,
  userID,
  userType,
  passwordResetRequired,
  multiFactorSetupRequired
});

export const authenticatedSuccess = (userID = null, userType = null, passwordResetRequired = null, multiFactorSetupRequired = null) => ({
  type: AUTHENTICATED_SUCCESS,
  userID,
  userType,
  passwordResetRequired,
  multiFactorSetupRequired
});

export const notTwoFactoredAuthenticated = () => ({
  type: NOT_TWO_FACTORED_AUTHENTICATED
});

export const notAuthenticated = () => ({
  type: NOT_AUTHENTICATED
});

export const passwordUpdated = () => ({
  type: PASSWORD_UPDATED
});

const handleMobileChecking = (dispatch, mobileAccess) => {
  if (window && window.screen && window.screen.width <= 768 && !mobileAccess) {
    setAlert(dispatch, 'You do not have mobile access.');
    logoutUser(dispatch);
  }
};

export const checkAuthentication = (dispatch, userID, isAuthenticated) => {
  dispatch(startLoading());

  // If cookies don't exist but we have user data saved
  if ((isNull(Cookies.get('sie_sa_auth')) && !isNull(userID)) || (isNull(Cookies.get('sie_sa_2fa')) && !isNull(isAuthenticated))) {
    // Log out user entirely
    dispatch(notAuthenticated());
    Cookies.remove('sie_sa_auth');
    Cookies.remove('sie_sa_2fa');
  }

  // If cookie exists but we don't have the right user information saved in redux state
  else if ((Cookies.get('sie_sa_auth') && isNull(userID)) || (Cookies.get('sie_sa_2fa') && !isAuthenticated)) {
    // Fill in the user info
    getUserFromServer().then(user => {
      handleMobileChecking(dispatch, user.mobileAccess);
      dispatch(authenticatedSuccess(user.id, user.userType, user.passwordResetRequired, user.multiFactorSetupRequired));
    }).catch(() => {
      logoutUser(dispatch);
    });
  }

  else {
    dispatch(stopLoading());
  }
};

export const loginUser = (dispatch, email = null, password = null) => {
  dispatch(startLoading());

  // Attempt to log in the user
  loginUserFromServer(email, password)
    .then((user) => {
      dispatch(clearErrors());
      dispatch(initialLoginSuccess(user.id, user.userType, user.passwordResetRequired, user.multiFactorSetupRequired));
    })
    .catch((error) => {
      setAlert(
        dispatch,
        'Error: ' + error && error.response && error.response.data
          ? error.response.data
          : 'Could not log in.'
      );
      dispatch(notAuthenticated());
    });
};

export const authenticateUser = (dispatch, token) => {
  dispatch(startLoading());

  // Attempt to 2-factor authenticate the user
  verifyMultiFactorFromServer(token).then(() => {
    dispatch(clearErrors());
    dispatch(authenticatedSuccess());
    checkSetupMode(dispatch);
  }).catch(error => {
    setAlert(
      dispatch,
      'Error: ' + error && error.response && error.response.data
        ? error.response.data
        : 'Could not log in.'
    );
    dispatch(notTwoFactoredAuthenticated());
  });
};

export const logoutUser = (dispatch) => {
  // Revoke tokens and unauthenticate in redux state
  Cookies.remove('sie_sa_auth');
  Cookies.remove('sie_sa_2fa');
  dispatch(notAuthenticated());

  // Logout officially from server
  logoutUserFromServer()
    .then(() => {
      dispatch(clearAlerts());
      setAlert(dispatch, 'Log out successful', AlertSeverity.SUCCESS, true);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const forceLogoutUser = () => {
  // Revoke tokens
  Cookies.remove('sie_sa_auth');
  Cookies.remove('sie_sa_2fa');
  window.location.reload(false); // Hopefully we can do this more gracefully in the future.
};

export const submitSetupTwoFactorAuthentication = (dispatch, token) => {
  dispatch(startLoading());

  confirmMultiFactorFromServer(token).then(() => {
    dispatch(clearErrors());
    dispatch(authenticatedSuccess());
    setAlert(
      dispatch,
      'Successfully set up 2FA!',
      AlertSeverity.SUCCESS,
      true
    );
  }).catch(error => {
    setAlert(
      dispatch,
      'Error: ' + error && error.response && error.response.data
        ? error.response.data
        : 'Could not log in.'
    );
    dispatch(notTwoFactoredAuthenticated());
  });
};

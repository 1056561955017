import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
  AppBar,
  Button,
  Toolbar,
  IconButton,
  Hidden,
  Drawer,
  Typography
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import RefreshIcon from '@material-ui/icons/Refresh';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { setSchoolStatus, clearSchoolStatus } from '../redux/actions/statusActions';
import { logoutUser, checkAuthentication } from '../redux/actions/authActions';
import { checkSetupMode } from '../redux/actions/setupModeActions';

// Assets
import Logo from '../images/LogoWhiteBackground.png';

// Components
import SideMenu from './SideMenu';
import ConfirmStatusModal from '../components/ConfirmStatusModal';
import DashboardAppBar from './DashboardAppBar';

// Constants
import { AlertTypes, RED } from '../constants';

class AppToolBar extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isSidePanelOpen: false,
      showConfirmationModal: false,
      desiredStatus: null
    };
  }

  componentDidMount() {
    this.props.checkAuthentication(this.props.userID, this.props.isAuthenticated);
    if (this.props.isAuthenticated) this.props.checkSetupMode();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userID !== this.props.userID || prevProps.isAuthenticated !== this.props.isAuthenticated) {
      this.props.checkAuthentication(this.props.userID, this.props.isAuthenticated);
      if (this.props.isAuthenticated) this.props.checkSetupMode();
    }
  }

  toggleSideMenu = () => {
    this.setState({ isSidePanelOpen: !this.state.isSidePanelOpen });
  }

  handleOpenConfirmationModal = (desiredStatus) => {
    this.setState({ showConfirmationModal: true, desiredStatus: desiredStatus });
  };

  handleCloseConfirmationModal = () => {
    this.setState({ showConfirmationModal: false });
  };

  confirmStatus = (status) => {
    this.props.setSchoolStatus(status);
    this.handleCloseConfirmationModal();
  }

  clearStatus = () => {
    this.props.clearSchoolStatus();
    this.handleCloseConfirmationModal();
  }

  refreshSchoolStatus() {
    window.location.reload(false);
  }

  render() {
    return (
      <>
        <ConfirmStatusModal
          closeModal={this.handleCloseConfirmationModal}
          showModal={this.state.showConfirmationModal}
          setStatus={this.confirmStatus}
          clearStatus={this.clearStatus}
          desiredStatus={this.state.desiredStatus}
        />

        <AppBar style={{ backgroundColor: this.props.schoolStatus.getColor().main }} position='static'>
          <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>

            {/* --- LOGO --- */}
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Link to='/'>
                <img
                  height={'40px'}
                  mode={'fill'}
                  src={Logo}
                  alt={'Logo'}
                  style={{ marginTop: '5px', marginBottom: '5px', borderRadius: '5px' }}
                />
              </Link>

              {/* --- STATUS --- */}
              <div style={{ marginLeft: '15px' }}>
                <Typography style={{ color: this.props.schoolStatus.getColor().dark, fontSize: '20pt', fontWeight: 'bold' }}>
                  {this.props.schoolStatus && this.props.schoolStatus !== AlertTypes.NORMAL &&
                    this.props.schoolStatus.getDashboardStatus().toUpperCase()
                  }
                </Typography>
              </div>
            </div>

            {/* --- BUTTONS --- */}
            <div>
              {this.props.location.pathname !== '/' && this.props.isAuthenticated && !this.props.isMobile &&
                <Button href={'/'} style={{ color: this.props.schoolStatus.getColor().text, textTransform: 'none', marginRight: '10px' }}>Dashboard</Button>}

              {this.props.isAuthenticated &&
              <Hidden xsDown>
                <Button
                  onClick={() => this.handleOpenConfirmationModal(AlertTypes.EMERGENCY)}
                  style={{
                    backgroundColor: this.props.schoolStatus.getColor() === RED ? RED.dark : RED.main, color: RED.text,
                    textTransform: 'none',
                    fontSize: '15px',
                    fontWeight: 'BOLD',
                  }}>
                  Set Emergency Status
                </Button>
              </Hidden>}

              <IconButton
                aria-haspopup='true'
                onClick={this.refreshSchoolStatus.bind(this)}
                disabled={this.props.isLoadingSchoolStatus}>
                <RefreshIcon style={{ color: this.props.schoolStatus.getColor().text }} />
              </IconButton>

              {this.props.isAuthenticated &&
              <IconButton
                aria-controls='nav-menu'
                aria-haspopup='true'
                onClick={this.toggleSideMenu}>
                <MenuIcon style={{ color: this.props.schoolStatus.getColor().text }}/>
              </IconButton>}

              {this.props.isAuthenticated &&
              <Drawer anchor={'right'} open={this.state.isSidePanelOpen} onClose={this.toggleSideMenu}>
                <SideMenu
                  toggle={this.toggleSideMenu}
                  openConfirmationModal={this.handleOpenConfirmationModal}
                  logout={this.props.logout}
                  userType={this.props.userType}
                  isMobile={this.props.isMobile} />
              </Drawer>}
            </div>
          </Toolbar>
        </AppBar>

        {this.props.location?.pathname?.includes('/dashboard') &&
          <DashboardAppBar />
        }
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  schoolStatus: state.status.schoolStatus || AlertTypes.UNKNOWN,
  isLoadingSchoolStatus: state.status.isLoading,
  isAuthenticated: state.auth.isAuthenticated,
  userType: state.auth.userType,
  isMobile: state.ui.isMobile,
});

const mapDispatchToProps = dispatch => ({
  setSchoolStatus: (alertStatus) => setSchoolStatus(dispatch, alertStatus),
  clearSchoolStatus: () => clearSchoolStatus(dispatch),
  logout: () => {
    logoutUser(dispatch);

  },
  checkAuthentication: (userID, isAuthenticated) => checkAuthentication(dispatch, userID, isAuthenticated),
  checkSetupMode: () => checkSetupMode(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppToolBar));


AppToolBar.propTypes = {
  checkAuthentication: PropTypes.func.isRequired,
  checkSetupMode: PropTypes.func.isRequired,
  userID: PropTypes.number,
  isAuthenticated: PropTypes.bool,
  setSchoolStatus: PropTypes.func,
  isLoadingSchoolStatus: PropTypes.bool,
  clearSchoolStatus: PropTypes.func,
  schoolStatus: PropTypes.object,
  location: PropTypes.object,
  isMobile: PropTypes.bool,
  logout: PropTypes.func,
  userType: PropTypes.any
};

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Typography, TextField, Button, MenuItem
} from '@material-ui/core';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { setSuccess, setAlert, clearErrors } from '../redux/actions/alertActions';

// Components
import InfoButton from './InfoButton';

// Server
import { listBadgeTypes, listUnassignedStaff, createBadge, listUnassignedBadges } from '../api';

// Constants
import { DEFAULT } from '../constants';
const SERIAL_NUMBER_HELP = 'The serial number of the badge that you would like to assign.';
const STAFF_MEMBER_HELP = 'The staff member that you would like to assign the badge to.';
const BADGE_TYPE_HELP = 'Administrator badges receive alerts for all school alerts. Nurse badges receive alerts for all school medical alerts. Normal badges do not receive school-wide alerts, except for red alerts.';

class BadgeForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      unassignedBadges: [],
      serialNumber: '',
      assignedStaffMemberID: '',
      badgeTypeID: '',
      staffMembers: [],
      badgeTypes: [],
      formError: false,
      redirectToBadges: false,
      isLoading: false,
    };
  }

  componentDidMount() {
    listUnassignedBadges().then((unassignedBadges) => {
      this.setState({ unassignedBadges: unassignedBadges });
    }).catch(error => {
      this.props.setError(error ? error : 'Error getting unassigned badges.');
    });

    listUnassignedStaff().then((staffMembers) => {
      this.setState({ staffMembers: staffMembers });
    }).catch(error => {
      this.props.setError(error ? error : 'Error getting staff members.');
    });

    listBadgeTypes().then(badgeTypes => {
      this.setState({ badgeTypes: badgeTypes }, () => {
        this.setState({ badgeType: badgeTypes?.length > 0 ? badgeTypes[0].getID() : '' });
      });
    });
  }

  componentWillUnmount() {
    this.props.clearErrors();
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  hasFormError() {
    return this.state.serialNumber === '' || this.state.assignedStaffMemberID === '' || this.state.badgeTypeID === '';
  }

  submitForm = () => {
    // Ensure there is no form error
    const formError = this.hasFormError();
    this.setState({ formError });
    if (formError) return;

    this.setState({ isLoading: true });
    const { serialNumber, assignedStaffMemberID, badgeTypeID } = this.state;
    createBadge({ serialNumber, staffMemberID: assignedStaffMemberID, badgeTypeID }).then(() => {
      this.props.setSuccess('Successfully created new badge.');
      this.setState({ isLoading: false, serialNumber: '', assignedStaffMemberID: '', badgeTypeID: '' });
    }).catch(error => {
      this.setState({ isLoading: false });
      this.props.setError(error ? error : 'Error creating new badge.');
    });
  }

  render() {
    return this.state.redirectToBadges ? <Redirect to='/admin/badges' /> : (
      <form noValidate autoComplete="off" style={{ width: (this.props.isFullWidth ? '50%' : '90%') }}>

        {/* Serial Number */}
        <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between', marginBottom: '10px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: (this.props.isMobile ? '100%' : '40%') }}>
            <Typography variant='h6'>Serial Number</Typography>
            <InfoButton name={'Serial Number'} body={SERIAL_NUMBER_HELP} color={this.props.statusColor} />
          </div>
          <TextField
            required
            select
            error={this.state.formError && this.state.serialNumber === ''}
            name="serialNumber"
            label="Serial Number"
            value={this.state.serialNumber}
            style={{ width: (this.props.isMobile ? '100%' : '60%') }}
            onChange={this.onChange}
            variant='filled'
            disabled={this.state.isLoading}>
            {this.state.unassignedBadges.map((badge) => (
              <MenuItem key={badge.getSerialNumber()} value={badge.getSerialNumber()}>
                {badge.getSerialNumber()}
              </MenuItem>
            ))}
          </TextField>
        </div>

        {/* Assigned Staff Member */}
        <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between', marginBottom: '10px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: (this.props.isMobile ? '100%' : '40%') }}>
            <Typography variant='h6'>Assigned Staff Member</Typography>
            <InfoButton name={'Staff Member'} body={STAFF_MEMBER_HELP} color={this.props.statusColor} />
          </div>
          <TextField
            required
            select
            error={this.state.formError && this.state.assignedStaffMemberID === ''}
            name="assignedStaffMemberID"
            label="Assigned Staff Member"
            value={this.state.assignedStaffMemberID}
            style={{ width: (this.props.isMobile ? '100%' : '60%') }}
            onChange={this.onChange}
            variant='filled'
            disabled={this.state.isLoading}>
            {this.state.staffMembers.map((member) => (
              <MenuItem key={member.id} value={member.id}>
                {member.firstName} {member.lastName}
              </MenuItem>
            ))}
          </TextField>
        </div>

        {/* Badge Type */}
        <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between', marginBottom: '10px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: (this.props.isMobile ? '100%' : '40%') }}>
            <Typography variant='h6'>Badge Type</Typography>
            <InfoButton name={'Badge Type'} body={BADGE_TYPE_HELP} color={this.props.statusColor} />
          </div>
          <TextField
            required
            select
            error={this.state.formError && this.state.badgeTypeID === ''}
            name="badgeTypeID"
            label="Badge Type"
            value={this.state.badgeTypeID}
            style={{ width: (this.props.isMobile ? '100%' : '60%') }}
            onChange={this.onChange}
            variant='filled'
            disabled={this.state.isLoading}>
            {this.state.badgeTypes.map((type) => (
              <MenuItem key={type.id} value={type.id}>
                {type.name}
              </MenuItem>
            ))}
          </TextField>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <Button onClick={this.submitForm} variant="contained" style={{ width: '200px', height: '50px', margin: '30px', backgroundColor: this.props.statusColor.main, color: this.props.statusColor.text }} disabled={this.state.isLoading}>Save</Button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  statusColor: state.status.schoolStatus ? state.status.schoolStatus.getColor() : DEFAULT,
  isFullWidth: state.ui.isFullWidth,
  isMobile: state.ui.isMobile
});

const mapDispatchToProps = dispatch => ({
  setError: (msg) => setAlert(dispatch, msg),
  setSuccess: (msg) => setSuccess(dispatch, msg),
  clearErrors: () => clearErrors()
});

export default connect(mapStateToProps, mapDispatchToProps)(BadgeForm);

BadgeForm.propTypes = {
  setSuccess: PropTypes.func,
  setError: PropTypes.func,
  clearErrors: PropTypes.func,
  isFullWidth: PropTypes.bool,
  statusColor: PropTypes.object,
  isMobile: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func
};

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Fab, Grid } from '@material-ui/core';

// Icons
import AddIcon from '@material-ui/icons/Add';

import Menu from '@material-ui/core/Menu';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { isNull } from '../constants';

/**
 * @param {object} props
 * @param {any} props.statusColor
 * @param {unknown[]} props.data
 * @param {{name: string, value: function, values?: {name: string, value: any, active: boolean, nullMatch?: boolean}[], autoPopulate?: { name: string, value: any }}[]}  props.filterOptions
 * @param {function} props.updateFilteredData
 */
export default function FilterWidget({ statusColor, filterOptions, data, updateFilteredData }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    filterData();
    updateAutoFilters();
  }, [data]);

  const updateAutoFilters = () => {
    if (isNull(filterOptions) || isNull(data)) {
      return;
    }

    filterOptions.forEach(filterCategory => {
      if (isNull(filterCategory.autoPopulate) || isNull(filterCategory.autoPopulate.name) || isNull(filterCategory.autoPopulate.value)) {
        return;
      }

      var namePattern = filterCategory.autoPopulate.name;
      var valuePattern = filterCategory.autoPopulate.value;

      filterCategory.values = [];

      // Get array of unique values for the given field.
      var fieldValues = [...new Set(data.map(elem => filterCategory.value(elem)))];

      fieldValues.forEach(elem => {
        var name = namePattern.replace('#{value}', elem);
        var value = valuePattern.replace('#{value}', elem);

        if (isNull(filterCategory.values)) {
          filterCategory.values = [];
        }

        filterCategory.values.push({
          'name': name,
          'value': value,
          'active': false
        });
      });
    });
  };

  const updateFilters = (name, active) => {
    var [category, value] = name.split('-');

    filterOptions.forEach(filterCategory => {
      // If not the right category, return.
      if (filterCategory.name !== category) {
        return;
      }

      // Make sure this category has values.
      if (isNull(filterCategory.values) || !Array.isArray(filterCategory.values) || filterCategory.values.length === 0) {
        return;
      }
      
      if (isNull(filterCategory.values)) {
        filterCategory.values = [];
      }

      filterCategory.values.forEach(filterValue => {
        if (filterValue.name !== value) {
          return;
        }

        filterValue.active = active;
      });
    });

    filterData();
  };

  const filterData = () => {
    var filteredData = data;
    if (isNull(filteredData)) {
      return;
    }

    filterOptions.forEach(category => {
      // Make sure this category has values.
      if (isNull(category.values)) {
        return;
      }

      var activeFilters = category.values.filter(elem => elem.active === true);

      // If no active filters, return so we don't have an empty list.
      if (activeFilters.length === 0) {
        return;
      }

      // Get an array of the active filter values.
      var activeFilterValues = activeFilters.map(elem => elem.value);

      // If one of the filters has a nullMatch, add null to our values to check.
      if (activeFilters.some(elem => elem.nullMatch)) {
        activeFilterValues.push(null);
      }

      // Filter all our data with the activeFilterValues.
      filteredData = filteredData.filter(elem => {
        var targetField = category.value(elem);

        return activeFilterValues.some(elem => elem === targetField);
      });
    });


    updateFilteredData(filteredData);
  };

  return (
    <div>
      <Fab
        variant="extended"
        style={{ backgroundColor: statusColor.main }}
        onClick={handleClick}
      >
            Filter
        <AddIcon style={{ paddingLeft: '10px' }} />
      </Fab>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Grid container direction='column' style={{ width: '250px' }}>
          {filterOptions && filterOptions.map((category, index) =>
            <FormControl key={index} component="fieldset" style={{ margin: '10px' }}>
              <FormLabel component="legend">{category.name}</FormLabel>
              <FormGroup>
                <Grid container direction='row'>
                  {category.values && category.values.map((value, index) =>
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          name={category.name + '-' + value.name}
                          onChange={(event) => updateFilters(event.target.name, event.target.checked)}
                        />
                      }
                      label={value.name}
                    />
                  )}
                </Grid>
              </FormGroup>
            </FormControl>
          )}
        </Grid>
      </Menu>
    </div>
  );
}

FilterWidget.propTypes = {
  statusColor: PropTypes.object,
  filterOptions: PropTypes.array,
  data: PropTypes.array,
  updateFilteredData: PropTypes.func
};

import React from 'react';
import { Card, CircularProgress, Fab } from '@material-ui/core';
import { Stage, Layer } from 'react-konva';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Icons
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import PanToolIcon from '@material-ui/icons/PanTool';
import DoneIcon from '@material-ui/icons/Done';

// Components
import KonvaImage from './KonvaImage';

// Constants
import { GREY, DEFAULT } from '../constants';

// Props: panelHeight, panelWidth, iconsMarginRight, statusColor, onRoomSelection, onClick
class MapPanel extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      inPanMode: false,
      stageScale: 1,
      layerScale: 1,
      layerOffsetX: -20,
      layerOffsetY: 0,
      imageRef: null,
      dragStartX: null,
      dragStartY: null,
      dragX: 0,
      dragY: 0
    };

    this.stage = null;
  }

  componentDidMount() {
    this.setOnClick();
  }

  togglePanMode = () => {
    this.setState({ inPanMode: !this.state.inPanMode }, () => {
      if (this.state.inPanMode) this.disableOnClick();
      else this.setOnClick();
    });
  }

  setOnClick = () => {
    if (this.props.onClick) {
      this.stageRef.on('click', () => {
        const x = Math.floor((this.stageRef.getPointerPosition().x - this.state.dragX) / (this.state.layerScale * this.state.stageScale) + this.state.layerOffsetX);
        const y = Math.floor((this.stageRef.getPointerPosition().y - this.state.dragY) / (this.state.layerScale * this.state.stageScale) + this.state.layerOffsetY);
        this.props.onClick(x,y);
      });
    }
  }

  disableOnClick = () => {
    this.stageRef.off('click');
  }

  zoomIn = () => {
    this.setState({ stageScale: this.state.stageScale + 0.1 });
  }

  zoomOut = () => {
    this.setState({ stageScale: this.state.stageScale > 0.3 ? this.state.stageScale - 0.1 : 0.3 });
  }

  onImageLoad = (imageRef) => {
    const { width, height } = imageRef.attrs.image;
    const newMaxWidth = this.props.panelWidth - 40;
    const newMaxHeight = this.props.panelHeight - 40;

    // Make the Image the Size of the Panel
    var widthScale = width && newMaxWidth && width !== 0 ? newMaxWidth / width : 0;
    var heightScale = height && newMaxHeight && height !== 0 ? newMaxHeight / height : 0;

    var scale = Math.min(widthScale, heightScale);
    scale = scale === 0 ? 1 : scale;
    this.setState({ layerScale: scale });

    // Center the Layers
    this.setState({
      layerOffsetX: -1 * (this.props.panelWidth - (scale * width)) / 2 + 20,
      layerOffsetY: -1 * (this.props.panelHeight - (scale * height)) / 2 + 5
    });
  }

  onDragStart = () => {
    const stage = this.stageRef;
    this.setState({
      dragStartX: stage.x(),
      dragStartY: stage.y()
    });
  }

  onDragEnd = () => {
    const stage = this.stageRef;
    const dragEndX = stage.x();
    const dragEndY = stage.y();
    const distanceX = dragEndX - this.state.dragStartX;
    const distanceY = dragEndY - this.state.dragStartY;
    this.setState({ dragX: this.state.dragX + distanceX, dragY: this.state.dragY + distanceY });
  }

  render() {
    return (
      <Card style={{ position: 'relative', backgroundColor: GREY.dark, height: this.props.panelHeight, marginRight: 10, ...this.props.style }}>
        {this.props.isLoading && <CircularProgress />}
        <Stage
          width={this.props.panelWidth}
          height={this.props.panelHeight}
          scaleX={this.state.stageScale}
          scaleY={this.state.stageScale}
          draggable={this.state.inPanMode}
          onDragStart={this.onDragStart}
          onDragEnd={this.onDragEnd}
          ref={ref => {
            this.stageRef = ref; 
          }}>

          <Layer scaleX={this.state.layerScale} scaleY={this.state.layerScale} offsetX={this.state.layerOffsetX} offsetY={this.state.layerOffsetY}>
            {this.props.floorPlanImagePath != null &&
            <KonvaImage src={this.props.floorPlanImagePath} scale={this.state.layerScale} onLoad={this.onImageLoad} />}
          </Layer>

          <Layer scaleX={this.state.layerScale} scaleY={this.state.layerScale} offsetX={this.state.layerOffsetX} offsetY={this.state.layerOffsetY}>
            {this.props.children}
          </Layer>
        </Stage>

        <div style={{ position: 'absolute', right: 8, bottom: 8 }}>
          <Fab style={{ marginRight: '8px', backgroundColor: this.props.statusColor.main, color: this.props.statusColor.text, width: 40, height: 40 }} onClick={this.togglePanMode}>
            {!this.state.inPanMode && <PanToolIcon style={{ width: 18, height: 18 }} />}
            {this.state.inPanMode && <DoneIcon style={{ width: 18, height: 18 }} />}
          </Fab>
          <Fab style={{ marginRight: '8px', backgroundColor: this.props.statusColor.main, color: this.props.statusColor.text, width: 40, height: 40 }} onClick={this.zoomIn} disabled={this.state.stageScale >= 2}>
            <ZoomInIcon style={{ width: 18, height: 18 }} />
          </Fab>
          <Fab style={{ backgroundColor: this.props.statusColor.main, color: this.props.statusColor.text, width: 40, height: 40 }} onClick={this.zoomOut} disabled={this.state.stageScale <= 0.3 }>
            <ZoomOutIcon style={{ width: 18, height: 18 }} />
          </Fab>
        </div>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  statusColor: state.status.schoolStatus ? state.status.schoolStatus.getColor() : DEFAULT,
  panelHeight: state.ui.windowSize.height - 159 - 41,
  panelWidth: state.ui.windowSize.width * 8 / 12
});

export default connect(mapStateToProps, null)(MapPanel);

MapPanel.propTypes = {
  onClick: PropTypes.func,
  panelWidth: PropTypes.number,
  panelHeight: PropTypes.number,
  style: PropTypes.object,
  isLoading: PropTypes.bool,
  floorPlanImagePath: PropTypes.string,
  children: PropTypes.array,
  statusColor: PropTypes.object
};

import React, { Component } from 'react';
import {
  CircularProgress, Typography, TextField, Button, Container, Grid
} from '@material-ui/core';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { submitSetupTwoFactorAuthentication } from '../../../../redux/actions/authActions';

// SimulAlert Server
import { setupMultiFactor as setupMultiFactorFromServer } from '../../../../api';

// Constants
import { DEFAULT, GREY } from '../../../../constants';

class TwoFactorSetup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      imageUrl: null,
      isImageLoading: true,
      duoCode: ''
    };
  }

  componentDidMount() {
    setupMultiFactorFromServer().then(res => {
      this.setState({ imageUrl: res, isImageLoading: false });
    }).catch(error => {
      console.log(error);
    });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.submitSetupTwoFactorAuthentication(this.state.duoCode);
  }

  render() {
    return (
      <Container style={{ marginTop: '10%' }}>
        <h1
          style={{ color: GREY.dark, textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}
        >
          Set Up Two Factor Authentication
        </h1>

        <form autoComplete='off' onSubmit={this.onSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant='h5' style={{ width: '100%', marginBottom: '5px', marginTop: '5px' }}>1. Download the Duo App from the iOS App Store or Google Play Store.</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h5' style={{ width: '100%', marginBottom: '5px', marginTop: '5px' }}>2. Scan the QR code below using the Duo App.</Typography>
            </Grid>
            <Grid item xs={12}>
              {!this.state.isImageLoading && this.state.imageUrl &&
              <img src={this.state.imageUrl} alt='Duo QR Code' style={{ width: 200, height: 200 }} />}
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h5' style={{ width: '100%', marginBottom: '5px', marginTop: '5px' }}>3. Enter in the code it gives you below.</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField required error={this.state.formError && this.state.duoCode === ''} name="duoCode" label="Duo Code" style={{ width: '100%', marginBottom: '15px' }} onChange={this.onChange} variant='filled' />
            </Grid>
            {/* Submit Button */}
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <Button type='submit' variant="contained" style={{ width: '200px', backgroundColor: this.props.statusColor.main, color: this.props.statusColor.text, marginBottom: '2em' }} disabled={this.props.isLoading}>
                Submit
                {this.props.isLoading && <CircularProgress size={25} style={{ color: this.props.statusColor.text, marginLeft: '1em' }} />}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  statusColor: state.status.schoolStatus ? state.status.schoolStatus.getColor() : DEFAULT,
  isLoading: state.auth.isLoading
});

const mapDispatchToProps = (dispatch) => ({
  submitSetupTwoFactorAuthentication: (token) => submitSetupTwoFactorAuthentication(dispatch, token)
});

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorSetup);

TwoFactorSetup.propTypes = {
  submitSetupTwoFactorAuthentication: PropTypes.func,
  statusColor: PropTypes.object,
  isLoading: PropTypes.bool
};

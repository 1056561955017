import React from 'react';
import { Line } from 'react-konva';
import PropTypes from 'prop-types';

// Constants
import { DEFAULT } from '../constants';

// Props: x, y, statusColor, isSelected, onClick, blinking
export default class RoomStatusZone extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      hover: false,
      timerCount: 0
    };
  }

  componentDidMount() {
    // Start the blinking timer
    this.timer = setInterval(
      () => {
        this.setState({ timerCount: this.state.timerCount + 1 });
      },
      500
    );
  }

  componentWillUnmount() {
    // Stop the blinking timer
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  render() {
    const points = this.props.vertices.reduce((acc, vertex) => acc.concat([vertex.x, vertex.y]), []);
    return (
      <Line
        points={points}
        fill={this.props.statusColor ? this.props.statusColor.main : DEFAULT.main}
        opacity={
          this.state.hover ? (
            this.props.isSelected ? 0.8 : 0.6
          ) : (
            this.props.blinking && this.state.timerCount % 2 === 0 ? 0.7 : (
              this.props.isSelected ? 0.9 : 0.5
            )
          )
        }
        closed
        stroke={(this.state.hover || this.props.isSelected) && this.props.onClick ? this.props.statusColor.dark : null}
        strokeWidth={this.state.hover || this.props.isSelected ? 10 : 5}
        onClick={this.props.onClick ? this.props.onClick : null}
        onMouseenter={() => this.setState({ hover: true })}
        onMouseleave={() => this.setState({ hover: false })}
      />
    );
  }
}

RoomStatusZone.propTypes = {
  vertices: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number
  })),
  statusColor: PropTypes.object,
  blinking: PropTypes.bool,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func
};
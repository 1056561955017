import { ADD_ANNOUNCEMENT, REMOVE_ANNOUNCEMENT, CLEAR_ERRORS, CLEAR_ANNOUNCEMENTS, REMOVE_ANNOUNCEMENT_BY_MESSAGE } from '../actions/announcementActions';

const initialState = [];

/**
 * @param {import('../actions/announcementActions').Announcement[]} state 
 */
const announcements = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ANNOUNCEMENT: {
      // Prevent more than 3 announcements to appear simultaneously or have duplicates
      return state.filter(announcement => (announcement.message === action.announcement.message && announcement.title === action.announcement.title)).length === 0 ? [...state.slice(state.length >= 3 ? 1 : 0), action.announcement] : state;
    }
    case REMOVE_ANNOUNCEMENT:
      return state.filter(announcement => announcement.id !== action.id);
    case REMOVE_ANNOUNCEMENT_BY_MESSAGE:
      return state.filter(announcement => announcement.message !== action.message);
    case CLEAR_ERRORS:
      return state.filter(announcement => announcement.severity !== 'ERROR');
    case CLEAR_ANNOUNCEMENTS:
      return initialState;
    default:
      return state;
  }
};

export default announcements;

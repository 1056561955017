import React from 'react';
import { Dialog, Grid, Typography, Button } from '@material-ui/core';
import PropTypes from 'prop-types';

// Icons
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

// Redux
import { connect } from 'react-redux';

// Components
import AlertUpdateWidget from './AlertUpdateWidget';

// Constants
import { GREY, GREEN, isNull, RED } from '../constants';

function UserInfoDialog({ user, onClose, toggleActivityModal, toggleResetPasswordModal, toggleChangePasswordModal, toggleReset2faModal, toggleMobileAccessModal, activeUserType, setupMode }) {
  return (
    <Dialog open={!isNull(user)} onClose={onClose} scroll='paper'>
      {user &&
      <div style={{ padding: 20 }}>
        {/* Header */}
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant='h4'>{user.getName()}</Typography>
          {!user.getIsActive() && <Typography variant='body1' style={{ color: RED.main }}>Inactive</Typography>}
          <Typography variant='overline' style={{ color: GREY.dark }}>{user.getUserType().name}</Typography>
        </div>

        {/* --- Contact Info --- */}
        <div>
          <Grid container direction='column' style={{ marginBottom: '1em' }}>

            {/* Email */}
            <Grid item container direction='row' justify='center' align='center'>
              <Grid item style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <MailIcon style={{ fontSize: 20, color: GREY.main, marginRight: '10px' }} />
              </Grid>
              <Grid item>
                <Typography variant='h6' style={{ color: GREY.main }}>{user.getEmail()}</Typography>
              </Grid>
            </Grid>

            {/* Phone Number */}
            <Grid item container direction='row' justify='center' align='center'>
              <Grid item style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <PhoneIcon style={{ fontSize: 20, color: GREY.main, marginRight: '10px' }} />
              </Grid>
              <Grid item>
                <Typography variant='h6' style={{ color: GREY.main }}>{user.getPhoneNumber() || 'Unknown'}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </div>

        {/* --- Last Seen --- */}
        {user.getRegisterTimestamp() ?
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1em' }}>
            <Typography variant='overline' style={{ color: GREY.dark }}>Registered</Typography>
            <Typography variant='h6' style={{ color: GREY.dark }}>{user.getRegisterTimestamp().toLocaleString()}</Typography>
          </div> :
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1em' }}>
            <Typography variant='overline' style={{ color: GREY.dark }}>Registered</Typography>
            <Typography variant='h6' style={{ color: GREY.dark }}>Unkown</Typography>
          </div>
        }

        {/* --- 2FA Status --- */}
        <Grid container direction='column' justify='center'>

          <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant='overline' style={{ color: GREY.dark }}>2-Factor Authentication</Typography>
          </Grid>

          <Grid item container direction='row' justify='center'>
            {/* --- ICON --- */}
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              {user.getMultiFactorSetupRequired() ? <CancelIcon style={{ color: GREY.main, fontSize: 40 }} /> : <CheckCircleIcon style={{ color: GREEN.main, fontSize: 40 }} />}
            </Grid>

            {/* --- TEXT --- */}
            <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Grid container direction='column' justify='center'>
                {/* Connection Status */}
                <Grid item style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Typography variant='h6' style={{ marginLeft: '5px', color: GREY.dark }}>{user.getMultiFactorSetupRequired() ? 'Not Set Up' : 'Set Up'}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* --- Password Status --- */}
        <Grid container direction='column' justify='center' style={{ marginBottom:'1em' }}>

          <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant='overline' style={{ color: GREY.dark }}>Password</Typography>
          </Grid>

          <Grid item container direction='row' justify='center'>
            {/* --- ICON --- */}
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              {!user.getPasswordResetRequired() ? <CheckCircleIcon style={{ color: GREEN.main, fontSize: 40 }} /> : <CancelIcon style={{ color: GREY.main, fontSize: 40 }} />}
            </Grid>

            {/* --- TEXT --- */}
            <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Grid container direction='column' justify='center' style={{ margin: '5px' }}>
                {/* Connection Status */}
                <Grid item style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Typography variant='h6' style={{ marginLeft: '5px', color: GREY.dark }}>{!user.getPasswordResetRequired() ? 'Changed' : 'Not Changed'}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* User update buttons */}
        {user.id <= activeUserType.index &&
          <>
            <Grid container direction='row' justify='center' style={{ margin: '5px' }}>
              <Button
                onClick={toggleResetPasswordModal}
                variant='outlined'
                disabled={!setupMode}
              >
              Reset User Password
              </Button>
            </Grid>

            <Grid container direction='row' justify='center' style={{ margin: '5px' }}>
              <Button
                onClick={toggleChangePasswordModal}
                variant='outlined'
                disabled={!setupMode}
              >
              Change User Password
              </Button>
            </Grid>
            <Grid container direction='row' justify='center' style={{ margin: '5px' }}>
              <Button
                onClick={toggleReset2faModal}
                variant='outlined'
                disabled={!setupMode}
              >
              Reset 2FA
              </Button>
            </Grid>

            <Grid container direction='row' justify='center' style={{ margin: '5px' }}>
              <Button
                onClick={toggleMobileAccessModal}
                variant='outlined'
                disabled={!setupMode}
              >
                {user.getMobileAccess() ? 'Disable' : 'Enable'} Mobile Access
              </Button>
            </Grid>

            <Grid container direction='row' justify='center' style={{ margin: '5px' }}>
              <Button
                onClick={toggleActivityModal}
                variant='outlined'
                disabled={!setupMode}
              >
                {user.isActive ? 'Deactivate' : 'Reactivate'}
              </Button>
            </Grid>

            <Grid container direction='row' justify='center' style={{ margin: '5px' }}>
              <Button
                href={'/admin/users/create/' + user.getID()}
                variant='outlined'
                disabled={!setupMode}
              >
                {'Edit User'}
              </Button>
            </Grid>
          </>
        }

        {/* Logs */}
        <AlertUpdateWidget updates={user.getAlertUpdates()} style={{ marginTop: '35px' }} />
      </div>}
    </Dialog>
  );
}

class UserInfoDialogWrapper extends React.Component {
  render() {
    return (
      <UserInfoDialog {...this.props} />
    );
  }
}

const mapStateToProps = (state) => ({
  activeUserType: state.auth.userType,
  setupMode: state.setupMode.mode || state.setupMode.isLoading
});

export default connect(mapStateToProps, null)(UserInfoDialogWrapper);

UserInfoDialog.propTypes = {
  user: PropTypes.object,
  onClose: PropTypes.func,
  toggleActivityModal: PropTypes.func,
  toggleResetPasswordModal: PropTypes.func,
  toggleChangePasswordModal: PropTypes.func,
  toggleReset2faModal: PropTypes.func,
  toggleMobileAccessModal: PropTypes.func,
  activeUserType: PropTypes.any,
  setupMode: PropTypes.bool.isRequired
};

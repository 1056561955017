import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { CircularProgress, Container, Typography } from '@material-ui/core';

// Redux
import { connect } from 'react-redux';
import { setSuccess, clearErrors, setAlert } from '../../../../redux/actions/alertActions';

// API
import { getBadge } from '../../../../api';

// Components
import LineItem from '../../../../components/LineItem';

class Badge extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectToBadges: false,
      badge: null,
      isLoading: false
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    const badgeID = this.props.match?.params?.id;
    getBadge(badgeID).then(badge => {
      this.setState({ badge: badge, isLoading: false });
    }).catch(error => {
      this.setState({ isLoading: false });
      this.props.setError(error ? error : 'Error: Unable to retrieve badge.');
    });
  }

  componentWillUnmount() {
    this.props.clearErrors();
  }

  render() {
    return this.state.redirectToBadges || !this.props.setupMode ? <Redirect to='/admin/badges' /> : (
      <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
          <Typography variant='h1'>Badge</Typography>
          {this.state.isLoading && <CircularProgress style={{ color: this.props.statusColor.main }} />}
        </div>

        {this.state.badge != null &&
        <div style={{ width: '100%' }}>
          <LineItem description='Badge Serial Number' value={this.state.badge.getSerialNumber()} />
          <LineItem description='Assigned Staff Member' value={this.state.badge.getBelongsTo()?.getName() ?? 'Unknown'} />
          <LineItem description='Badge Type' value={this.state.badge.getBadgeType()?.getName() ?? 'Unknown'} />
        </div>}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  statusColor: state.status.schoolStatus.getColor(),
  setupMode: state.setupMode.mode || state.setupMode.isLoading
});

const mapDispatchToProps = dispatch => ({
  setError: (msg) => setAlert(dispatch, msg),
  setSuccess: (msg) => setSuccess(dispatch, msg),
  clearErrors: () => dispatch(clearErrors())
});

Badge.propTypes = {
  setError: PropTypes.func,
  setSuccess: PropTypes.func,
  clearErrors: PropTypes.func,
  match: PropTypes.object.isRequired,
  statusColor: PropTypes.object.isRequired,
  setupMode: PropTypes.bool.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Badge));

import React from 'react';
import { Image } from 'react-konva';
import PropTypes from 'prop-types';

// Props: src, x, y, width (optional), height (optional), imageRef
export default class KonvaImage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      image: null
    };
  }

  componentDidMount() {
    this.loadImage();
  }

  componentDidUpdate(oldProps) {
    if (oldProps.src !== this.props.src) {
      this.setState({ image: null });
      this.loadImage();
    }
  }

  componentWillUnmount() {
    this.image.removeEventListener('load', this.handleLoad);
  }

  loadImage() {
    // save to "this" to remove "load" handler on unmount
    this.image = new window.Image();
    this.image.src = this.props.src;

    // Fit the image in the width and height passed as props
    if (this.props.width || this.props.height) {
      var widthScale = this.props.width && this.props.width !== 0 ? this.image.width / this.props.width : 0;
      var heightScale = this.props.height && this.props.height !== 0 ? this.image.height / this.props.height : 0;

      var scale = Math.max(widthScale, heightScale);
      scale = scale === 0 ? 1 : scale;
      this.image.width = Math.round(this.image.width / scale);
      this.image.height = Math.round(this.image.height / scale);
    }

    this.image.addEventListener('load', this.handleLoad);
  }

  handleLoad = () => {
    // after setState react-konva will update canvas and redraw the layer
    // because "image" property is changed
    this.setState({
      image: this.image
    });

    this.props.onLoad && this.props.onLoad(this.ref);
    // if you keep same image object during source updates
    // you will have to update layer manually:
    // this.imageNode.getLayer().batchDraw();
  };

  render() {
    return (
      <Image
        x={this.props.x ? this.props.x : 0}
        y={this.props.y ? this.props.y : 0}
        image={this.state.image}
        ref={ref => (this.ref = ref)}
      />
    );
  }
}

KonvaImage.propTypes = {
  src: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  onLoad: PropTypes.func,
  x: PropTypes.number,
  y: PropTypes.number
};
import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from '../reducers';
import statusTransform from '../transforms/statusTransform';

const rootPersistConfig = {
  key: 'root',
  storage,
  blacklist: ['alerts'],
  transforms: [statusTransform]
};

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);
export const store = createStore(
  persistedReducer
);
export const persistor = persistStore(store);

import { Floor } from './';

export default class Building {
  static thaw(buildingObj) {
    if (buildingObj == null) return null;
    const building = new Building();
    if (buildingObj.id != null) building.setID(buildingObj.id);
    if (buildingObj.name != null) building.setName(buildingObj.name);
    if (buildingObj.code != null) building.setCode(buildingObj.code);
    if (buildingObj.isActive != null) building.setIsActive(buildingObj.isActive == null ? null : (buildingObj.isActive ? true : false));
    if (buildingObj.floors != null) building.setFloors(Floor.thawList(buildingObj.floors));
    return building;
  }

  static thawList(buildingObjList) {
    if (buildingObjList == null) return [];
    var buildings = [];
    for (var i = 0; i < buildingObjList.length; i++) {
      buildings.push(Building.thaw(buildingObjList[i]));
    }
    return buildings;
  }

  static freeze(building) {
    if (building == null) return null;
    var buildingObj = {};
    if (building.getID() != null) buildingObj.id = building.getID();
    if (building.getName() != null) buildingObj.name = building.getName();
    if (building.getCode() != null) buildingObj.code = building.getCode();
    if (building.getIsActive() != null) buildingObj.isActive = building.getIsActive() == null ? null : (building.getIsActive() ? 1 : 0);
    if (building.getFloors() != null) buildingObj.floors = Floor.freezeList(building.getFloors());
    return buildingObj;
  }

  static freezeList(buildings) {
    if (buildings == null) return [];
    var buildingObjList = [];
    for (var i = 0; i < buildings.length; i++) {
      buildingObjList.push(Building.freeze(buildings[i]));
    }
    return buildingObjList;
  }

  constructor() {
    this.setID(null);
    this.setName(null);
    this.setCode(null);
    this.setIsActive(null);
    this.setFloors(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setName(name) {
    this.name = name;
  }

  setCode(code) {
    this.code = code;
  }

  setIsActive(isActive) {
    this.isActive = isActive;
  }

  setFloors(floors) {
    this.floors = floors;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getName() {
    return this.name;
  }

  getCode() {
    return this.code;
  }

  getIsActive() {
    return this.isActive;
  }

  getFloors() {
    return this.floors;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

}

import React from 'react';
import { Link } from 'react-router-dom';
import {
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import DotIcon from '@material-ui/icons/FiberManualRecord';
import BlockIcon from '@material-ui/icons/Block';
import ListIcon from '@material-ui/icons/List';
import EditIcon from '@material-ui/icons/Edit';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PropTypes from 'prop-types';

// Constants
import { AlertTypes, UserTypes } from '../constants';

function SideMenu({ toggle, openConfirmationModal, logout, userType = null, standalone = false, height = null, isMobile }) {
  return (
    <div
      role='presentation'
      onClick={toggle}
      onKeyDown={toggle}
      style={{ width: (isMobile ? '85vw' : 400), borderRight: standalone ? '1px solid lightgrey' : 'none', overflow: 'auto', minHeight: height - 70 }}
    >
      <List>
        <ListItem button component={Link} to='/dashboard/map'>
          <ListItemIcon><DashboardIcon /></ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
      </List>
      <Divider />
      {openConfirmationModal != null &&
      <>
        <List>
          <ListItem button onClick={() => openConfirmationModal(AlertTypes.EMERGENCY)}>
            <ListItemIcon><DotIcon style={{ color: AlertTypes.EMERGENCY.getColor().main }} /></ListItemIcon>
            <ListItemText primary="Set Emergency Status for All Rooms" />
          </ListItem>
          <ListItem button onClick={() => openConfirmationModal(AlertTypes.ALL_CLEAR)}>
            <ListItemIcon><DotIcon style={{ color: AlertTypes.ALL_CLEAR.getColor().main }} /></ListItemIcon>
            <ListItemText primary="Set All Clear Status for All Rooms" />
          </ListItem>
          <ListItem button onClick={() => openConfirmationModal(null)}>
            <ListItemIcon><BlockIcon /></ListItemIcon>
            <ListItemText primary="Clear All Statuses" />
          </ListItem>
        </List>
        <Divider />
      </>}

      {(userType === UserTypes.SUPER || userType === UserTypes.IT) &&
      <>
        {/* Edit Config */}
        <List>
          <ListItem button component={Link} to='/admin'>
            <ListItemIcon><EditIcon /></ListItemIcon>
            <ListItemText primary="Edit Configuration" />
          </ListItem>
        </List>
        <Divider />

        {/* Users */}
        <List>
          <ListItem button component={Link} to='/admin/users'>
            <ListItemIcon><ListIcon /></ListItemIcon>
            <ListItemText primary="SimulAlert Users" />
          </ListItem>
        </List>
        <Divider />

        {/* Entities */}
        <List>
          <ListItem button component={Link} to='/admin/staff'>
            <ListItemIcon><ListIcon /></ListItemIcon>
            <ListItemText primary="Staff" />
          </ListItem>
          <ListItem button component={Link} to='/admin/rooms'>
            <ListItemIcon><ListIcon /></ListItemIcon>
            <ListItemText primary="Rooms" />
          </ListItem>
          <ListItem button component={Link} to='/admin/floors'>
            <ListItemIcon><ListIcon /></ListItemIcon>
            <ListItemText primary="Building Floors" />
          </ListItem>
          <ListItem button component={Link} to='/admin/buildings'>
            <ListItemIcon><ListIcon /></ListItemIcon>
            <ListItemText primary="Buildings" />
          </ListItem>
          <ListItem button component={Link} to='/admin/hubs'>
            <ListItemIcon><ListIcon /></ListItemIcon>
            <ListItemText primary="Hubs" />
          </ListItem>
          <ListItem button component={Link} to='/admin/badges'>
            <ListItemIcon><ListIcon /></ListItemIcon>
            <ListItemText primary="Badges" />
          </ListItem>
          <ListItem button component={Link} to='/admin/red-alert-text-groups'>
            <ListItemIcon><ListIcon /></ListItemIcon>
            <ListItemText primary="Red Alert Texts" />
          </ListItem>
        </List>
        <Divider />
      </>}

      {/* My Account */}
      <List>
        <ListItem button component={Link} to='/account/settings'>
          <ListItemIcon><AccountCircleIcon /></ListItemIcon>
          <ListItemText primary="My Account" />
        </ListItem>
      </List>
      <Divider />

      {/* Logout */}
      {logout != null &&
      <List>
        <ListItem button onClick={logout}>
          <ListItemText primary="Logout" style={{ textAlign: 'center' }} />
        </ListItem>
      </List>}
    </div>
  );
}

SideMenu.propTypes = {
  toggle: PropTypes.func,
  openConfirmationModal: PropTypes.func,
  logout: PropTypes.func,
  userType: PropTypes.object,
  standalone: PropTypes.bool,
  height: PropTypes.number,
  isMobile: PropTypes.bool,
  setupMode: PropTypes.bool.isRequired
};

export default SideMenu;

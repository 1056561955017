import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

// Components
import InfoPanel from './InfoPanel';
import HubStatusWidget from './HubStatusWidget';
import AlertUpdateWidget from './AlertUpdateWidget';
import InfoDialog from './InfoDialog';

import { isNull } from '../constants';

// Icons
import Hallway from '../images/customIcons/Hallway.png';
import Room from '../images/customIcons/Room.png';
import Door from '../images/customIcons/Door.png';

function getIcon(hubType) {
  switch (hubType) {
    case 'Hallway':
      return Hallway;
    case 'Room':
      return Room;
    case 'Door':
      return Door;
    default:
      return;
  }
}

const HEADER = (hub) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img src={getIcon(hub.getHubType())} alt={hub.getHubType() + 'Image'} style={{ width: '50px', height: '50px' }} />
      <Typography variant='h3' style={{ textAlign: 'center' }}>{hub.getSerialNumber()}</Typography>
    </div>
  );
};

const CHILDREN = (hub) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <HubStatusWidget hub={hub} />
      {!isNull(hub.getRoom()?.getIdentifier()) &&
        <>
          <Typography variant='overline' style={{ marginTop: '40px' }}>Assigned Room</Typography>
          <Typography variant='h5'>{hub.getRoom()?.getIdentifier()}</Typography>
        </>
      }
      <AlertUpdateWidget updates={hub.getAlertUpdates()} />
    </div>
  );
};

export default function HubInfoPanel({ hub, handleClose, isMobile }) {
  return isMobile ? (
    <InfoDialog
      statusColor={hub?.getRoom()?.getAlertStatus().color.main}
      header={HEADER(hub)}
      handleClose={handleClose}
      open={!isNull(hub)}
    >
      {CHILDREN(hub)}
    </InfoDialog>
  ) : (
    <InfoPanel
      statusColor={hub?.getRoom()?.getAlertStatus().color.main}
      header={HEADER(hub)}
    >
      {CHILDREN(hub)}
    </InfoPanel>
  );
}

HubInfoPanel.propTypes = {
  hub: PropTypes.object,
  handleClose: PropTypes.func,
  isMobile: PropTypes.bool
};

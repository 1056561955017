import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

// Icons
import DotIcon from '@material-ui/icons/FiberManualRecord';
import BlockIcon from '@material-ui/icons/Block';

// Constants
import { GREY, AlertTypes } from '../constants';
const STYLES = {
  button: {
    width: (100 / (Object.keys(AlertTypes).length - 1)) + '%'
  },
  icon: {
    fontSize: 50
  },
  text: {
    fontSize: '10px',
    textAlign: 'center',
    display: 'block',
    lineHeight: '1.25em',
    marginLeft: '2px',
    marginRight: '2px'
  }
};

export default function SetStatusWidget({ style = {}, onSetStatus, disabled = false }) {
  const alertType = AlertTypes.NORMAL;
  return (
    <Grid container direction='row' justify='center' style={style}>

      {/* --- STATUSES --- */}
      <Button style={STYLES.button} onClick={() => onSetStatus(alertType)} disabled={disabled}>
        <Grid item>
          <Grid container direction='column' align='center'>
            {/* Icon */}
            <Grid item>
              {alertType !== AlertTypes.NORMAL ?
                <DotIcon style={{ ...STYLES.icon, color: alertType.getColor().main, opacity: disabled ? 0.5 : 1 }} /> :
                <BlockIcon style={{ ...STYLES.icon, color: GREY.dark, opacity: disabled ? 0.5 : 1 }} />}
            </Grid>

            {/* Text */}
            <Grid item>
              <Typography variant='caption' style={STYLES.text}>{alertType.getDashboardStatus() || 'Remove'}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Button>

    </Grid>
  );
}

SetStatusWidget.propTypes = {
  style: PropTypes.object,
  onSetStatus: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

import { AlertSeverity, setAlert, clearErrors } from './alertActions';

import { AlertTypes } from '../../constants';

import { setSchoolStatus as setServerSchoolStatus, getSchoolStatus, getAlertUpdates, getRoom, getFloor, getBuilding } from '../../api';
import { announceSchoolStatus, clearAnnouncements } from './announcementActions';

export const IS_STATUS_LOADING = 'IS_STATUS_LOADING';
export const IS_STATUS_NOT_LOADING = 'IS_STATUS_NOT_LOADING';
export const UPDATE_SCHOOL_STATUS = 'UPDATE_SCHOOL_STATUS';
export const UPDATE_ALERT_MODAL = 'UPDATE_ALERT_MODAL';
export const STOP_SHOW_ALERT_ROOM = 'STOP_SHOW_ALERT_ROOM';

export const startLoading = () => ({
  type: IS_STATUS_LOADING
});

export const stopLoading = () => ({
  type: IS_STATUS_NOT_LOADING
});

export const updateSchoolStatus = (roomID = null, status = AlertTypes.UNKNOWN) => ({
  type: UPDATE_SCHOOL_STATUS,
  roomID,
  status
});

export const updateAlertModal = (value) => ({
  type: UPDATE_ALERT_MODAL,
  value
});

export const stopShowAlertRoom = () => ({
  type: STOP_SHOW_ALERT_ROOM
});

export const checkSchoolStatus = (dispatch) => {
  dispatch(startLoading());

  getSchoolStatus().then(data => {
    dispatch(stopLoading());
    if (data) dispatch(updateSchoolStatus(data.roomID, data.status));
  }).catch(() => {
    dispatch(stopLoading());
    dispatch(updateSchoolStatus());
  });

  getAlertUpdates(3).then(alertUpdates => {
    let seenAlertUpdateIds;
    try {
      seenAlertUpdateIds = JSON.parse(sessionStorage.getItem('statusActions.seenAlertUpdateIds') ?? '[]');
    }
    catch (e) {
      seenAlertUpdateIds = [];
    }

    if (alertUpdates) {
      for (const alert of alertUpdates) {
        if (alert != null) {
          // Make sure we haven't already seen the alert
          if (seenAlertUpdateIds.includes(alert.id)) continue;

          // Update the list of seen alert update ids
          seenAlertUpdateIds.push(alert.id);
          sessionStorage.setItem('statusActions.seenAlertUpdateIds', JSON.stringify(seenAlertUpdateIds));
          
          const alertType = Object.values(AlertTypes).find(alertType => alertType.getID() === alert.getAlertTypeID());

          // If the alert type is not found, skip it
          if (alertType == null) {
            console.log('Alert type not found for alert type id ' + alert.getAlertTypeID());
            continue;
          }

          // Don't show alert banner for normal or all clear alerts
          if (alertType.getDashboardStatus() == 'Normal' || alertType.getDashboardStatus() == 'All Clear') continue;

          getRoom(alert.getRoomID()).then(room => {
            getFloor(room.getFloorID()).then(floor => {
              getBuilding(floor.getBuildingID()).then(building => {
                announceSchoolStatus(dispatch, {
                  status: alertType,
                  room,
                  floor,
                  building
                });
              });
            });
          });
        }
      }
    }
  });
};

export const setSchoolStatus = (dispatch, alertStatus) => {
  dispatch(startLoading());
  dispatch(clearAnnouncements());
  setServerSchoolStatus(alertStatus).then(() => {
    dispatch(stopLoading());
    dispatch(updateSchoolStatus(null, alertStatus));
    dispatch(clearErrors());
    setAlert(dispatch, 'Successfully set school status to ' + alertStatus.getDashboardStatus(), AlertSeverity.SUCCESS, true);
  }).catch(() => {
    dispatch(stopLoading());
    setAlert(dispatch, 'Unable to set school status.');
  });
};

export const clearSchoolStatus = (dispatch) => {
  setSchoolStatus(dispatch, AlertTypes.NORMAL);
};

export const hideAlertModal = (dispatch) => {
  dispatch(updateAlertModal(false));
};

import { Building, Room } from './';

export default class Floor {
  static thaw(floorObj) {
    if (floorObj == null) return null;
    const floor = new Floor();
    if (floorObj.id != null) floor.setID(floorObj.id);
    if (floorObj.zIndex != null) floor.setZIndex(floorObj.zIndex);
    if (floorObj.floorPlanImage != null) floor.setFloorPlanImage(floorObj.floorPlanImage);
    if (floorObj.identifier != null) floor.setIdentifier(floorObj.identifier);
    if (floorObj.longLeftTop != null) floor.setLongLeftTop(floorObj.longLeftTop);
    if (floorObj.latLeftTop != null) floor.setLatLeftTop(floorObj.latLeftTop);
    if (floorObj.imageWidthM != null) floor.setImageWidthM(floorObj.imageWidthM);
    if (floorObj.imageWidthPixel != null) floor.setImageWidthPixel(floorObj.imageWidthPixel);
    if (floorObj.imageHeightPixel != null) floor.setImageHeightPixel(floorObj.imageHeightPixel);
    if (floorObj.isActive != null) floor.setIsActive(floorObj.isActive == null ? null : (floorObj.isActive ? true : false));
    if (floorObj.buildingId != null) floor.setBuildingID(floorObj.buildingId);
    if (floorObj.building != null) floor.setBuilding(Building.thaw(floorObj.building));
    if (floorObj.rooms != null) floor.setRooms(Room.thawList(floorObj.rooms));
    return floor;
  }

  static thawList(floorObjList) {
    if (floorObjList == null) return [];
    var floors = [];
    for (var i = 0; i < floorObjList.length; i++) {
      floors.push(Floor.thaw(floorObjList[i]));
    }
    return floors;
  }

  static freeze(floor) {
    if (floor == null) return null;
    var floorObj = {};
    if (floor.getID() != null) floorObj.id = floor.getID();
    if (floor.getZIndex() != null) floorObj.zIndex = floor.getZIndex();
    if (floor.getFloorPlanImage() != null) floorObj.floorPlanImage = floor.getFloorPlanImage();
    if (floor.getIdentifier() != null) floorObj.identifier = floor.getIdentifier();
    if (floor.getLongLeftTop() != null) floorObj.longLeftTop = floor.getLongLeftTop();
    if (floor.getLatLeftTop() != null) floorObj.latLeftTop = floor.getLatLeftTop();
    if (floor.getImageWidthM() != null) floorObj.imageWidthM = floor.getImageWidthM();
    if (floor.getImageWidthPixel() != null) floorObj.imageWidthPixel = floor.getImageWidthPixel();
    if (floor.getImageHeightPixel() != null) floorObj.imageHeightPixel = floor.getImageHeightPixel();
    if (floor.getIsActive() != null) floorObj.isActive = floor.getIsActive() == null ? null : (floor.getIsActive() ? 1 : 0);
    if (floor.getBuildingID() != null) floorObj.buildingId = floor.getBuildingID();
    if (floor.getBuilding() != null) floorObj.building = Building.freeze(floor.getBuilding());
    if (floor.getRooms() != null) floorObj.rooms = Room.freezeList(floor.getRooms());
    return floorObj;
  }

  static freezeList(floors) {
    if (floors == null) return [];
    var floorObjList = [];
    for (var i = 0; i < floors.length; i++) {
      floorObjList.push(Floor.freeze(floors[i]));
    }
    return floorObjList;
  }

  constructor() {
    this.setID(null);
    this.setZIndex(null);
    this.setFloorPlanImage(null);
    this.setIdentifier(null);
    this.setLongLeftTop(null);
    this.setLatLeftTop(null);
    this.setImageWidthM(null);
    this.setImageWidthPixel(null);
    this.setImageHeightPixel(null);
    this.setIsActive(null);
    this.setBuildingID(null);
    this.setBuilding(null);
    this.setRooms(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setZIndex(zIndex) {
    this.zIndex = zIndex;
  }

  setFloorPlanImage(floorPlanImage) {
    this.floorPlanImage = floorPlanImage;
  }

  setIdentifier(identifier) {
    this.identifier = identifier;
  }

  setLongLeftTop(longLeftTop) {
    this.longLeftTop = longLeftTop;
  }

  setLatLeftTop(latLeftTop) {
    this.latLeftTop = latLeftTop;
  }

  setImageWidthM(imageWidthM) {
    this.imageWidthM = imageWidthM;
  }

  setImageWidthPixel(imageWidthPixel) {
    this.imageWidthPixel = imageWidthPixel;
  }

  setImageHeightPixel(imageHeightPixel) {
    this.imageHeightPixel = imageHeightPixel;
  }

  setIsActive(isActive) {
    this.isActive = isActive;
  }

  setBuildingID(buildingID) {
    this.buildingID = buildingID;
  }

  setBuilding(building) {
    this.building = building;
  }

  setRooms(rooms) {
    this.rooms = rooms;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getZIndex() {
    return this.zIndex;
  }

  getFloorPlanImage() {
    return this.floorPlanImage;
  }

  getIdentifier() {
    return this.identifier;
  }

  getLongLeftTop() {
    return this.longLeftTop;
  }

  getLatLeftTop() {
    return this.latLeftTop;
  }

  getImageWidthM() {
    return this.imageWidthM;
  }

  getImageWidthPixel() {
    return this.imageWidthPixel;
  }

  getImageHeightPixel() {
    return this.imageHeightPixel;
  }

  getIsActive() {
    return this.isActive;
  }

  getBuildingID() {
    return this.buildingID;
  }

  getBuilding() {
    return this.building;
  }

  getRooms() {
    return this.rooms;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

}

import React, { useEffect } from 'react';
import { Card, Typography, CardContent, Grid, Select, MenuItem, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';

// Icons
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

// Constants
import { GREY_SCALE } from '../../../constants';

export default function SelectorWidget({ buildings, onBuildingSelection, onFloorSelection, selectedBuilding, selectedFloorID }) {

  useEffect(() => {
    // Initially select a floor
    if (selectedBuilding === '') {
      selectedBuilding = null;
      onBuildingSelection(null);
    }
    if (selectedBuilding != null && selectedFloorID == null && selectedBuilding?.getFloors()?.length > 0) {
      onFloorSelection(selectedBuilding?.getFloors()[0]?.getID());
    }
  }, [buildings, onBuildingSelection, onFloorSelection, selectedBuilding, selectedFloorID]);

  function handleDownArrow() {
    let floorIdx = selectedBuilding?.getFloors()?.findIndex(floor => floor.getID() === selectedFloorID);
    if (selectedBuilding != null && floorIdx > 0) onFloorSelection(selectedBuilding?.getFloors()[floorIdx - 1].getID());
  }

  function handleUpArrow() {
    let floorIdx = selectedBuilding?.getFloors()?.findIndex(floor => floor.getID() === selectedFloorID);
    if (selectedBuilding != null && floorIdx < selectedBuilding?.getFloors().length - 1) onFloorSelection(selectedBuilding?.getFloors()[floorIdx + 1].getID());
  }

  return (
    <Card style={{ backgroundColor: GREY_SCALE[3] }}>
      <CardContent style={{ padding: '5px' }}>
        <Grid container direction='row' justify='space-between' alignItems='center'>
          <Grid xs={6} item>
            <Select
              value={selectedBuilding}
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              onChange={(e) => onBuildingSelection(e.target.value)}
              style={{ marginLeft: '40px', width: '200px' }}>
              {buildings && buildings.map((building, index) => (
                <MenuItem default value={building} key={index}>{building.getName()}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid xs={6} container item direction='row' justify='flex-end' alignItems='center'>
            {selectedBuilding && selectedBuilding?.getFloors().length > 0 &&
              <>
                <Typography variant='overline' display='inline' style={{ fontWeight: 'bold', marginRight: 5 }}>Floor</Typography>
                <IconButton onClick={() => handleDownArrow()} disabled={selectedBuilding?.getFloors()?.findIndex(floor => floor.getID() === selectedFloorID) === 0}><KeyboardArrowLeftIcon /></IconButton>
                {selectedBuilding?.getFloors().map((floor, index) =>
                  <IconButton value={floor.getID()} key={index} onClick={() => onFloorSelection(floor.getID())} style={selectedFloorID === floor.getID() ? { backgroundColor: GREY_SCALE[4] } : {}}>
                    <div style={{ width: 15, height: 15, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 15 }}>{floor.getIdentifier()}</div>
                  </IconButton>
                )}
                <IconButton onClick={() => handleUpArrow()} disabled={selectedBuilding?.getFloors()?.findIndex(floor => floor.getID() === selectedFloorID) >= selectedBuilding?.getFloors().length - 1}><KeyboardArrowRightIcon /></IconButton>
              </>
            }
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

SelectorWidget.propTypes = {
  buildings: PropTypes.array,
  onBuildingSelection: PropTypes.func,
  onFloorSelection: PropTypes.func,
  selectedBuilding: PropTypes.object,
  selectedFloorID: PropTypes.number
};

import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PropTypes from 'prop-types';
import DefaultPortrait from '../../../images/DefaultPortrait.png';

// Components
import ListCard from '../../../components/ListCard';
import BadgeStatusWidget from '../../../components/BadgeStatusWidget';

// Constants
import { GREY } from '../../../constants';
import { DateTime } from 'luxon';

export default function StaffMemberListCard({ staffMember, onClick, selectedCardObject, isFullWidth }) {
  const [errorLoadingImage, setErrorLoadingImage] = useState(false);

  const onError = () => {
    setErrorLoadingImage(true);
  };

  return (
    <ListCard statusColor={staffMember.getBadge()?.getLastSeenRoom()?.getAlertStatus()?.getColor()?.main} onClick={onClick} isSelected={selectedCardObject === staffMember}>
      <Grid container direction={isFullWidth ? 'row' : 'column'} style={isFullWidth ? {} : { width: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <Grid lg item style={{ display: 'flex', alignItems: 'center', paddingLeft: (isFullWidth ? '20px': '0px') }}>
          <div container style={isFullWidth ? { display: 'flex', flexDirection: 'row' } : { display: 'flex', flexDirection: 'column' }}>

            {/* Image */}
            <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: '10px', paddingRight: '10px' }}>
              {errorLoadingImage ?
                <AccountCircleIcon style={{ height: 120, width: 120 }} /> :
                <img
                  height={120}
                  width={120}
                  src={staffMember.getImagePath() ?? DefaultPortrait}
                  alt='Staff Member Profile'
                  onError={onError}
                  style={{ marginTop: '10px', marginBottom: '10px', borderRadius: '60px', objectFit: 'cover' }} />}
            </Grid>

            <Grid container id="inner" direction='column' justify='center' alignItems={isFullWidth ? 'flex-start' : 'center'} style={{ marginLeft: (isFullWidth ? '5px' : '0px') }}>
              {/* Name */}
              <Grid item style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Typography variant='h4'>{staffMember.getFirstName()} {staffMember.getLastName()}</Typography>
              </Grid>

              {/* Last Seen */}
              <Grid item style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Typography variant='h6'>Last Seen: {(staffMember.getBadge()?.getLastSeenRoom()?.getIdentifier() && staffMember.getBadge()?.getLastPositionTimestamp()) ? staffMember.getBadge()?.getLastSeenRoom()?.getIdentifier() + ' @ ' + staffMember.getBadge()?.getLastPositionTimestamp().toLocaleString(DateTime.DATETIME_SHORT) : 'Unknown'}</Typography>
              </Grid>
            </Grid>
          </div>
        </Grid>

        {/* Device Status */}
        <Grid lg={3} item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: (isFullWidth ? '0px' : '30px') }}>
          {staffMember.getBadge() != null ?
            <BadgeStatusWidget badge={staffMember.getBadge()} />
            :
            <Typography variant='button' style={{ color: GREY.main, width: 150 }}>No Badge Assigned</Typography>
          }
        </Grid>
      </Grid>
    </ListCard>
  );
}

StaffMemberListCard.propTypes = {
  staffMember: PropTypes.object,
  onClick: PropTypes.func,
  selectedCardObject: PropTypes.object,
  isFullWidth: PropTypes.bool
};

import React from 'react';
import { IconButton, Dialog, Typography, Button } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PropTypes from 'prop-types';

export default class InfoButton extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false
    };
  }

  handleClose = () => {
    this.setState({ open: false });
  }

  handleOpen = () => {
    this.setState({ open: true });
  }

  render() {
    return (
      <>
        <IconButton onClick={this.handleOpen}><InfoOutlinedIcon fontSize='small' /></IconButton>

        {/* Dialog Box */}
        <Dialog open={this.state.open} onClose={this.handleClose}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <Typography variant="h4" style={{ padding: '.5em .5em 0em .5em' }}>{this.props.name ? this.props.name : 'Help Modal'}</Typography>
            <Typography variant="body1" style={{ padding: '1em 1em 2em 1em' }}>{this.props.body ? this.props.body : ''}</Typography>
            <Button onClick={this.handleClose} style={{ backgroundColor: this.props.color.main, color: this.props.color.text, marginBottom: '1em' }}>Close</Button>
          </div>
        </Dialog>
      </>
    );
  }
}

InfoButton.propTypes = {
  name: PropTypes.string,
  body: PropTypes.any,
  color: PropTypes.object
};
import { v4 as uuidv4 } from 'uuid';

// Constants
import { isNull } from '../../constants';
const DEFAULT_TIMEOUT = 3000;

export const ADD_ALERT = 'ADD_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const CLEAR_ALERTS = 'CLEAR_ALERTS';
export const CLEAR_INFOS = 'CLEAR_INFOS';
export const REMOVE_ALERT_FROM_MESSAGE = 'REMOVE_ALERT_FROM_MESSAGE';
export const AlertSeverity = {
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
  INFO: 'info'
};

export const addAlert = (msg, severity = AlertSeverity.ERROR, link = null, showClose = true) => ({
  type: ADD_ALERT,
  id: uuidv4(),
  msg,
  link,
  severity,
  showClose
});

export const removeAlert = (id) => ({
  type: REMOVE_ALERT,
  id
});

export const removeAlertFromMessage = (msg) => ({
  type: REMOVE_ALERT_FROM_MESSAGE,
  msg
});

export const clearErrors = () => ({
  type: CLEAR_ERRORS
});

export const clearAlerts = () => ({
  type: CLEAR_ALERTS
});

export const clearInfos = () => ({
  type: CLEAR_INFOS
});

export const setSuccess = (dispatch, msg) => {
  dispatch(clearErrors());
  setAlert(dispatch, msg, AlertSeverity.SUCCESS, true);
};

export const setAlert = (
  dispatch,
  msg,
  severity = AlertSeverity.ERROR,
  haveTimeout = false,
  link = null,
  showClose = true
) => {
  window.scrollTo(0, 0);

  if ((isNull(msg) || msg === '') && (isNull(msg.message) || msg.message === '')) {
    switch (severity) {
      case AlertSeverity.ERROR:
        msg = 'Error: something went wrong. Please try again later.';
        break;
      case AlertSeverity.WARNING:
        msg = 'Warning!';
        break;
      case AlertSeverity.SUCCESS:
        msg = 'Success!';
        break;
      default:
        msg = 'An unknown event occurred.';
    }
  }
  else if (!isNull(msg.message)) msg = msg.message;

  // Add New Alert to App State
  const newAlert = addAlert(msg, severity, link, showClose);
  dispatch(newAlert);

  // Remove the New Alert After Timeout (3 sec default)
  if (haveTimeout)
    setTimeout(() => dispatch(removeAlert(newAlert.id)), DEFAULT_TIMEOUT);
};

import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { CircularProgress, Typography, TextField, Button, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { setSuccess, clearErrors, setAlert } from '../../../../redux/actions/alertActions';

// Components
import InfoButton from '../../../../components/InfoButton';

// Server
import { createUser, getUser, updateUser } from '../../../../api';

// Constants
import { DEFAULT, ValidCreateUserTypes, HelpDescriptions } from '../../../../constants';

class CreateUser extends Component {

  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      password: '',
      userType: 'Admin',
      formError: false,
      isLoading: false,
      user: null,
      redirectToUsers: false
    };
  }

  componentDidMount() {
    const userID = this.props.match.params.user_id;

    if (userID != null) {
      this.setState({ isLoading: true });
      getUser(userID).then(user => {
        this.setState({
          user: user,
          firstName: user.getFirstName(),
          lastName: user.getLastName(),
          email: user.getEmail(),
          phoneNumber: user.getPhoneNumber(),
          userType: user.getUserType().name,
          isLoading: false
        });
      }).catch(error => {
        this.props.setError(error ? error : 'Unable to retrieve user info. Please try again later.');
        this.setState({ isLoading: false });
      });
    }
  }

  componentWillUnmount() {
    this.props.clearErrors();
  }

  hasFormError() {
    return this.state.firstName === '' ||
      this.state.lastName === '' ||
      this.state.email === '' ||
      this.state.phoneNumber === '' ||
      (this.state.user == null && (this.state.password === '' || this.state.password.length < 8)) ||
      this.state.userType === '';
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitForm = () => {
    const formError = this.hasFormError();
    this.setState({ formError: formError });

    if (!formError) {
      // Start the loading icon and disable the buttons
      this.setState({ isLoading: true });

      const {
        firstName,
        lastName,
        email,
        phoneNumber,
        password,
        userType,
        user
      } = this.state;

      // Update User
      if (this.props.match.params.user_id != null) {
        updateUser({ userID: user.getID(), firstName, lastName, email, phoneNumber, userType }).then(() => {
          this.props.setSuccess('Successfully updated user!');

          // Clear the form
          this.setState({
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            password: '',
            userType: '',
            isLoading: false,
            redirectToUsers: true
          });
        }).catch(error => {
          this.props.setError(error ? error : 'Error updating the user, please try again.');
          this.setState({ isLoading: false });
        });
      }

      // Create User
      else {
        // Attempt to create the user
        createUser({ firstName, lastName, email, phoneNumber, password, userType }).then(() => {
          this.props.setSuccess('Successfully created a SimulAlert user!');

          // Clear the form
          this.setState({
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            password: '',
            userType: '',
            isLoading: false
          });
        }).catch((error) => {
          this.props.setError(error ? error : 'Error creating the user, please try again.');
          this.setState({ isLoading: false });
        });
      }
    }
  }

  render() {
    if (this.state.redirectToUsers) return <Redirect to='/admin/users' />;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'flex-start' }}>
        <Typography variant='h3' style={{ margin: '25px 20px 50px 20px', textAlign: 'center' }}>Add a SimulAlert User</Typography>
        <form autoComplete="off" style={{ width: (this.props.isFullWidth? '50%': '90%') }}>

          {/* Name Input */}
          <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between', marginBottom: '10px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '40%' }}>
              <Typography variant='h6'>Name</Typography>
              <InfoButton name={HelpDescriptions.TEMP.name} body={HelpDescriptions.TEMP.body} color={this.props.statusColor} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: (this.props.isMobile ? '100%' : '60%') }}>
              <TextField required error={this.state.formError && this.state.firstName === ''} name="firstName" label="First Name" style={{ width: '49%' }} value={this.state.firstName} onChange={this.onChange} variant='filled' />
              <TextField required error={this.state.formError && this.state.lastName === ''} name="lastName" label="Last Name" style={{ width: '49%' }} value={this.state.lastName} onChange={this.onChange} variant='filled' />
            </div>
          </div>

          {/* Email Input */}
          <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between', marginBottom: '10px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '40%' }}>
              <Typography variant='h6'>Email</Typography>
              <InfoButton name={HelpDescriptions.TEMP.name} body={HelpDescriptions.TEMP.body} color={this.props.statusColor} />
            </div>
            <TextField required error={this.state.formError && this.state.email === ''} name="email" label="Email" style={{ width: (this.props.isMobile ? '100%' : '60%') }} value={this.state.email} type='email' onChange={this.onChange} variant='filled' />
          </div>

          {/* Phone Number Input */}
          <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between', marginBottom: '10px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '40%' }}>
              <Typography variant='h6'>Phone Number</Typography>
              <InfoButton name={HelpDescriptions.TEMP.name} body={HelpDescriptions.TEMP.body} color={this.props.statusColor} />
            </div>
            <TextField required type='phoneNumber' error={this.state.formError && this.state.phoneNumber === ''} name="phoneNumber" label="Phone Number" style={{ width: (this.props.isMobile ? '100%' : '60%') }} value={this.state.phoneNumber} onChange={this.onChange} variant='filled' />
          </div>

          {/* Password Input */}
          {this.props.match.params.user_id == null &&
          <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between', marginBottom: '10px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '40%' }}>
              <Typography variant='h6'>Password</Typography>
              <InfoButton name={HelpDescriptions.TEMP.name} body={HelpDescriptions.TEMP.body} color={this.props.statusColor} />
            </div>
            <TextField required error={this.state.formError && (this.state.password === '' || (this.state.password && this.state.password.length < 8))} name="password" label="Password" style={{ width: (this.props.isMobile ? '100%' : '60%') }} value={this.state.password} onChange={this.onChange} variant='filled' />
          </div>}

          {/* Select Staff Member Type */}
          <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between', marginBottom: '10px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '40%' }}>
              <Typography variant='h6'>User Type</Typography>
              <InfoButton name={HelpDescriptions.TEMP.name} body={HelpDescriptions.TEMP.body} color={this.props.statusColor} />
            </div>
            <TextField
              required
              select
              error={this.state.formError && this.state.userType === ''}
              disabled={this.state.user && (this.state.user.getUserType().name === 'Super' || this.state.user.getUserType().name === 'IT')}
              name="userType"
              label="User Type"
              style={{ width: (this.props.isMobile ? '100%' : '60%') }}
              value={this.state.userType}
              onChange={this.onChange}
              variant='filled'>
              {Object.values(ValidCreateUserTypes).map((type) => (
                <MenuItem key={type} value={type}>
                  { type }
                </MenuItem>
              ))}
              <MenuItem value={'IT'} disabled={true}>
                IT
              </MenuItem>
              <MenuItem value={'Super'} disabled={true}>
                Super
              </MenuItem>
            </TextField>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <Button onClick={this.submitForm} variant="contained" style={{ width: '200px', height: '50px', color: this.props.statusColor.text, backgroundColor: this.props.statusColor.main, margin: '30px' }} disabled={this.state.isLoading}>
              {this.state.user != null ? 'Update' : 'Create'} User
              {this.state.isLoading && <CircularProgress size={25} style={{ color: this.props.statusColor.text, marginLeft: '1em' }} />}
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  statusColor: state.status.schoolStatus ? state.status.schoolStatus.getColor() : DEFAULT,
  isMobile: state.ui.isMobile,
  isFullWidth: state.ui.isFullWidth
});

const mapDispatchToProps = (dispatch) => ({
  setError: (msg) => {
    dispatch(clearErrors());
    setAlert(dispatch, msg);
  },
  setSuccess: (msg) => setSuccess(dispatch, msg),
  clearErrors: () => dispatch(clearErrors())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateUser));

CreateUser.propTypes = {
  match: PropTypes.object,
  setError: PropTypes.func,
  setSuccess: PropTypes.func,
  clearErrors: PropTypes.func,
  setupMode: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  statusColor: PropTypes.object
};

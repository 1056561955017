export default class Setting {
  static thaw(settingObj) {
    if (settingObj == null) return null;
    const setting = new Setting();
    if (settingObj.id != null) setting.setID(settingObj.id);
    if (settingObj.name != null) setting.setName(settingObj.name);
    if (settingObj.friendlyName != null) setting.setFriendlyName(settingObj.friendlyName);
    if (settingObj.value != null) setting.setValue(settingObj.value);
    if (settingObj.description != null) setting.setDescription(settingObj.description);
    if (settingObj.restartRequired != null) setting.setRestartRequired(settingObj.restartRequired == null ? null : (settingObj.restartRequired ? true : false));
    if (settingObj.formType != null) setting.setFormType(settingObj.formType);
    if (settingObj.backendType != null) setting.setBackendType(settingObj.backendType);
    if (settingObj.minValue != null) setting.setMinValue(settingObj.minValue);
    if (settingObj.maxValue != null) setting.setMaxValue(settingObj.maxValue);
    if (settingObj.display != null) setting.setDisplay(settingObj.display == null ? null : (settingObj.display ? true : false));
    if (settingObj.setupModeOnly != null) setting.setSetupModeOnly(settingObj.setupModeOnly == null ? null : (settingObj.setupModeOnly ? true : false));
    return setting;
  }

  static thawList(settingObjList) {
    if (settingObjList == null) return [];
    var settings = [];
    for (var i = 0; i < settingObjList.length; i++) {
      settings.push(Setting.thaw(settingObjList[i]));
    }
    return settings;
  }

  static freeze(setting) {
    if (setting == null) return null;
    var settingObj = {};
    if (setting.getID() != null) settingObj.id = setting.getID();
    if (setting.getName() != null) settingObj.name = setting.getName();
    if (setting.getFriendlyName() != null) settingObj.friendlyName = setting.getFriendlyName();
    if (setting.getValue() != null) settingObj.value = setting.getValue();
    if (setting.getDescription() != null) settingObj.description = setting.getDescription();
    if (setting.getRestartRequired() != null) settingObj.restartRequired = setting.getRestartRequired() == null ? null : (setting.getRestartRequired() ? 1 : 0);
    if (setting.getFormType() != null) settingObj.formType = setting.getFormType();
    if (setting.getBackendType() != null) settingObj.backendType = setting.getBackendType();
    if (setting.getMinValue() != null) settingObj.minValue = setting.getMinValue();
    if (setting.getMaxValue() != null) settingObj.maxValue = setting.getMaxValue();
    if (setting.getDisplay() != null) settingObj.display = setting.getDisplay() == null ? null : (setting.getDisplay() ? 1 : 0);
    if (setting.getSetupModeOnly() != null) settingObj.setupModeOnly = setting.getSetupModeOnly() == null ? null : (setting.getSetupModeOnly() ? 1 : 0);
    return settingObj;
  }

  static freezeList(settings) {
    if (settings == null) return [];
    var settingObjList = [];
    for (var i = 0; i < settings.length; i++) {
      settingObjList.push(Setting.freeze(settings[i]));
    }
    return settingObjList;
  }

  constructor() {
    this.setID(null);
    this.setName(null);
    this.setFriendlyName(null);
    this.setValue(null);
    this.setDescription(null);
    this.setRestartRequired(null);
    this.setFormType(null);
    this.setBackendType(null);
    this.setMinValue(null);
    this.setMaxValue(null);
    this.setDisplay(null);
    this.setSetupModeOnly(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setName(name) {
    this.name = name;
  }

  setFriendlyName(friendlyName) {
    this.friendlyName = friendlyName;
  }

  setValue(value) {
    this.value = value;
  }

  setDescription(description) {
    this.description = description;
  }

  setRestartRequired(restartRequired) {
    this.restartRequired = restartRequired;
  }

  setFormType(formType) {
    this.formType = formType;
  }

  setBackendType(backendType) {
    this.backendType = backendType;
  }

  setMinValue(minValue) {
    this.minValue = minValue;
  }

  setMaxValue(maxValue) {
    this.maxValue = maxValue;
  }

  setDisplay(display) {
    this.display = display;
  }

  setSetupModeOnly(setupModeOnly) {
    this.setupModeOnly = setupModeOnly;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getName() {
    return this.name;
  }

  getFriendlyName() {
    return this.friendlyName;
  }

  getValue() {
    return this.value;
  }

  getDescription() {
    return this.description;
  }

  getRestartRequired() {
    return this.restartRequired;
  }

  getFormType() {
    return this.formType;
  }

  getBackendType() {
    return this.backendType;
  }

  getMinValue() {
    return this.minValue;
  }

  getMaxValue() {
    return this.maxValue;
  }

  getDisplay() {
    return this.display;
  }

  getSetupModeOnly() {
    return this.setupModeOnly;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

}

import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

// Icons
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

// Constants
import { GREY, GREEN } from '../constants';

export default function MultiHubStatusWidget({ hubs }) {
  const [numConnected, setNumConnected] = useState(0);

  useEffect(() => {

    var connected = 0;
    hubs.forEach(hub => {
      if (hub.getIsConnected()) {
        connected += 1;
      }
    });
    setNumConnected(connected);

  }, [hubs]);

  return (
    <Grid container direction='column' justify='center'>
      <Typography variant='overline' style={{ color: GREY.dark }}>Hub Status</Typography>

      <Grid container direction='row' justify='center' alignItems='center'>
        {hubs.length === numConnected ? 
          <>
            <CheckCircleIcon style={{ color: GREEN.main, fontSize: 40, marginRight: '5px' }} /> 
            <Typography variant='h6' style={{ color: GREY.dark }}>{numConnected} Connected</Typography>
          </>
          : 
          <>
            <CancelIcon style={{ color: GREY.main, fontSize: 40, marginRight: '5px' }} />
            <Typography variant='h6' style={{ color: GREY.dark }}>{(hubs.length - numConnected) + '/' + hubs.length} Not Connected</Typography>
          </>
        }
      </ Grid>

    </Grid>
  );
}

MultiHubStatusWidget.propTypes = {
  hubs: PropTypes.array,
};
import { DateTime } from 'luxon';
import { AlertUpdate, Badge, Coordinate, Room, AlertType, Floor } from './';

export default class Hub {
  static thaw(hubObj) {
    if (hubObj == null) return null;
    const hub = new Hub();
    if (hubObj.serialNumber != null) hub.setSerialNumber(hubObj.serialNumber);
    if (hubObj.nodeAddress != null) hub.setNodeAddress(hubObj.nodeAddress);
    if (hubObj.lastSeenTimestamp != null) hub.setLastSeenTimestamp(DateTime.fromISO(hubObj.lastSeenTimestamp));
    if (hubObj.notes != null) hub.setNotes(hubObj.notes);
    if (hubObj.hubType != null) hub.setHubType(hubObj.hubType);
    if (hubObj.firmwareVersion != null) hub.setFirmwareVersion(hubObj.firmwareVersion);
    if (hubObj.isActive != null) hub.setIsActive(hubObj.isActive == null ? null : (hubObj.isActive ? true : false));
    if (hubObj.roomId != null) hub.setRoomID(hubObj.roomId);
    if (hubObj.alertStatusId != null) hub.setAlertStatusID(hubObj.alertStatusId);
    if (hubObj.floorId != null) hub.setFloorID(hubObj.floorId);
    if (hubObj.coordinateId != null) hub.setCoordinateID(hubObj.coordinateId);
    if (hubObj.batteryLevel != null) hub.setBatteryLevel(hubObj.batteryLevel);
    if (hubObj.coordinate != null) hub.setCoordinate(Coordinate.thaw(hubObj.coordinate));
    if (hubObj.floor != null) hub.setFloor(Floor.thaw(hubObj.floor));
    if (hubObj.alertUpdates != null) hub.setAlertUpdates(AlertUpdate.thawList(hubObj.alertUpdates));
    if (hubObj.nearestBadges != null) hub.setNearestBadges(Badge.thawList(hubObj.nearestBadges));
    if (hubObj.room != null) hub.setRoom(Room.thaw(hubObj.room));
    if (hubObj.alertStatus != null) hub.setAlertStatus(AlertType.thaw(hubObj.alertStatus));
    return hub;
  }

  static thawList(hubObjList) {
    if (hubObjList == null) return [];
    var hubs = [];
    for (var i = 0; i < hubObjList.length; i++) {
      hubs.push(Hub.thaw(hubObjList[i]));
    }
    return hubs;
  }

  static freeze(hub) {
    if (hub == null) return null;
    var hubObj = {};
    if (hub.getSerialNumber() != null) hubObj.serialNumber = hub.getSerialNumber();
    if (hub.getNodeAddress() != null) hubObj.nodeAddress = hub.getNodeAddress();
    if (hub.getLastSeenTimestamp() != null) hubObj.lastSeenTimestamp = DateTime.toISO(hub.getLastSeenTimestamp());
    if (hub.getNotes() != null) hubObj.notes = hub.getNotes();
    if (hub.getHubType() != null) hubObj.hubType = hub.getHubType();
    if (hub.getFirmwareVersion() != null) hubObj.firmwareVersion = hub.getFirmwareVersion();
    if (hub.getIsActive() != null) hubObj.isActive = hub.getIsActive() == null ? null : (hub.getIsActive() ? 1 : 0);
    if (hub.getRoomID() != null) hubObj.roomId = hub.getRoomID();
    if (hub.getAlertStatusID() != null) hubObj.alertStatusId = hub.getAlertStatusID();
    if (hub.getFloorID() != null) hubObj.floorId = hub.getFloorID();
    if (hub.getCoordinateID() != null) hubObj.coordinateId = hub.getCoordinateID();
    if (hub.getBatteryLevel() != null) hubObj.batteryLevel = hub.getBatteryLevel();
    if (hub.getCoordinate() != null) hubObj.coordinate = Coordinate.freeze(hub.getCoordinate());
    if (hub.getFloor() != null) hubObj.floor = Floor.freeze(hub.getFloor());
    if (hub.getAlertUpdates() != null) hubObj.alertUpdates = AlertUpdate.freezeList(hub.getAlertUpdates());
    if (hub.getNearestBadges() != null) hubObj.nearestBadges = Badge.freezeList(hub.getNearestBadges());
    if (hub.getRoom() != null) hubObj.room = Room.freeze(hub.getRoom());
    if (hub.getAlertStatus() != null) hubObj.alertStatus = AlertType.freeze(hub.getAlertStatus());
    return hubObj;
  }

  static freezeList(hubs) {
    if (hubs == null) return [];
    var hubObjList = [];
    for (var i = 0; i < hubs.length; i++) {
      hubObjList.push(Hub.freeze(hubs[i]));
    }
    return hubObjList;
  }

  constructor() {
    this.setSerialNumber(null);
    this.setNodeAddress(null);
    this.setLastSeenTimestamp(null);
    this.setNotes(null);
    this.setHubType(null);
    this.setFirmwareVersion(null);
    this.setIsActive(null);
    this.setRoomID(null);
    this.setAlertStatusID(null);
    this.setFloorID(null);
    this.setCoordinateID(null);
    this.setBatteryLevel(null);
    this.setCoordinate(null);
    this.setFloor(null);
    this.setAlertUpdates(null);
    this.setNearestBadges(null);
    this.setRoom(null);
    this.setAlertStatus(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setSerialNumber(serialNumber) {
    this.serialNumber = serialNumber;
  }

  setNodeAddress(nodeAddress) {
    this.nodeAddress = nodeAddress;
  }

  setLastSeenTimestamp(lastSeenTimestamp) {
    this.lastSeenTimestamp = lastSeenTimestamp;
  }

  setNotes(notes) {
    this.notes = notes;
  }

  setHubType(hubType) {
    this.hubType = hubType;
  }

  setFirmwareVersion(firmwareVersion) {
    this.firmwareVersion = firmwareVersion;
  }

  setIsActive(isActive) {
    this.isActive = isActive;
  }

  setRoomID(roomID) {
    this.roomID = roomID;
  }

  setAlertStatusID(alertStatusID) {
    this.alertStatusID = alertStatusID;
  }

  setFloorID(floorID) {
    this.floorID = floorID;
  }

  setCoordinateID(coordinateID) {
    this.coordinateID = coordinateID;
  }

  setBatteryLevel(batteryLevel) {
    this.batteryLevel = batteryLevel;
  }

  setCoordinate(coordinate) {
    this.coordinate = coordinate;
  }

  setFloor(floor) {
    this.floor = floor;
  }

  setAlertUpdates(alertUpdates) {
    this.alertUpdates = alertUpdates;
  }

  setNearestBadges(nearestBadges) {
    this.nearestBadges = nearestBadges;
  }

  setRoom(room) {
    this.room = room;
  }

  setAlertStatus(alertStatus) {
    this.alertStatus = alertStatus;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getSerialNumber() {
    return this.serialNumber;
  }

  getNodeAddress() {
    return this.nodeAddress;
  }

  getLastSeenTimestamp() {
    return this.lastSeenTimestamp;
  }

  getNotes() {
    return this.notes;
  }

  getHubType() {
    return this.hubType;
  }

  getFirmwareVersion() {
    return this.firmwareVersion;
  }

  getIsActive() {
    return this.isActive;
  }

  getRoomID() {
    return this.roomID;
  }

  getAlertStatusID() {
    return this.alertStatusID;
  }

  getFloorID() {
    return this.floorID;
  }

  getCoordinateID() {
    return this.coordinateID;
  }

  getBatteryLevel() {
    return this.batteryLevel;
  }

  getCoordinate() {
    return this.coordinate;
  }

  getFloor() {
    return this.floor;
  }

  getAlertUpdates() {
    return this.alertUpdates;
  }

  getNearestBadges() {
    return this.nearestBadges;
  }

  getRoom() {
    return this.room;
  }

  getAlertStatus() {
    return this.alertStatus;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

  getIsConnected() {
    let diff = this.lastSeenTimestamp?.diff(DateTime.now(), ['minutes']).get('minutes');
    if (diff == null) return false;
    return Math.abs(diff) < 5;
  }
}

import React, { Component } from 'react';
import {
  CircularProgress, Typography, TextField, Button, MenuItem, Avatar, Tooltip
} from '@material-ui/core';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { setSuccess, clearErrors, setAlert } from '../redux/actions/alertActions';

// Server
import { deleteStaffMember, listBuildings, listStaffTypes, createStaffMember, updateStaffMember, deleteBadge } from '../api';

// Components
import InfoButton from './InfoButton';

// Constants
import { DEFAULT } from '../constants';
const STAFF_MEMBER_IMAGE_HELP = 'Optionally upload a picture of the staff member for easier identification.';
const STAFF_MEMBER_TYPE_HELP = 'Select which type of staff member this individual is. This makes searching easier on the dashboard.';

class StaffMemberForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      staffMemberImagePath: '',
      staffMemberImage: null,
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      staffTypeID: '',
      buildingID: '',
      floorID: '',
      roomID: '',
      formError: false,
      staffTypes: [],
      badges: [],
      buildings: [],
      floors: [],
      rooms: [],
      isLoading: false
    };
  }

  componentDidMount() {
    // If staff member is passed in we should populate our state with its values.
    if (this.props.staffMember != null) {
      const staffMember = this.props.staffMember;
      this.setState({
        firstName: staffMember.getFirstName() || '',
        lastName: staffMember.getLastName() || '',
        phoneNumber: staffMember.getPhoneNumber() || '',
        email: staffMember.getEmail() || ''
      });
    }

    // Retrieve list of the staff types
    listStaffTypes().then(staffTypes => {
      this.setState({ staffTypes: staffTypes, staffTypeID: this.props.staffMember?.getStaffType()?.getID() || '' });
    }).catch(error => {
      this.props.setError(error ? error : 'Unable to retrieve staff types');
    });

    listBuildings().then(buildings => {
      if (this.props.staffMember?.getAssignedRoomID() != null) {
        buildings.forEach(building => {
          building.getFloors().forEach(floor => {
            if (floor.getRooms().find(room => room.getID() === this.props.staffMember.getAssignedRoomID()) != null) {
              this.setState({ buildingID: building.getID(), floorID: floor.getID(), roomID: this.props.staffMember.getAssignedRoomID() });
            }
          });
        });
      }
      this.setState({ buildings: buildings });
    }).catch(error => {
      this.props.setError(error ? error : 'Unable to retrieve buildings.');
    });
  }

  componentWillUnmount() {
    this.props.clearErrors();
  }

  hasFormError() {
    return this.state.firstName === '' || this.state.lastName === '' || this.state.staffType === '';
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onImageInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });

    // Set Image for Display
    var input = document.getElementById('staffMemberImagePath');
    var fReader = new FileReader();
    fReader.readAsDataURL(input.files[0]);
    this.setState({ staffMemberImage: input.files[0] });
    fReader.onloadend = function (event) {
      document.getElementById('displayImage').innerHTML = '<image src=\'' + event.target.result + '\' style=\'height: 60px;\'/>';
    };
  };

  clearImage = () => {
    this.setState({ staffMemberImagePath: '', staffMemberImage: null });
    document.getElementById('displayImage').innerHTML = null;
    this.fileInput.value = '';
  }

  submitForm = (e) => {
    e.preventDefault();

    // Ensure there is no form error
    const formError = this.hasFormError();
    this.setState({ formError });
    if (formError) return;
    let { firstName, lastName, phoneNumber, email, staffTypeID, staffMemberImage, roomID } = this.state;
    this.setState({ isLoading: true });

    if (phoneNumber === '') phoneNumber = null;
    if (email === '') email = null;

    // Update Existing Staff Member
    if (this.props.staffMember != null) {
      let staffMemberObj = { id: this.props.staffMember.getID(), firstName, lastName, phoneNumber, email, staffTypeID, assignedRoomID: roomID };
      if (staffMemberImage != null) staffMemberObj.staffMemberImage = staffMemberImage;
      updateStaffMember(staffMemberObj).then(() => {
        this.props.setSuccess('Successfully updated Staff Member');
        this.clearImage();
        this.setState({ isLoading: false, firstName: '', lastName: '', phoneNumber: '', email: '', staffTypeID: '', buildingID: '', floorID: '', roomID: '' });
        this.props.onSuccess();
      }).catch(error => {
        this.props.setError(error ? error : 'Error: Unable to update staff member.');
        this.setState({ isLoading: false });
      });
    }

    // Crete New Staff Member
    else {
      createStaffMember({ firstName, lastName, phoneNumber, email, staffTypeID, staffMemberImage, assignedRoomID: roomID }).then(() => {
        this.props.setSuccess('Successfully created Staff Member');
        this.clearImage();
        this.setState({ isLoading: false, firstName: '', lastName: '', phoneNumber: '', email: '', staffTypeID: '', buildingID: '', floorID: '', roomID: '' });
        this.props.onSuccess();
      }).catch(error => {
        this.props.setError(error ? error : 'Error: Unable to create staff member.');
        this.setState({ isLoading: false });
      });
    }
  }

  unassignBadge = () => {
    deleteBadge(this.props.staffMember?.getBadge()?.getSerialNumber()).then(() => {
      this.props.setSuccess('Successfully unassigned badge from staff member.');
      window.location.reload(false);
    }).catch(error => {
      this.props.setError(error ? error : 'Error: Unable to unassign badge.');
    });
  }

  deleteStaffMember = () => {
    deleteStaffMember(this.props.staffMember?.getID()).then(() => {
      this.props.setSuccess('Successfully deleted staff member.');
      this.props.onSuccess();
    }).catch(error => {
      this.props.setError(error ? error : 'Error: Unable to delete staff member.');
    });
  }

  render() {
    return (
      <form onSubmit={this.submitForm} style={{ width: '100%' }}>

        {/* Select Staff Image */}
        <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between', marginBottom: '10px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: (this.props.isMobile ? '100%' : '40%') }}>
            <Typography variant='h6'>Upload Staff Member Image</Typography>
            <InfoButton name='Staff Member Image' body={STAFF_MEMBER_IMAGE_HELP} color={this.props.statusColor} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: (this.props.isMobile ? '100%' : '60%') }}>
            <input type="file" id="staffMemberImagePath" name="staffMemberImagePath" ref={ref=> this.fileInput = ref} label="Select Image" onChange={this.onImageInput} style={{ width: '60%' }} value={this.state.staffMemberImagePath} />
            <div id="displayImage">
              <Avatar />
            </div>
          </div>

        </div>

        {/* Name Input */}
        <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between', marginBottom: '10px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: (this.props.isMobile ? '100%' : '40%') }}>
            <Typography variant='h6'>Name</Typography>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: (this.props.isMobile ? '100%' : '60%') }}>
            <TextField required error={this.state.formError && this.state.firstName === ''} name="firstName" label="First Name" style={{ width: '49%' }} onChange={this.onChange} variant='filled' value={this.state.firstName} />
            <TextField required error={this.state.formError && this.state.lastName === ''} name="lastName" label="Last Name" style={{ width: '49%' }} onChange={this.onChange} variant='filled' value={this.state.lastName} />
          </div>
        </div>

        {/* Phone Number Input */}
        <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between', marginBottom: '10px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: (this.props.isMobile ? '100%' : '40%') }}>
            <Typography variant='h6'>Phone Number</Typography>
          </div>
          <TextField type='phoneNumber' name="phoneNumber" label="Phone Number" style={{ width: (this.props.isMobile ? '100%' : '60%') }} onChange={this.onChange} variant='filled' value={this.state.phoneNumber} />
        </div>

        {/* Email Input */}
        <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between', marginBottom: '10px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: (this.props.isMobile ? '100%' : '40%') }}>
            <Typography variant='h6'>Email</Typography>
          </div>
          <TextField type='email' name="email" label="Email" style={{ width: (this.props.isMobile ? '100%' : '60%') }} onChange={this.onChange} variant='filled' value={this.state.email} />
        </div>

        {/* Select Staff Member Type */}
        <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between', marginBottom: '10px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: (this.props.isMobile ? '100%' : '40%') }}>
            <Typography variant='h6'>Staff Member Type</Typography>
            <InfoButton name='Staff Member Type' body={STAFF_MEMBER_TYPE_HELP} color={this.props.statusColor} />
          </div>
          <TextField
            required
            select
            error={this.state.formError && this.state.staffTypeID === ''}
            name="staffTypeID"
            label="Staff Member Type"
            style={{ width: (this.props.isMobile ? '100%' : '60%') }}
            value={this.state.staffTypeID}
            onChange={this.onChange}
            variant='filled'>
            {this.state.staffTypes.map((type, i) => (
              <MenuItem key={i} value={type.getID()}>
                { type.getName() }
              </MenuItem>
            ))}
          </TextField>
        </div>

        <Typography variant='h4' style={{ marginTop: '1.5em', marginBottom: '0.5em' }}>Associate with Room (Optional)</Typography>

        {/* Select Building */}
        <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between', marginBottom: '10px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: (this.props.isMobile ? '100%' : '40%') }}>
            <Typography variant='h6'>Select Building</Typography>
          </div>
          <TextField
            select
            name="buildingID"
            label="Building"
            value={this.state.buildingID}
            style={{ width: (this.props.isMobile ? '100%' : '60%') }}
            onChange={this.onChange}
            variant='filled'>
            {this.state.buildings?.map((building, index) => (
              <MenuItem key={index} value={building.getID()}>
                {building.getName()}
              </MenuItem>
            ))}
          </TextField>
        </div>

        {/* Select Building Floor */}
        <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between', marginBottom: '10px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: (this.props.isMobile ? '100%' : '40%') }}>
            <Typography variant='h6'>Select Floor</Typography>
          </div>
          <TextField
            select
            name="floorID"
            label="Building Floor"
            value={this.state.floorID}
            style={{ width: (this.props.isMobile ? '100%' : '60%') }}
            onChange={this.onChange}
            variant='filled'>
            {this.state.buildings?.find(building => building.getID() === this.state.buildingID)?.getFloors()?.map((floor, index) => (
              <MenuItem key={index} value={floor.getID()}>
                {floor.getIdentifier()}
              </MenuItem>
            ))}
          </TextField>
        </div>

        {/* Select Room */}
        <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between', marginBottom: '10px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: (this.props.isMobile ? '100%' : '40%') }}>
            <Typography variant='h6'>Select Room</Typography>
          </div>
          <TextField
            select
            name="roomID"
            label="Room"
            value={this.state.roomID}
            style={{ width: (this.props.isMobile ? '100%' : '60%') }}
            onChange={this.onChange}
            variant='filled'>
            <MenuItem value=''>
              N/A
            </MenuItem>
            {this.state.buildings?.find(building => building.getID() === this.state.buildingID)?.getFloors()?.find(floor => floor.getID() === this.state.floorID)?.getRooms()?.map((room, index) => (
              <MenuItem key={index} value={room.getID()}>
                {room.getIdentifier()}
              </MenuItem>
            ))}
          </TextField>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          {this.props.staffMember &&
          <Tooltip title='Delete this staff member. Warning: this is not easy to undo.'>
            <Button onClick={this.deleteStaffMember} variant='outlined' style={{ width: '200px', height: '50px', margin: '15px' }}>Delete Staff Member</Button>
          </Tooltip>}
          {this.props.staffMember && this.props.staffMember.getBadge() != null &&
          <Tooltip title='Unassign badge from staff member. Warning: this will prevent the badge from being able to change statuses.'>
            <Button onClick={this.unassignBadge} variant='outlined' style={{ width: '200px', height: '50px', margin: '15pxpx' }}>Unassign Badge</Button>
          </Tooltip>}
          <Button type='submit' variant="contained" style={{ width: '200px', height: '50px', margin: '15px', backgroundColor: this.props.statusColor.main, color: this.props.statusColor.text }} disabled={this.state.isLoading}>
            Save
            {this.state.isLoading && <CircularProgress size={20} style={{ marginLeft: '10px', color: this.props.statusColor.text }} />}
          </Button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  statusColor: state.status.schoolStatus ? state.status.schoolStatus.getColor() : DEFAULT,
  isMobile: state.ui.isMobile,
  isFullWidth: state.ui.isFullWidth
});

const mapDispatchToProps = dispatch => ({
  setError: (msg) => setAlert(dispatch, msg),
  setSuccess: (msg) => setSuccess(dispatch, msg),
  clearErrors: () => dispatch(clearErrors())
});

export default connect(mapStateToProps, mapDispatchToProps)(StaffMemberForm);

StaffMemberForm.propTypes = {
  setError: PropTypes.func,
  setSuccess: PropTypes.func,
  clearErrors: PropTypes.func,
  statusColor: PropTypes.object,
  isMobile: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  staffMember: PropTypes.object,
  onSuccess: PropTypes.func.isRequired
};

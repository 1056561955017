import React, { Component } from 'react';
import { Grid, Typography, Tab, Fab, InputBase, CardContent, AppBar, Card, Tabs } from '@material-ui/core';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { setAlert } from '../../../redux/actions/alertActions';

// Server
import { listStaff, listRooms, listHubs, setRoomStatus } from '../../../api';

// Icons
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';

// Components
import StaffMemberListCard from './StaffMemberListCard';
import RoomListCard from './RoomListCard';
import StaffMemberInfoPanel from '../../../components/StaffMemberInfoPanel';
import RoomInfoPanel from '../../../components/RoomInfoPanel';
import DefaultInfoPanel from '../../../components/DefaultInfoPanel';

// Constants
import { AlertTypes, DEVICE_TYPES, DeviceTypes, GREY, FilterOptions } from '../../../constants';
import HubListCard from './HubListCard';
import HubInfoPanel from '../../../components/HubInfoPanel';
import FilterWidget from '../../../components/FilterWidget';

class ListView extends Component {
  constructor(props) {
    super(props);

    var state = {
      tabValue: 0,
      selectedCardObject: null,
      staff: null,
      rooms: null,
      hubs: null
    };

    this.state = state;
  }

  componentDidMount() {
    this.handleUpdateFromDatabase(this.state.tabValue);

    // Update whichever panel the user is viewing every 5 seconds.
    this.timer = setInterval(() => {
      this.handleUpdateFromDatabase(this.state.tabValue);
    }, 5000);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.schoolAlertStatus !== this.props.schoolAlertStatus) {
      this.handleUpdateFromDatabase(this.state.tabValue);
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = null;
  }

  handleTabValueChange = (event, newValue) => {
    if (newValue !== this.state.tabValue) {
      // Update which tab is open and clear old data
      this.setState({
        tabValue: newValue,
        selectedCardObject: null,
        staff: null,
        rooms: null,
        hubs: null,
        filteredStaff: null,
        filteredRooms: null,
        filteredHubs: null
      });

      // Retrieve the appropriate data from the db
      this.handleUpdateFromDatabase(newValue);
    }
  };

  handleUpdateFromDatabase = (tabValue) => {
    switch (DEVICE_TYPES[tabValue]) {
      case DeviceTypes.STAFF:
        listStaff().then(staff => {
          this.setState({ staff: staff });
          if (this.state.selectedCardObject) {
            this.setState({ selectedCardObject: staff.find(staffMember => staffMember.getID() === this.state.selectedCardObject.getID()) || null });
          }
        }).catch(error => {
          this.props.setError(error ? error : 'Could not retrieve the staff list.');
          this.setState({ staff: [], selectedCardObject: null });
        });
        break;
      case DeviceTypes.ROOMS:
        listRooms().then(rooms => {
          this.setState({ rooms: rooms });
          if (this.state.selectedCardObject) {
            this.setState({ selectedCardObject: rooms.find(room => room.getID() === this.state.selectedCardObject.getID()) || null });
          }
        }).catch(error => {
          this.props.setError(error ? error : 'Could not retrieve the room list.');
          this.setState({ rooms: [], selectedCardObject: null });
        });
        break;
      case DeviceTypes.HUBS:
        listHubs().then(hubs => {
          this.setState({ hubs: hubs });
          if (this.state.selectedCardObject) {
            this.setState({ selectedCardObject: hubs.find(hub => hub.getSerialNumber() === this.state.selectedCardObject.getSerialNumber()) || null });
          }
        }).catch(error => {
          this.props.setError(error ? error : 'Could not retrieve the hub list.');
          this.setState({ hubs: [], selectedCardObject: null });
        });
        break;
      default:
    }
  }

  handleCardSelection = (object) => {
    this.setState({ selectedCardObject: object });
  }

  handleSetRoomStatus = (newStatus) => {
    setRoomStatus(this.state.selectedCardObject.getID(), newStatus).then(() => {
      this.state.selectedCardObject.setAlertStatus(newStatus);
      this.setState({ selectedCardObject: this.state.selectedCardObject });
    }).catch(error => {
      this.props.setError(error ? error : 'Could not set the room status.');
    });
  }

  getCards = () => {
    if ((DEVICE_TYPES[this.state.tabValue] === DeviceTypes.STAFF && !this.state.staff) || (DEVICE_TYPES[this.state.tabValue] === DeviceTypes.ROOMS && !this.state.rooms) || (DEVICE_TYPES[this.state.tabValue] === DeviceTypes.HUBS && !this.state.hubs)) {
      return <Typography variant='body1' style={{ color: 'white' }}>Loading...</Typography>;
    }
    else if ((DEVICE_TYPES[this.state.tabValue] === DeviceTypes.STAFF && this.state.staff.length === 0) || (DEVICE_TYPES[this.state.tabValue] === DeviceTypes.ROOMS && this.state.rooms.length === 0) || (DEVICE_TYPES[this.state.tabValue] === DeviceTypes.HUBS && this.state.hubs.length === 0)) {
      return <Typography variant='body1' style={{ color: 'white' }}>Nothing to show.</Typography>;
    }

    // Apply a search filter to our cards. Case insensitive.
    var regex = new RegExp(this.state.searchCriteria, 'i');

    switch (DEVICE_TYPES[this.state.tabValue]) {
      case DeviceTypes.STAFF:
        return this.state.filteredStaff && this.state.filteredStaff.filter(staffMember => {
          return regex.test(staffMember.getFirstName()) || regex.test(staffMember.getLastName()) || regex.test(staffMember.getEmail());
        }).map((staffMember, index) => (
          <StaffMemberListCard key={index} staffMember={staffMember} onClick={() => this.handleCardSelection(staffMember)} selectedCardObject={this.state.selectedCardObject} isFullWidth={this.props.isFullWidth} />
        ));
      case DeviceTypes.ROOMS:
        return this.state.filteredRooms && this.state.filteredRooms.filter(room => {
          return regex.test(room.getIdentifier());
        }).map((room, index) => (
          <RoomListCard key={index} room={room} onClick={() => this.handleCardSelection(room)} selectedCardObject={this.state.selectedCardObject} isFullWidth={this.props.isFullWidth} />
        ));
      case DeviceTypes.HUBS:
        return this.state.filteredHubs && this.state.filteredHubs.filter(hub => {
          return regex.test(hub.getSerialNumber());
        }).map((hub, index) => (
          <HubListCard key={index} hub={hub} onClick={() => this.handleCardSelection(hub)} selectedCardObject={this.state.selectedCardObject} isFullWidth={this.props.isFullWidth} />
        ));
      default:
        return;
    }
  }

  onSearchChange = (e) => {
    var searchCriteria = e.target.value;

    this.setState({
      searchCriteria: searchCriteria
    });
  }

  getActiveData = () => {
    switch (DEVICE_TYPES[this.state.tabValue]) {
      case DeviceTypes.STAFF:
        return this.state.staff ?? [];
      case DeviceTypes.ROOMS:
        return this.state.rooms ?? [];
      case DeviceTypes.HUBS:
        return this.state.hubs ?? [];
      default:
        return [];
    }
  }

  setFilteredData = (data) => {
    switch (DEVICE_TYPES[this.state.tabValue]) {
      case DeviceTypes.STAFF:
        this.setState({
          filteredStaff: data
        });
        break;
      case DeviceTypes.ROOMS:
        this.setState({
          filteredRooms: data
        });
        break;
      case DeviceTypes.HUBS:
        this.setState({
          filteredHubs: data
        });
        break;
      default:
        return null;
    }
  }

  getFilterOptions = () => {
    switch (DEVICE_TYPES[this.state.tabValue]) {
      case DeviceTypes.STAFF:
        return FilterOptions.STAFF_LIST;
      case DeviceTypes.ROOMS:
        return FilterOptions.ROOM_LIST;
      case DeviceTypes.HUBS:
        return FilterOptions.HUB_LIST;
      default:
        return [];
    }
  }

  render() {
    return (
      <Grid container
        direction="row"
        justify="space-evenly"
        style={this.props.style}>
        <Grid item xs={12} md={4}>
          {!this.state.selectedCardObject && !this.props.isMobile &&
            <DefaultInfoPanel />
          }
          {DEVICE_TYPES[this.state.tabValue] === DeviceTypes.STAFF && this.state.selectedCardObject &&
            <StaffMemberInfoPanel staffMember={this.state.selectedCardObject} isMobile={this.props.isMobile} handleClose={() => this.setState({ selectedCardObject: null })} />
          }
          {DEVICE_TYPES[this.state.tabValue] === DeviceTypes.ROOMS && this.state.selectedCardObject &&
            <RoomInfoPanel room={this.state.selectedCardObject} onSetStatus={this.handleSetRoomStatus} isMobile={this.props.isMobile} isFullWidth={this.props.isFullWidth} handleClose={() => this.setState({ selectedCardObject: null })} setupMode={this.props.setupMode} />
          }
          {DEVICE_TYPES[this.state.tabValue] === DeviceTypes.HUBS && this.state.selectedCardObject &&
            <HubInfoPanel hub={this.state.selectedCardObject} isMobile={this.props.isMobile} handleClose={() => this.setState({ selectedCardObject: null })} />
          }
        </Grid>
        <Grid item xs={12} md={8}>
          <Card style={{ backgroundColor: GREY.dark, margin: (this.props.isMobile? '0px 10px 0px 10px' : '0px 10px 0px 0px') }}>
            <AppBar position='static' style={{ backgroundColor: GREY.dark, color: GREY.text }}>
              <Tabs value={this.state.tabValue} onChange={this.handleTabValueChange} variant='fullWidth' TabIndicatorProps={{ style: { background: this.props.schoolAlertStatus.color.main } }}>
                <Tab label={DEVICE_TYPES[0]} selectionFollowsFocus />
                <Tab label={DEVICE_TYPES[1]} selectionFollowsFocus />
                <Tab label={DEVICE_TYPES[2]} selectionFollowsFocus />
              </Tabs>
            </AppBar>

            <CardContent style={{ height: this.props.panelHeight, overflow: 'auto' }}>

              <Grid container direction='row' style={{ alignItems: 'center', marginBottom: '15px' }}>

                <FilterWidget
                  statusColor={this.props.schoolAlertStatus.color}
                  filterOptions={this.getFilterOptions()}
                  data={this.getActiveData()}
                  updateFilteredData={(filteredData) => this.setFilteredData(filteredData)}
                />
                <Fab variant="extended" style={{ backgroundColor: this.props.schoolAlertStatus.color.main, flex: 1, marginLeft: '10px' }}>
                  <InputBase
                    style={{ marginLeft: '10px', flex: 1 }}
                    placeholder="Search"
                    inputProps={{ 'aria-label': 'search list' }}
                    onChange={this.onSearchChange}
                  />
                  <SearchIcon />
                </Fab>

                <Fab style={{ marginLeft: '10px' }} onClick={() => this.handleUpdateFromDatabase(this.state.tabValue)}>
                  <RefreshIcon />
                </Fab>
              </Grid>

              <Typography variant='h1' style={{ textAlign: 'center' }}>
                {this.getCards()}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  schoolAlertStatus: state.status.schoolStatus || AlertTypes.UNKNOWN,
  panelHeight: state.ui.windowSize.height - 220,
  isMobile: state.ui.isMobile,
  isFullWidth: state.ui.isFullWidth,
  setupMode: state.setupMode.mode || state.setupMode.isLoading
});

const mapDispatchToProps = (dispatch) => ({
  setError: (msg) => setAlert(dispatch, msg)
});

export default connect(mapStateToProps, mapDispatchToProps)(ListView);

ListView.propTypes = {
  schoolAlertStatus: PropTypes.object,
  setError: PropTypes.func,
  isFullWidth: PropTypes.bool,
  style: PropTypes.object,
  panelHeight: PropTypes.number,
  isMobile: PropTypes.bool,
  setupMode: PropTypes.bool.isRequired
};

import React, { Component } from 'react';
import {
  AppBar,
  Tabs,
  Tab
} from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// Constants
import { GREY, GREY_SCALE } from '../constants';
const MAP_VIEW = {
  route: '/dashboard/map',
  title: 'Map View'
};
const LIST_VIEW = {
  route: '/dashboard/list',
  title: 'List View'
};

class DashboardAppBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabValue: MAP_VIEW.route
    };
  }

  componentDidMount() {
    this.updateSelectedTab();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location?.pathname !== this.props.location?.pathname) {
      this.updateSelectedTab();
    }
  }

  updateSelectedTab = () => {
    let pathname = this.props.location?.pathname;
    if (pathname.includes(LIST_VIEW.route)) this.setState({ tabValue: LIST_VIEW.route });
    else this.setState({ tabValue: MAP_VIEW.route });
  }

  handleChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  render() {
    return (
      <div>
        <AppBar position='static' style={{ backgroundColor: GREY_SCALE[3], color: GREY.dark, marginBottom: '10px' }}>
          <Tabs value={this.state.tabValue} onChange={this.handleChange} variant='fullWidth' TabIndicatorProps={{ style: { background: GREY.dark } }}>
            <Tab label={MAP_VIEW.title} value={MAP_VIEW.route} component={Link} to='/dashboard/map' selectionFollowsFocus />
            <Tab label={LIST_VIEW.title} value={LIST_VIEW.route} component={Link} to='/dashboard/list' selectionFollowsFocus />
          </Tabs>
        </AppBar>
      </div>
    );
  }
}

export default withRouter(DashboardAppBar);

DashboardAppBar.propTypes = {
  location: PropTypes.object
};

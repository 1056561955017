import {
  IS_AUTH_LOADING,
  IS_AUTH_NOT_LOADING,
  INITIAL_LOGIN_SUCCESS,
  NOT_AUTHENTICATED,
  AUTHENTICATED_SUCCESS,
  NOT_TWO_FACTORED_AUTHENTICATED,
  PASSWORD_UPDATED
} from '../actions/authActions';
import { UserTypes, isNull } from '../../constants';

const initialState = {
  isLoading: true,
  isAuthenticated: false,
  userID: null,
  userType: null,
  passwordResetRequired: null,
  multiFactorSetupRequired: null
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case IS_AUTH_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case IS_AUTH_NOT_LOADING:
      return {
        ...state,
        isLoading: false
      };
    case NOT_TWO_FACTORED_AUTHENTICATED:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false
      };
    case NOT_AUTHENTICATED:
      return {
        isLoading: false,
        isAuthenticated: false,
        userID: null,
        userType: null,
        passwordResetRequired: null,
        multiFactorSetupRequired: null
      };
    case INITIAL_LOGIN_SUCCESS:
      return {
        isLoading: false,
        isAuthenticated: false,
        userID: action.userID,
        userType: Object.values(UserTypes).find(userType => action.userType === userType.name) || null,
        passwordResetRequired: action.passwordResetRequired,
        multiFactorSetupRequired: action.multiFactorSetupRequired
      };
    case AUTHENTICATED_SUCCESS:
      return {
        isLoading: false,
        isAuthenticated: true,
        userID: isNull(action.userID) ? state.userID : action.userID,
        userType: action.userType || state.userType,
        passwordResetRequired: isNull(action.passwordResetRequired) ? state.passwordResetRequired : action.passwordResetRequired,
        multiFactorSetupRequired: false
      };
    case PASSWORD_UPDATED:
      return {
        ...state,
        isLoading: false,
        passwordResetRequired: false
      };
    default:
      return state;
  }
};

export default user;

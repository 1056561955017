import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Circle } from 'react-konva';

// Redux
import { connect } from 'react-redux';

// Components
import MapPanel from '../../components/MapPanel';

// Constants
import { DEFAULT } from '../../constants';

class MapHubLocationSelector extends Component {
  render() {
    return (
      <MapPanel floorPlanImagePath={this.props.floorPlanImagePath} onClick={this.props.onCoordinateSelection} statusColor={DEFAULT}>
        {this.props.x != null && this.props.y != null && <Circle x={this.props.x} y={this.props.y} radius={30} fill={this.props.statusColor.main} />}
      </MapPanel>
    );
  }
}

const mapStateToProps = state => ({
  windowSize: state.ui.windowSize,
  statusColor: state.status.schoolStatus ? state.status.schoolStatus.getColor() : DEFAULT
});

export default connect(mapStateToProps, null)(MapHubLocationSelector);

MapHubLocationSelector.propTypes = {
  floorPlanImagePath: PropTypes.string,
  onCoordinateSelection: PropTypes.func,
  x: PropTypes.number,
  y: PropTypes.number,
  statusColor: PropTypes.shape({
    main: PropTypes.string
  })
};

import { AlertTypeObjs, DEFAULT } from '../constants';

export default class AlertType {
  static thaw(alertTypeObj) {
    if (alertTypeObj == null) return null;
    const alertType = new AlertType();
    if (alertTypeObj.id != null) alertType.setID(alertTypeObj.id);
    if (alertTypeObj.hardwareStatus != null) alertType.setHardwareStatus(alertTypeObj.hardwareStatus);
    if (alertTypeObj.dashboardStatus != null) alertType.setDashboardStatus(alertTypeObj.dashboardStatus);
    if (alertTypeObj.shouldAlertSchool != null) alertType.setShouldAlertSchool(alertTypeObj.shouldAlertSchool);
    alertType.setColor(alertTypeObj.color);
    return alertType;
  }

  static thawList(alertTypeObjList) {
    if (alertTypeObjList == null) return [];
    var alertTypes = [];
    for (var i = 0; i < alertTypeObjList.length; i++) {
      alertTypes.push(AlertType.thaw(alertTypeObjList[i]));
    }
    return alertTypes;
  }

  static freeze(alertType) {
    if (alertType == null) return null;
    var alertTypeObj = {};
    if (alertType.getID() != null) alertTypeObj.id = alertType.getID();
    if (alertType.getHardwareStatus() != null) alertTypeObj.hardwareStatus = alertType.getHardwareStatus();
    if (alertType.getDashboardStatus() != null) alertTypeObj.dashboardStatus = alertType.getDashboardStatus();
    if (alertType.getShouldAlertSchool() != null) alertTypeObj.shouldAlertSchool = alertType.getShouldAlertSchool();
    if (alertType.getColor() != null) alertTypeObj.color = alertType.getColor();
    return alertTypeObj;
  }

  static freezeList(alertTypes) {
    if (alertTypes == null) return [];
    var alertTypeObjList = [];
    for (var i = 0; i < alertTypes.length; i++) {
      alertTypeObjList.push(AlertType.freeze(alertTypes[i]));
    }
    return alertTypeObjList;
  }

  constructor() {
    this.setID(null);
    this.setHardwareStatus(null);
    this.setDashboardStatus(null);
    this.setShouldAlertSchool(null);
    this.setColor(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setHardwareStatus(hardwareStatus) {
    this.hardwareStatus = hardwareStatus;
  }

  setDashboardStatus(dashboardStatus) {
    this.dashboardStatus = dashboardStatus;
  }

  setShouldAlertSchool(shouldAlertSchool) {
    this.shouldAlertSchool = shouldAlertSchool;
  }

  setColor(color) {
    if (color == null) {
      color = Object.values(AlertTypeObjs).find(alertType => alertType.dashboardStatus === this.dashboardStatus)?.color;
    }
    this.color = color;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getHardwareStatus() {
    return this.hardwareStatus;
  }

  getDashboardStatus() {
    return this.dashboardStatus;
  }

  getShouldAlertSchool() {
    return this.shouldAlertSchool;
  }

  getColor() {
    if (this.color == null) {
      this.color = Object.values(AlertTypeObjs).find(alertType => alertType.dashboardStatus === this.dashboardStatus)?.color;
    }
    return this.color || DEFAULT;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

}

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Container,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { setSuccess, clearErrors, setAlert } from '../../../../redux/actions/alertActions';

// Components
import BuildingForm from '../../../../components/BuildingForm';

// Constants
import { DEFAULT } from '../../../../constants';

class CreateBuilding extends Component {

  constructor(props) {
    super(props);

    this.state = {
      redirectToBuildings: false
    };
  }

  render() {
    return this.state.redirectToBuildings || !this.props.setupMode ? <Redirect to='/admin/buildings' /> : (
      <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant='h1'>Add a Building</Typography>
        <BuildingForm onSuccess={() => this.setState({ redirectToBuildings: true })} />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  statusColor: state.status.schoolStatus ? state.status.schoolStatus.getColor() : DEFAULT,
  isMobile: state.ui.isMobile,
  isFullWidth: state.ui.isFullWidth,
  setupMode: state.setupMode.mode || state.setupMode.isLoading
});

const mapDispatchToProps = (dispatch) => ({
  setError: (msg) => {
    dispatch(clearErrors());
    setAlert(dispatch, msg);
  },
  setSuccess: (msg) => setSuccess(dispatch, msg),
  clearErrors: () => dispatch(clearErrors())
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateBuilding);

CreateBuilding.propTypes = {
  clearErrors: PropTypes.func,
  setSuccess: PropTypes.func,
  setError: PropTypes.func,
  isMobile: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  statusColor: PropTypes.object,
  setupMode: PropTypes.bool.isRequired
};

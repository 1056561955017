import { ADD_ALERT, REMOVE_ALERT, REMOVE_ALERT_FROM_MESSAGE, CLEAR_ERRORS, CLEAR_ALERTS, AlertSeverity } from '../actions/alertActions';

const initialState = [];

const alerts = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ALERT:
      var { id, msg, severity, showClose } = action;
      // Prevent more than 5 alerts to appear simultaneously or have duplicate errors
      return state.length < 5 && state.filter(alert => alert.msg === msg).length === 0 ? [...state, { id, msg, severity, showClose }] : state;
    case REMOVE_ALERT:
      return state.filter(alert => alert.id !== action.id);
    case REMOVE_ALERT_FROM_MESSAGE:
      return state.filter(alert => alert.msg !== action.msg);
    case CLEAR_ERRORS:
      return state.filter(alert => alert.severity !== AlertSeverity.ERROR);
    case CLEAR_ALERTS:
      return initialState;
    default:
      return state;
  }
};

export default alerts;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { CircularProgress, Container, Typography } from '@material-ui/core';

// Redux
import { connect } from 'react-redux';
import { setSuccess, clearErrors, setAlert } from '../../../../redux/actions/alertActions';

// API
import { getRoom } from '../../../../api';

// Components
import RoomForm from '../../../../components/RoomForm';

class Room extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectToRooms: false,
      room: null,
      isLoading: false
    };
  }

  componentDidMount() {
    const roomID = this.props.match?.params?.id;

    this.setState({ isLoading: true });
    getRoom(roomID).then(room => {
      this.setState({ room: room, isLoading: false });
    }).catch(error => {
      this.setState({ isLoading: false, redirectToRooms: true });
      this.props.setError(error ? error : 'Error: Unable to retrieve room.');
    });
  }

  render() {
    return this.state.redirectToRooms || !this.props.setupMode ? <Redirect to='/admin/rooms' /> : (
      <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
          <Typography variant='h1'>Update Room</Typography>
          {this.state.isLoading && <CircularProgress style={{ color: this.props.statusColor.main }} />}
        </div>

        {this.state.room != null &&
        <RoomForm onSuccess={() => this.setState({ redirectToRooms: true })} room={this.state.room} />}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  statusColor: state.status.schoolStatus.getColor(),
  setupMode: state.setupMode.mode || state.setupMode.isLoading
});

const mapDispatchToProps = dispatch => ({
  setError: (msg) => setAlert(dispatch, msg),
  setSuccess: (msg) => setSuccess(dispatch, msg),
  clearErrors: () => dispatch(clearErrors())
});

Room.propTypes = {
  setError: PropTypes.func,
  setSuccess: PropTypes.func,
  setupMode: PropTypes.bool.isRequired,
  clearErrors: PropTypes.func,
  match: PropTypes.object.isRequired,
  statusColor: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Room));

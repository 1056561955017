import { DateTime } from 'luxon';
import { AlertUpdate } from './';
import { UserTypes } from '../constants';

export default class User {
  static thaw(userObj) {
    if (userObj == null) return null;
    const user = new User();
    if (userObj.id != null) user.setID(userObj.id);
    if (userObj.firstName != null) user.setFirstName(userObj.firstName);
    if (userObj.lastName != null) user.setLastName(userObj.lastName);
    if (userObj.email != null) user.setEmail(userObj.email);
    if (userObj.phoneNumber != null) user.setPhoneNumber(userObj.phoneNumber);
    if (userObj.hash != null) user.setHash(userObj.hash);
    if (userObj.userType != null) user.setUserType(Object.values(UserTypes).find(userType => userObj.userType === userType.name) || null);
    if (userObj.registerTimestamp != null) user.setRegisterTimestamp(DateTime.fromISO(userObj.registerTimestamp));
    if (userObj.passwordResetRequired != null) user.setPasswordResetRequired(userObj.passwordResetRequired == null ? null : (userObj.passwordResetRequired ? true : false));
    if (userObj.lastUpdatedPasswordTimestamp != null) user.setLastUpdatedPasswordTimestamp(userObj.lastUpdatedPasswordTimestamp);
    if (userObj.multiFactorSecret != null) user.setMultiFactorSecret(userObj.multiFactorSecret);
    if (userObj.multiFactorSetupRequired != null) user.setMultiFactorSetupRequired(userObj.multiFactorSetupRequired == null ? null : (userObj.multiFactorSetupRequired ? true : false));
    if (userObj.mobileAccess != null) user.setMobileAccess(userObj.mobileAccess == null ? null : (userObj.mobileAccess ? true : false));
    if (userObj.isActive != null) user.setIsActive(userObj.isActive == null ? null : (userObj.isActive ? true : false));
    if (userObj.alertUpdates != null) user.setAlertUpdates(AlertUpdate.thawList(userObj.alertUpdates));
    return user;
  }

  static thawList(userObjList) {
    if (userObjList == null) return [];
    var users = [];
    for (var i = 0; i < userObjList.length; i++) {
      users.push(User.thaw(userObjList[i]));
    }
    return users;
  }

  static freeze(user) {
    if (user == null) return null;
    var userObj = {};
    if (user.getID() != null) userObj.id = user.getID();
    if (user.getFirstName() != null) userObj.firstName = user.getFirstName();
    if (user.getLastName() != null) userObj.lastName = user.getLastName();
    if (user.getEmail() != null) userObj.email = user.getEmail();
    if (user.getPhoneNumber() != null) userObj.phoneNumber = user.getPhoneNumber();
    if (user.getHash() != null) userObj.hash = user.getHash();
    if (user.getUserType() != null) userObj.userType = user.getUserType();
    if (user.getRegisterTimestamp() != null) userObj.registerTimestamp = DateTime.fromISO(user.getRegisterTimestamp());
    if (user.getPasswordResetRequired() != null) userObj.passwordResetRequired = user.getPasswordResetRequired() == null ? null : (user.getPasswordResetRequired() ? 1 : 0);
    if (user.getLastUpdatedPasswordTimestamp() != null) userObj.lastUpdatedPasswordTimestamp = user.getLastUpdatedPasswordTimestamp();
    if (user.getMultiFactorSecret() != null) userObj.multiFactorSecret = user.getMultiFactorSecret();
    if (user.getMultiFactorSetupRequired() != null) userObj.multiFactorSetupRequired = user.getMultiFactorSetupRequired() == null ? null : (user.getMultiFactorSetupRequired() ? 1 : 0);
    if (user.getMobileAccess() != null) userObj.mobileAccess = user.getMobileAccess() == null ? null : (user.getMobileAccess() ? 1 : 0);
    if (user.getIsActive() != null) userObj.isActive = user.getIsActive() == null ? null : (user.getIsActive() ? 1 : 0);
    if (user.getAlertUpdates() != null) userObj.alertUpdates = AlertUpdate.freezeList(user.getAlertUpdates());
    return userObj;
  }

  static freezeList(users) {
    if (users == null) return [];
    var userObjList = [];
    for (var i = 0; i < users.length; i++) {
      userObjList.push(User.freeze(users[i]));
    }
    return userObjList;
  }

  constructor() {
    this.setID(null);
    this.setFirstName(null);
    this.setLastName(null);
    this.setEmail(null);
    this.setPhoneNumber(null);
    this.setHash(null);
    this.setUserType(null);
    this.setRegisterTimestamp(null);
    this.setPasswordResetRequired(null);
    this.setLastUpdatedPasswordTimestamp(null);
    this.setMultiFactorSecret(null);
    this.setMultiFactorSetupRequired(null);
    this.setMobileAccess(null);
    this.setIsActive(null);
    this.setAlertUpdates(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setFirstName(firstName) {
    this.firstName = firstName;
  }

  setLastName(lastName) {
    this.lastName = lastName;
  }

  setEmail(email) {
    this.email = email;
  }

  setPhoneNumber(phoneNumber) {
    this.phoneNumber = phoneNumber;
  }

  setHash(hash) {
    this.hash = hash;
  }

  setUserType(userType) {
    this.userType = userType;
  }

  setRegisterTimestamp(registerTimestamp) {
    this.registerTimestamp = registerTimestamp;
  }

  setPasswordResetRequired(passwordResetRequired) {
    this.passwordResetRequired = passwordResetRequired;
  }

  setLastUpdatedPasswordTimestamp(lastUpdatedPasswordTimestamp) {
    this.lastUpdatedPasswordTimestamp = lastUpdatedPasswordTimestamp;
  }

  setMultiFactorSecret(multiFactorSecret) {
    this.multiFactorSecret = multiFactorSecret;
  }

  setMultiFactorSetupRequired(multiFactorSetupRequired) {
    this.multiFactorSetupRequired = multiFactorSetupRequired;
  }

  setMobileAccess(mobileAccess) {
    this.mobileAccess = mobileAccess;
  }

  setIsActive(isActive) {
    this.isActive = isActive;
  }

  setAlertUpdates(alertUpdates) {
    this.alertUpdates = alertUpdates;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getFirstName() {
    return this.firstName;
  }

  getLastName() {
    return this.lastName;
  }

  getEmail() {
    return this.email;
  }

  getPhoneNumber() {
    return this.phoneNumber;
  }

  getHash() {
    return this.hash;
  }

  getUserType() {
    return this.userType;
  }

  getRegisterTimestamp() {
    return this.registerTimestamp;
  }

  getPasswordResetRequired() {
    return this.passwordResetRequired;
  }

  getLastUpdatedPasswordTimestamp() {
    return this.lastUpdatedPasswordTimestamp;
  }

  getMultiFactorSecret() {
    return this.multiFactorSecret;
  }

  getMultiFactorSetupRequired() {
    return this.multiFactorSetupRequired;
  }

  getMobileAccess() {
    return this.mobileAccess;
  }

  getIsActive() {
    return this.isActive;
  }

  getAlertUpdates() {
    return this.alertUpdates;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */
  getName() {
    return this.firstName + ' ' + this.lastName;
  }
}

import { combineReducers } from 'redux';

// Reducers
import statusReducer from './statusReducer';
import alertReducer from './alertReducer';
import announcementReducer from './announcementReducer';
import uiReducer from './uiReducer';
import authReducer from './authReducer';
import setupModeReducer from './setupModeReducer';

export default combineReducers({
  status: statusReducer,
  announcements: announcementReducer,
  alerts: alertReducer,
  ui: uiReducer,
  auth: authReducer,
  setupMode: setupModeReducer
});

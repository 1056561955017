import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';

// Constants
import { DEFAULT, GREY } from '../constants';

function ConfirmationModal({ open, onClose, onConfirm, color = DEFAULT, title = null, body = null, isLoading = false }) {

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick
    >
      {title &&
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}

      {body &&
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {body}
        </DialogContentText>
      </DialogContent>}

      <DialogActions>
        <Button onClick={onClose} disabled={isLoading} style={{ backgroundColor: GREY.main, color: GREY.text }}>
          Cancel
        </Button>
        <Button onClick={onConfirm} autoFocus disabled={isLoading} style={{ backgroundColor: color.main, color: color.text }}>
          Confirm
          {isLoading &&
          <CircularProgress size={15} style={{ color: color.text, marginLeft: '10px' }} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationModal;

ConfirmationModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  color: PropTypes.object,
  title: PropTypes.string,
  body: PropTypes.any,
  isLoading: PropTypes.bool
};
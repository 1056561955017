import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

// Components
import ListCard from '../../../components/ListCard';
import MultiHubStatusWidget from '../../../components/MultiHubStatusWidget';

export default function RoomListCard({ room, onClick, selectedCardObject, isFullWidth }) {
  return (
    <ListCard statusColor={room.getAlertStatus().color.main} onClick={onClick} isSelected={selectedCardObject === room}>
      <Grid container direction={isFullWidth ? 'row' : 'column'}>
        {/* Content */}
        <Grid lg item style={{ display: 'flex', alignItems: 'center', paddingLeft: (isFullWidth ? '20px' : '0px') }}>
          <Grid container alignItems={isFullWidth ? 'flex-start' : 'center'} direction='column'>
            <Grid item style={{ textAlign: 'left' }}><Typography variant='h4'>{room.getIdentifier()}</Typography></Grid>
            <Grid item style={{ textAlign: 'left' }}><Typography variant='h6' style={{ color: room.getAlertStatus().color.main, textTransform: 'uppercase' }}>{room.getAlertStatus()?.getDashboardStatus() ?? 'Unknown'}</Typography></Grid>
          </Grid>
        </Grid>

        {/* Device Status */}
        <Grid lg={3} item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: (isFullWidth ? '0px' : '30px') }}>
          {room.getHubs() && room.getHubs().length >= 1 &&
          <MultiHubStatusWidget hubs={room.getHubs()} />}
        </Grid>
      </Grid>
    </ListCard>
  );
}

RoomListCard.propTypes = {
  room: PropTypes.object,
  onClick: PropTypes.func,
  selectedCardObject: PropTypes.object,
  isFullWidth: PropTypes.bool
};

import React from 'react';
import { Card, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

// Constants
import { GREY_SCALE } from '../constants';

export default function ListCard({ style = {}, statusColor, onClick = () => console.log('Clicked'), children, isSelected, selectedColor = 'white' }) {

  return (
    <Card onClick={onClick} border={5} style={{ ...style, backgroundColor: GREY_SCALE[3], margin: '5px', padding: '20px 10px 20px 10px', cursor: 'pointer', border: isSelected ? ('5px solid ' + selectedColor) : 'none' }}>
      <Grid container direction='row' justify="space-between">
        {/* Status Bar */}
        <Grid item>
          <div style={{ height: '100%', width: '6px', borderRadius: '3px', backgroundColor: statusColor }}></div>
        </Grid>

        {/* Content */}
        <Grid lg item>
          {children}
        </Grid>

        {/* Status Bar */}
        <Grid item>
          <div style={{ height: '100%', width: '6px', borderRadius: '3px', backgroundColor: statusColor }}></div>
        </Grid>
      </Grid>
    </Card>
  );
}

ListCard.propTypes = {
  style: PropTypes.object,
  statusColor: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.object,
  isSelected: PropTypes.bool,
  selectedColor: PropTypes.string
};

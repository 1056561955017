import React, { Component } from 'react';
import {
  Container, Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Components
import RoomForm from '../../../../components/RoomForm';

// Constants
import { DEFAULT } from '../../../../constants';

class CreateRoom extends Component {

  constructor(props) {
    super(props);

    this.state = {
      redirectToRooms: false
    };
  }

  render() {
    return this.state.redirectToRooms || !this.props.setupMode ? <Redirect to='/admin/rooms' /> : (
      <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant='h1'>Create a Room</Typography>
        <RoomForm onSuccess={() => this.setState({ redirectToRooms: true })} />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  statusColor: state.status.schoolStatus ? state.status.schoolStatus.getColor() : DEFAULT,
  isMobile: state.ui.isMobile,
  isFullWidth: state.ui.isFullWidth,
  setupMode: state.setupMode.mode || state.setupMode.isLoading
});

export default connect(mapStateToProps, null)(CreateRoom);

CreateRoom.propTypes = {
  clearErrors: PropTypes.func,
  statusColor: PropTypes.object,
  isMobile: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  setupMode: PropTypes.bool.isRequired
};

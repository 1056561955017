import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { setSuccess, setAlert } from '../../../redux/actions/alertActions';

// Components
import UserListPanel from './UserListPanel';
import UserInfoDialog from '../../../components/UserInfoDialog';
import { Button, TextField } from '@material-ui/core';

// Constants
import { AlertTypes, isNotNull } from '../../../constants';
import { toggleUserActivity, listUsers, changeUserPassword, toggleMobileAccess, resetUserPassword, resetUser2fa } from '../../../api';
import ConfirmationModal from '../../../components/ConfirmationModal';

class ListUsers extends Component {
  constructor(props) {
    super(props);

    var state = {
      selectedCardObject: null,
      users: null,
      isLoading: false,
      showFilter: false,
      showActivityModal: false,
      showResetPasswordModal: false,
      showChangePasswordModal: false,
      showReset2faModal: false,
      showMobileAccessModal: false
    };

    this.state = state;

    this.toggleActivityModal = this.toggleActivityModal.bind(this);
    this.toggleChangePasswordModal = this.toggleChangePasswordModal.bind(this);
    this.toggleMobileAccessModal = this.toggleMobileAccessModal.bind(this);
    this.toggleResetPasswordModal = this.toggleResetPasswordModal.bind(this);
    this.toggleReset2faModal = this.toggleReset2faModal.bind(this);

    this.confirmToggleUserActivity = this.confirmToggleUserActivity.bind(this);
    this.confirmChangePassword = this.confirmChangePassword.bind(this);
    this.confirmToggleMobileAccess = this.confirmToggleMobileAccess.bind(this);
    this.confirmResetPassword = this.confirmResetPassword.bind(this);
    this.confirmReset2fa = this.confirmReset2fa.bind(this);
  }

  componentDidMount() {
    this.loadUsers();
  }

  loadUsers() {
    listUsers().then((data) => {
      this.setState({
        users: data
      });
    }).catch(error => {
      this.props.setError(error ? error : 'Unable to retrieve users.');
    });
  }

  handleCardSelection = (object) => {
    this.setState({ selectedCardObject: object });
  }

  toggleActivityModal() {
    this.setState({
      showActivityModal: !this.state.showActivityModal
    });
  }

  toggleChangePasswordModal() {
    this.setState({
      showChangePasswordModal: !this.state.showChangePasswordModal
    });
  }

  toggleMobileAccessModal() {
    this.setState({
      showMobileAccessModal: !this.state.showMobileAccessModal
    });
  }

  toggleResetPasswordModal() {
    this.setState({
      showResetPasswordModal: !this.state.showResetPasswordModal
    });
  }

  toggleReset2faModal() {
    this.setState({
      showReset2faModal: !this.state.showReset2faModal
    });
  }

  confirmReset2fa() {
    this.setState({
      isLoading: true
    });

    resetUser2fa(this.state.selectedCardObject.getID()).then(() => {
      this.setState({
        isLoading: false,
        selectedCardObject: null,
        showReset2faModal: false
      });

      this.props.setSuccess('Successfully reset user 2FA.');
    })
      .catch(error => {
        this.setState({
          isLoading: false,
          selectedCardObject: null,
          showReset2faModal: false
        });

        this.props.setError(error ? error : 'Error resetting user 2FA.');
      });
  }

  confirmResetPassword() {
    this.setState({
      isLoading: true
    });

    resetUserPassword(this.state.selectedCardObject.getID()).then(() => {
      this.setState({
        isLoading: false,
        selectedCardObject: null,
        showResetPasswordModal: false
      });

      this.props.setSuccess('Successfully reset user password.');
    })
      .catch(error => {
        this.setState({
          isLoading: false,
          selectedCardObject: null,
          showResetPasswordModal: false
        });

        this.props.setError(error ? error : 'Error resetting user password.');
      });
  }

  confirmChangePassword() {
    this.setState({
      isLoading: true
    });

    changeUserPassword(this.state.selectedCardObject.getID(), this.state.newPassword).then(() => {
      this.setState({
        isLoading: false,
        selectedCardObject: null,
        showChangePasswordModal: false
      });

      this.props.setSuccess('Successfully changed user password.');
    })
      .catch(error => {
        this.setState({
          isLoading: false,
          selectedCardObject: null,
          showChangePasswordModal: false
        });

        this.props.setError(error ? error : 'Error changing user password.');
      });
  }

  confirmToggleUserActivity() {
    this.setState({
      isLoading: true
    });

    this.toggleActivityModal();

    toggleUserActivity(this.state.selectedCardObject.getID()).then(() => {
      this.setState({
        isLoading: false,
        selectedCardObject: null,
        showActivityModal: false
      });

      this.loadUsers();

      this.props.setSuccess('Successfully toggled user active status.');
    })
      .catch(error => {
        this.setState({
          isLoading: false,
          selectedCardObject: null,
          showActivityModal: false
        });

        this.props.setError(error ? error : 'Error toggling user active status.');
      });
  }

  confirmToggleMobileAccess() {
    this.setState({
      isLoading: true
    });

    toggleMobileAccess(this.state.selectedCardObject.getID(), !this.state.selectedCardObject.getMobileAccess()).then(() => {
      var hasAccess = this.state.selectedCardObject.getMobileAccess();

      this.setState({
        isLoading: false,
        selectedCardObject: null,
        showMobileAccessModal: false
      });

      this.loadUsers();

      this.props.setSuccess(`Successfully ${hasAccess ? 'disabled' : 'enabled'} user mobile access.`);
    })
      .catch(error => {
        this.setState({
          isLoading: false,
          selectedCardObject: null,
          showMobileAccessModal: false
        });

        this.props.setError(error ? error : 'Error toggling user mobile access.');
      });
  }

  render() {
    return (
      <>
        <UserInfoDialog
          user={this.state.selectedCardObject}
          onClose={() => this.setState({ selectedCardObject: null })}

          toggleActivityModal={this.toggleActivityModal}
          toggleResetPasswordModal={this.toggleResetPasswordModal}
          toggleChangePasswordModal={this.toggleChangePasswordModal}
          toggleReset2faModal={this.toggleReset2faModal}
          toggleMobileAccessModal={this.toggleMobileAccessModal}
        />

        {isNotNull(this.state.selectedCardObject) &&
          <>
            <ConfirmationModal
              open={this.state.showReset2faModal}
              onClose={this.toggleReset2faModal}
              onConfirm={this.confirmReset2fa}
              title={'Reset 2FA Confirmation'}
              body={
                <>
                  <div>
                    Are you sure you want to reset {this.state.selectedCardObject.getName()}&apos;s 2FA?
                  </div>
                  <br />
                  <div>
                    They will be prompted to reset their 2FA configuration on next login. Please notify them immediately for security purposes.
                  </div>
                </>
              }
              isLoading={this.state.isLoading}
            />

            <ConfirmationModal
              open={this.state.showActivityModal}
              onClose={this.toggleActivityModal}
              onConfirm={this.confirmToggleUserActivity}
              title={(this.state.selectedCardObject.getIsActive() ? 'Deactivate' : 'Reactivate') + ' User Confirmation'}
              body={
                <>
                  <div>
                    Are you sure you want to {(this.state.selectedCardObject.getIsActive() ? 'deactivate' : 'reactivate')} {this.state.selectedCardObject.getName()}&apos;s account?
                  </div>
                  <br />
                  <div>
                    {this.state.selectedCardObject.getIsActive() ? 'They will no longer be able to log in.' : 'They will then be able to log in.'}
                  </div>
                </>
              }
              isLoading={this.state.isLoading}
            />

            <ConfirmationModal
              open={this.state.showResetPasswordModal}
              onClose={this.toggleResetPasswordModal}
              onConfirm={this.confirmResetPassword}
              title={'Reset Password Confirmation'}
              body={
                <>
                  <div>
                    Are you sure you want to reset {this.state.selectedCardObject.getName()}&apos;s password?
                  </div>
                  <br />
                  <div>
                    They will be prompted to change their password on next login. Please notify them immediately for security purposes.
                  </div>
                </>
              }
              isLoading={this.state.isLoading}
            />

            <ConfirmationModal
              open={this.state.showMobileAccessModal}
              onClose={this.toggleMobileAccessModal}
              onConfirm={this.confirmToggleMobileAccess}
              title={'Change Mobile Access Confirmation'}
              body={
                <>
                  <div>
                    Are you sure you want to {(this.state.selectedCardObject.getMobileAccess() ? 'disable' : 'enable')} mobile access for {this.state.selectedCardObject.getName()}?
                  </div>
                  <br />
                  <div>
                    {this.state.selectedCardObject.getMobileAccess() ? 'They will no longer be able to access SimulAlert on their mobile device.' : 'They will now be able to access SimulAlert on their mobile device.'}
                  </div>
                </>
              }
              isLoading={this.state.isLoading}
            />

            <ConfirmationModal
              open={this.state.showChangePasswordModal}
              onClose={this.toggleChangePasswordModal}
              onConfirm={this.confirmChangePassword}
              title={'Change Password Confirmation'}
              body={
                <>
                  <div>
                    {'Are you sure you want to change ' + this.state.selectedCardObject.getName() + '\'s password?'}
                  </div>
                  <TextField required name="password" label="Password" type="password" onChange={(e) => this.setState({ newPassword: e.target.value })} style={{ width: '60%', marginTop: '10px', marginBottom: '-5px' }} variant='filled' />
                  <br />
                  <br />
                  <div>
                    They will not be able to log in until you provide them this password. They will then be prompted to change their password on next login.
                  </div>
                </>
              }
              isLoading={this.state.isLoading}
            />
          </>
        }

        {/* List of Users */}
        <div style={{ display: 'flex', flexDirection: 'column', width: '95%', alignItems: 'center', justifyContent: 'flex-start', margin: (this.props.isMobile ? 'auto' : 20), paddingTop: (this.props.isMobile ? 20 : 0) }}>
          <Button
            component={Link}
            variant='contained'
            to='/admin/users/create'
            style={{ margin: 20, backgroundColor: this.props.schoolAlertStatus?.getColor()?.main, color: this.props.schoolAlertStatus?.getColor()?.text }}>
            Create User
          </Button>
          <UserListPanel
            statusColor={this.props.schoolAlertStatus.color}
            onCardSelection={this.handleCardSelection}
            onRefresh={() => this.loadUsers()}
            {...this.state}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  schoolAlertStatus: state.status.schoolStatus || AlertTypes.UNKNOWN,
  isMobile: state.ui.isMobile
});

const mapDispatchToProps = (dispatch) => ({
  setError: (msg) => setAlert(dispatch, msg),
  setSuccess: (msg) => setSuccess(dispatch, msg)
});

export default connect(mapStateToProps, mapDispatchToProps)(ListUsers);

ListUsers.propTypes = {
  setError: PropTypes.func,
  setSuccess: PropTypes.func,
  isMobile: PropTypes.bool,
  schoolAlertStatus: PropTypes.object
};

import React from 'react';
import {
  Button,
  Card,
  Divider,
  //Grid
} from '@material-ui/core';
import PropTypes from 'prop-types';

// Icons
import EditIcon from '@material-ui/icons/Edit';
//import OpenInNewIcon from '@material-ui/icons/OpenInNew';

// Redux
import { connect } from 'react-redux';

// Constants
import { GREY_SCALE, GREY, UserTypes } from '../constants';

function InfoPanel({ children, statusColor, panelHeight, showEdit, editHref = null, header = null, footer = null }) {

  return (
    <Card style={{ display: 'flex', backgroundColor: GREY_SCALE[3], color: GREY.dark, height: panelHeight, maxHeight: panelHeight, marginLeft: 10, marginRight: 10 }}>
      <div style={{ flex: 2, display: 'flex', flexDirection: 'column', margin: '15px', overflow: 'auto' }}>

        {/* Header */}
        {header &&
        <div>
          <div style={{ height: '6px', borderRadius: '3px', width: '100%', marginBottom: '10px', backgroundColor: statusColor }}></div>
          {header}
          <Divider variant='middle' style={{ marginTop: '10px', marginBottom: '10px' }} />
        </div>}

        <div style={{ flex: 2/*, overflow: 'scroll'*/ }}>
          {children}
        </div>

        {/* Footer */}
        {header &&
        <div>
          <Divider variant='middle' style={{ marginTop: '10px', marginBottom: '10px' }} />
          {footer}
          {editHref && showEdit && <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 10 }}>
            <Button variant="outlined" href={editHref}>Edit <EditIcon style={{ fontSize: 18, marginLeft: '10px' }} /></Button>
          </div>}
          {/*
          <Grid container direction='row' justify='space-around' style={{ marginTop: '20px' }}>
            <Button variant="outlined" disabled>Show on Map <OpenInNewIcon style={{ fontSize: 18, marginLeft: '10px' }} /></Button>
            <Button variant="outlined" disabled>Edit <EditIcon style={{ fontSize: 18, marginLeft: '10px' }} /></Button>
          </Grid>*/}
        </div>}
      </div>
    </Card>
  );
}

class InfoPanelWrapper extends React.Component {
  render() {
    return (
      <InfoPanel {...this.props} />
    );
  }
}

const mapStateToProps = (state) => ({
  panelHeight: state.ui.windowSize.height - 132,
  showEdit: (state.auth.userType === UserTypes.SUPER || state.auth.userType === UserTypes.IT) && state.setupMode.mode
});

export default connect(mapStateToProps, null)(InfoPanelWrapper);

InfoPanel.propTypes = {
  children: PropTypes.object,
  statusColor: PropTypes.string,
  panelHeight: PropTypes.number,
  showEdit: PropTypes.bool,
  editHref: PropTypes.string,
  header: PropTypes.any,
  footer: PropTypes.any
};

import React, { Component } from 'react';
import {
  Typography, TextField, Tooltip, Button, MenuItem
} from '@material-ui/core';
import PropTypes from 'prop-types';

// Components
import InfoButton from '../InfoButton';

// Redux
import { connect } from 'react-redux';
import { setSuccess, clearErrors, setAlert } from '../../redux/actions/alertActions';

// Server Code
import { listFloors, listBuildings, createRoom, deleteRoom, updateRoom } from '../../api';

// Components
import MapRoomZoneSelector from './MapRoomZoneSelector';

// Constants
import { DEFAULT } from '../../constants';
const ROOM_IDENTIFIER_HELP = 'Enter an identifier to describe the room uniquely. For example, Room 125 or Cafeteria 2.';

class RoomForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      buildingID: '',
      floorID: '',
      identifier: '',
      formError: false,
      pageNum: 0,
      xCoordinate: null,
      yCoordinate: null,
      size: 100,
      buildings: [],
      floors: [],
      vertices: []
    };

    this.updateFloorList = this.updateFloorList.bind(this);
  }

  componentDidMount() {
    listBuildings().then(buildings => {
      this.setState({ buildings: buildings }, () => {
        // If room is passed in we should populate our state with its values.
        if (this.props.room != null) {
          const room = this.props.room;
          this.setState({
            buildingID: room.getFloor()?.getBuildingID() || '',
            identifier: room.getIdentifier() || '',
            xCoordinate: room.getCoordinate()?.getX() || '',
            yCoordinate: room.getCoordinate()?.getY() || '',
            size: room.getCoordinate()?.getSize() || 100
          });

          this.updateFloorList(room.getFloor()?.getBuildingID());
        }
      });
    }).catch(error => {
      this.props.setError(error ? error : 'Unable to retrieve buildings.');
    });
  }

  componentWillUnmount() {
    this.props.clearErrors();
  }

  hasFormError() {
    return this.state.buildingName === '' || this.state.floor === '' || this.state.identifier === '';
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });

    if (e.target.name === 'buildingID') {
      this.updateFloorList(e.target.value);
    }
  };

  updateFloorList = (buildingID) => {
    listFloors(buildingID).then(floors => {
      this.setState({ floors, floorID: this.props.room?.getFloorID() || '' });
    }).catch(error => {
      this.props.setError(error ? error : 'Unable to retrieve floors');
    });
  }

  onSlide = (e, newValue) => {
    this.setState({ size: newValue });
  };

  onCoordinateSelection = (x, y) => {
    this.setState({ xCoordinate: x, yCoordinate: y });
  }

  onCoordinateSelection2 = (x, y) => {
    this.setState(prevState => ({
      vertices: [...prevState.vertices, { x, y }]
    }));
  }

  resetVertices = () => {
    this.setState({ vertices: [] });
  }

  submitForm = (e) => {
    e.preventDefault();

    // Ensure there is no form error
    const formError = this.hasFormError();
    this.setState({ formError });
    if (formError) return;

    // Submit Create Room Form
    const { floorID, identifier, vertices } = this.state;
    this.setState({ isLoading: true });

    // Update Existing Room
    if (this.props.room != null) {
      updateRoom({ id: this.props.room.getID(), floorID, identifier, vertices }).then(() => {
        this.props.setSuccess('Successfully updated Room');
        this.setState({ isLoading: false, buildingID: '', floorID: '', identifier: '', xCoordinate: null, yCoordinate: null, pageNum: 0 });
        this.props.onSuccess();
      }).catch(error => {
        this.props.setError(error ? error : 'Error: Unable to update room.');
        this.setState({ isLoading: false });
      });
    }

    // Create New Room
    else {
      createRoom(floorID, identifier, vertices).then(() => {
        this.props.setSuccess('Successfully created Room');
        this.setState({ isLoading: false, buildingID: '', floorID: '', identifier: '', xCoordinate: null, yCoordinate: null, pageNum: 0 });
        this.props.onSuccess();
      }).catch(error => {
        this.props.setError(error ? error : 'Error: Unable to create room.');
        this.setState({ isLoading: false });
      });
    }
  }

  backPage = () => {
    this.setState({ pageNum: 0 });
  }

  nextPage = () => {
    if (this.hasFormError()) this.setState({ formError: this.hasFormError() });
    else this.setState({ pageNum: 1, formError: this.hasFormError() });
  }

  deleteRoom = () => {
    deleteRoom(this.props.room?.getID()).then(() => {
      this.props.setSuccess('Successfully deleted room.');
      this.props.onSuccess();
    }).catch(error => {
      this.props.setError(error ? error : 'Error: Unable to delete room.');
    });
  }

  render() {
    return (
      <form onSubmit={this.submitForm} autoComplete="off" style={{ width: (this.props.isFullWidth ? '50%' : '90%'), display: 'flex', justifyContent: 'center' }}>

        {/* Form Page */}
        {this.state.pageNum === 0 &&
          <div style={{ width: '100%' }}>

            {/* Select Building */}
            <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between', marginBottom: '10px' }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: (this.props.isMobile ? '100%' : '40%') }}>
                <Typography variant='h6'>Select Building</Typography>
              </div>
              <TextField
                required
                select
                error={this.state.formError && (this.state.buildingID === '' || this.state.buildingID == null)}
                name="buildingID"
                label="Building"
                value={this.state.buildingID}
                style={{ width: (this.props.isMobile ? '100%' : '60%') }}
                onChange={this.onChange}
                variant='filled'>
                {this.state.buildings.map((building, index) => (
                  <MenuItem key={index} value={building.getID()}>
                    {building.getName()}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            {/* Select Building Floor */}
            <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between', marginBottom: '10px' }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: (this.props.isMobile ? '100%' : '40%') }}>
                <Typography variant='h6'>Select Building Floor</Typography>
              </div>
              <TextField
                required
                select
                error={this.state.formError && this.state.floorID === ''}
                name="floorID"
                label="Building Floor"
                value={this.state.floorID}
                style={{ width: (this.props.isMobile ? '100%' : '60%') }}
                onChange={this.onChange}
                variant='filled'>
                {this.state.floors.map((floor, index) => (
                  <MenuItem key={index} value={floor.getID()}>
                    {floor.getIdentifier()}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            {/* Identifier */}
            <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between', marginBottom: '10px' }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: (this.props.isMobile ? '100%' : '40%') }}>
                <Typography variant='h6'>Room Identifier</Typography>
                <InfoButton name='Room Identifier' body={ROOM_IDENTIFIER_HELP} color={this.props.statusColor} />
              </div>
              <TextField required error={this.state.formError && this.state.identifier === ''} name="identifier" label="Identifier" style={{ width: (this.props.isMobile ? '100%' : '60%') }} onChange={this.onChange} value={this.state.identifier} variant='filled' />
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              {this.props.room &&
              <Tooltip title='Delete this room. Warning: this is not easy to undo.'>
                <Button onClick={this.deleteRoom} variant='outlined' style={{ width: '200px', height: '50px' }}>Delete Room</Button>
              </Tooltip>}
              <Button onClick={this.nextPage} variant="contained" style={{ width: '200px', height: '50px', color: this.props.statusColor.text, backgroundColor: this.props.statusColor.main, margin: '30px' }}>Next</Button>
            </div>
          </div>}

        {/* Select Map Position */}
        {this.state.pageNum === 1 &&
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
          <Typography variant='h5' style={{ textAlign: 'center', marginBottom: '1em' }}>Select the vertices of the room on the map to create the room zone. Click the reset button below to start over. Please ensure there are NO OVERLAPPING ZONES.</Typography>
          <Button onClick={this.resetVertices} variant="contained" style={{ width: '200px', height: '50px', color: this.props.statusColor.text, backgroundColor: this.props.statusColor.main, margin: '30px' }}>Reset Coordinates</Button>
          <MapRoomZoneSelector
            floor={this.state.floors?.find(floor => floor.getID() === this.state.floorID)}
            floorPlanImagePath={this.state.floors?.find(floor => floor.getID() === this.state.floorID)?.getFloorPlanImage()}
            onCoordinateSelection={this.onCoordinateSelection2}
            vertices={this.state.vertices}
          />
          <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), justifyContent: 'space-around' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <Button onClick={this.backPage} variant="contained" style={{ width: '200px', height: '50px', color: this.props.statusColor.text, backgroundColor: this.props.statusColor.main, margin: (this.props.isMobile ? '30px 0 15px 0px' : '30px') }}>Back</Button>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <Button type='submit' variant="contained" style={{ width: '200px', height: '50px', color: this.props.statusColor.text, backgroundColor: this.props.statusColor.main, margin: (this.props.isMobile ? '15px 0 30px 0px' : '30px' ) }}>Save</Button>
            </div>
          </div>
        </div>}
      </form>
    );
  }
}

const mapStateToProps = state => ({
  statusColor: state.status.schoolStatus ? state.status.schoolStatus.getColor() : DEFAULT,
  isMobile: state.ui.isMobile,
  isFullWidth: state.ui.isFullWidth
});

const mapDispatchToProps = (dispatch) => ({
  setError: (msg) => {
    dispatch(clearErrors());
    setAlert(dispatch, msg);
  },
  setSuccess: (msg) => setSuccess(dispatch, msg),
  clearErrors: () => dispatch(clearErrors())
});

export default connect(mapStateToProps, mapDispatchToProps)(RoomForm);

RoomForm.propTypes = {
  setError: PropTypes.func,
  setSuccess: PropTypes.func,
  isMobile: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  statusColor: PropTypes.object,
  clearErrors: PropTypes.func,
  room: PropTypes.object,
  onSuccess: PropTypes.func.isRequired
};

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import QueryString from 'query-string';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { setAlert } from '../../../redux/actions/alertActions';

// Server
import { listBuildings, getFloor, setRoomStatus, getRoom } from '../../../api';

// Components
import RoomInfoPanel from '../../../components/RoomInfoPanel';
import DefaultInfoPanel from '../../../components/DefaultInfoPanel';
import MapRoomView from './MapRoomView';
import SelectorWidget from './SelectorWidget';

// Constants
import { AlertTypes, DEFAULT, isNull } from '../../../constants';
import { removeAnnouncement } from '../../../redux/actions/announcementActions';

class MapView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRoom: null,
      buildings: null,
      school: null,
      selectedBuilding: null,
      selectedFloorID: null,
      selectedFloor: null,
      selectedFloorImage: null,
      lastImageTime: null,
      isLoadingFloor: false
    };
  }

  componentWillUnmount() {
    this._ismounted = false;
    clearInterval(this.schoolTimer);
    clearInterval(this.floorTimer);
    this.schoolTimer = null;
    this.floorTimer = null;
  }

  componentDidMount() {
    this._ismounted = true;
    this.getSchoolFromServer();
    this.startSchoolTimer();
  }

  componentDidUpdate() {
    // On first load, isLoggedIn is false when mounting, so the componentDidMount call doesn't work
    if (this._ismounted && this.props.isLoggedIn && !this.state.buildings) {
      this.getSchoolFromServer();
    }
  }

  forceOpenRoom = () => {
    // See if there's an emergency to focus the map on
    let roomID = QueryString.parse(this.props.location?.search)?.roomID;
    if (roomID != null) {
      getRoom(roomID).then(room => {
        // Open the appropriate building, floor, and room
        let building = this.state.buildings?.find(building => building.getID() === room?.getFloor()?.getBuildingID());
        this.setState({ selectedBuilding: building });
        this.onFloorSelection(room.getFloorID(), room);
      }).catch(error => {
        console.log(error);
      });
    }
  }

  // Keep trying to get the school object if not successful
  startSchoolTimer = () => {
    this.schoolTimer = setInterval(
      () => {
        if (this.state.buildings) {
          clearInterval(this.schoolTimer);
          this.schoolTimer = null;
        }
        else this.getSchoolFromServer();
      },
      5000
    );
  }

  // Called when a user selects a room on the map
  onRoomSelection = (room) => {
    this.setState({ selectedRoom: this.state.selectedRoom === room ? null : room });
  }

  // Called when a user selects a floor from the selector widget
  onFloorSelection = (selectedFloorID, room = null) => {
    this.setState({ selectedFloorID: selectedFloorID, selectedFloorImage: null }, () => {
      this.getFloorFromServer(room);
    });
  }

  // Retrieves the school object from the server
  getSchoolFromServer() {
    if (this.props.isLoggedIn) {
      listBuildings().then(buildings => {
        if (this._ismounted) {
          this.setState({ buildings: buildings, selectedBuilding: buildings?.length > 0 ? buildings[0] : '' }, () => {
            this.forceOpenRoom();
          });
        }
      }).catch(error => {
        if (this._ismounted) {
          this.props.setError(error ? error : 'Could not retrieve the buildings from the server.');
        }
      });
    }
  }

  // Retrieves the floor object from the server
  getFloorFromServer(room = null) {
    this.setState({ isLoadingFloor: true });
    getFloor(this.state.selectedFloorID).then(floor => {
      if (this._ismounted) {
        if (!this.state.selectedFloorImage || this.state.lastImageTime == null || new Date() - this.state.lastImageTime > 1000 * 60 * 5) {
          this.setState({ selectedFloorImage: floor.getFloorPlanImage(), lastImageTime: new Date() });
          console.log('image update');
        }
        this.setState({ selectedFloor: floor, isLoadingFloor: false });
        if (room != null) this.onRoomSelection(floor.getRooms()?.find(r => r.getID() === room.getID()));
        else if (this.state.selectedRoom) this.setState({ selectedRoom: floor.getRooms().find(room => room.getID() === this.state.selectedRoom.getID()) || null });
        this.updateFloorFromServer();
      }
    }).catch(error => {
      if (this._ismounted) {
        this.props.setError(error ? error : 'Could not retrieve the floor info from the server.');
        this.setState({ isLoadingFloor: false, selectedRoom: null });
        this.updateFloorFromServer();
      }
    });
  }

  // Updates the floor regularly
  updateFloorFromServer() {
    if (isNull(this.floorTimer)) {
      this.floorTimer = setInterval(
        () => {
          // Clear the timer
          clearInterval(this.floorTimer);
          this.floorTimer = null;

          // Get the floor from the server or update the floor
          if (!this.state.isLoading) this.getFloorFromServer();
          else this.updateFloorFromServer();
        },
        5000
      );
    }
  }

  // Called when a user changes an individual room's alert status via an info panel
  handleSetRoomStatus = (newStatus) => {
    setRoomStatus(this.state.selectedRoom.getID(), newStatus).then(() => {
      this.state.selectedRoom.setAlertStatus(newStatus);
      this.state.selectedFloor.getRooms().forEach(room => {
        if (room.getID() === this.state.selectedRoom.getID()) room.setAlertStatus(newStatus);
      });
      this.setState({ selectedRoom: this.state.selectedRoom });

      // Clear the announcement banner if the room is normal
      if (newStatus === AlertTypes.NORMAL) this.props.clearAnnouncement(this.state.selectedRoom.getID());
    }).catch(error => {
      this.props.setError(error ? error : 'Could not set the room status.');
    });
  }

  render() {
    return (
      <Grid container
        direction="row"
        justify="space-evenly">
        <Grid item sm={4}>
          {this.state.selectedRoom ?
            <RoomInfoPanel room={this.state.selectedRoom} onSetStatus={this.handleSetRoomStatus} isMobile={this.props.isMobile} handleClose={() => this.setState({ selectedRoom: null })} setupMode={this.props.setupMode} />
            :
            (!this.props.isMobile && <DefaultInfoPanel message="Please select a room to view more info." />)
          }
        </Grid>
        <Grid item sm={8}>
          <MapRoomView
            floorPlanImagePath={this.state.selectedFloorImage}
            rooms={this.state.selectedFloor && this.state.selectedFloor.getRooms() ? this.state.selectedFloor.getRooms() : []}
            onRoomSelection={this.onRoomSelection}
            selectedRoom={this.state.selectedRoom}
            statusColor={this.props.statusColor}
            style={{ marginBottom: '10px' }}
            isLoading={this.state.isLoading}
          />

          {this.state.buildings &&
          <SelectorWidget
            buildings={this.state.buildings}
            onBuildingSelection={(building) => this.setState({ selectedBuilding: building, selectedFloorID: null })}
            onFloorSelection={this.onFloorSelection}
            selectedBuilding={this.state.selectedBuilding}
            selectedFloorID={this.state.selectedFloorID}
          />}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.userID != null,
  statusColor: state.status.schoolStatus ? state.status.schoolStatus.getColor() : DEFAULT,
  isMobile: state.ui.isMobile,
  setupMode: state.setupMode.mode || state.setupMode.isLoading
});

const mapDispatchToProps = (dispatch) => ({
  setError: (msg) => setAlert(dispatch, msg),
  clearAnnouncement: (id) => dispatch(removeAnnouncement(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MapView));

MapView.propTypes = {
  isLoggedIn: PropTypes.bool,
  location: PropTypes.object,
  setError: PropTypes.func,
  isMobile: PropTypes.bool,
  statusColor: PropTypes.object,
  setupMode: PropTypes.bool.isRequired,
  clearAnnouncement: PropTypes.func.isRequired
};

import React, { Component } from 'react';
import {
  CircularProgress, Typography, TextField, Button, Container, Grid
} from '@material-ui/core';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { passwordUpdated } from '../../../redux/actions/authActions';
import { setAlert, setSuccess } from '../../../redux/actions/alertActions';

// Simul Alert Server
import { updatePassword } from '../../../api';

// Constants
import { DEFAULT, GREY } from '../../../constants';

class ChangePassword extends Component {

  constructor(props) {
    super(props);

    this.state = {
      newPassword: '',
      confirmNewPassword: '',
      isLoading: false
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (this.state.newPassword === '' || this.state.confirmNewPassword === '') {
      this.props.setError('Error: You cannot leave a field blank.');
    }
    else if (this.state.newPassword === this.state.confirmNewPassword) {
      this.setState({ isLoading: true });
      updatePassword(this.state.newPassword).then(() => {
        this.setState({ isLoading: false });
        this.props.passwordUpdated();
        this.props.setSuccess('Successfully updated password!');
      }).catch(error => {
        this.setState({ isLoading: false });
        this.props.setError(error ? error : 'Could not change your password.');
      });
    }
    else {
      this.props.setError('Error: Passwords must match.');
    }
  }

  render() {
    return (
      <Container style={{ marginTop: '10%' }}>
        <h1
          style={{ color: GREY.dark, textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}
        >
          Welcome to SimulAlert!
        </h1>

        <form autoComplete='off' onSubmit={this.onSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant='h6' style={{ width: '100%', marginBottom: '15px', marginTop: '25px' }}>Please update your password. Passwords are required to have all of the following: uppercase letter, lowercase letter, symbol, and number. Store your password in a secure location.</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField required error={this.state.formError && this.state.buildingName === ''} type="password" name="newPassword" label="New Password" style={{ width: '100%', marginBottom: '5px' }} onChange={this.onChange} variant='filled' />
            </Grid>
            <Grid item xs={12}>
              <TextField required error={this.state.formError && this.state.buildingName === ''} type="password" name="confirmNewPassword" label="Confirm New Password" style={{ width: '100%', marginBottom: '5px' }} onChange={this.onChange} variant='filled' />
            </Grid>
            {/* Submit Button */}
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginBottom: '2em' }}>
              <Button type='submit' variant="contained" style={{ width: '200px', backgroundColor: this.props.statusColor.main, color: this.props.statusColor.text }} disabled={this.state.isLoading}>
                Submit
                {this.state.isLoading && <CircularProgress size={25} style={{ color: this.props.statusColor.text, marginLeft: '1em' }} />}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>

    );
  }
}

const mapStateToProps = state => ({
  statusColor: state.status.schoolStatus ? state.status.schoolStatus.getColor() : DEFAULT
});

const mapDispatchToProps = dispatch => ({
  passwordUpdated: () => dispatch(passwordUpdated()),
  setError: (msg) => setAlert(dispatch, msg),
  setSuccess: (msg) => setSuccess(dispatch, msg)
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);

ChangePassword.propTypes = {
  setError: PropTypes.func,
  setSuccess: PropTypes.func,
  statusColor: PropTypes.object,
  passwordUpdated: PropTypes.bool
};

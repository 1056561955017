import React from 'react';
import {
  Button,
  Divider,
  Dialog,
  Slide,
  IconButton
} from '@material-ui/core';
import PropTypes from 'prop-types';

// Icons
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';

// Redux
import { connect } from 'react-redux';

// Constants
import { GREY_SCALE, UserTypes } from '../constants';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function InfoDialog({ children, statusColor, header = null, footer = null, open, handleClose, editHref = null, showEdit }) {
  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} style={{ margin: '5px' }}>
      <div style={{ flex: 2, display: 'flex', flexDirection: 'column', padding: '15px', backgroundColor: GREY_SCALE[3] }}>
        <div style={{ width: '100%', display: 'flex' , justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ width: '80%', display: 'flex', justifyContent: 'flex-end' }}>
            <div style={{ height: '6px', borderRadius: '3px', width: '75%', backgroundColor: statusColor }}></div>
          </div>
          <div style={{ width: '20%', display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton
              onClick={handleClose}
              style={{ width: '35px', height: '35px' }}>
              <ClearIcon style={{ color: GREY_SCALE[5] }} />
            </IconButton>
          </div>
        </div>
        {/* Header */}
        {header &&
          <div>
            {header}
            <Divider variant='middle' style={{ marginTop: '10px', marginBottom: '10px' }} />
          </div>}

        <div style={{ flex: 2/*, overflow: 'scroll'*/ }}>
          {children}
        </div>

        {/* Footer */}
        {header &&
          <div>
            {footer}
            {editHref && showEdit && <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Button variant="outlined" href={editHref}>Edit <EditIcon style={{ fontSize: 18, marginLeft: '10px' }} /></Button>
            </div>}
            {/*
          <Grid container direction='row' justify='space-around' style={{ marginTop: '20px' }}>
            <Button variant="outlined" disabled>Show on Map <OpenInNewIcon style={{ fontSize: 18, marginLeft: '10px' }} /></Button>
            <Button variant="outlined" disabled>Edit <EditIcon style={{ fontSize: 18, marginLeft: '10px' }} /></Button>
          </Grid>*/}
          </div>}
      </div>
    </Dialog>
  );
}

class InfoDialogWrapper extends React.Component {
  render() {
    return (
      <InfoDialog {...this.props} />
    );
  }
}

const mapStateToProps = (state) => ({
  panelHeight: state.ui.windowSize.height - 132,
  showEdit: (state.auth.userType === UserTypes.SUPER || state.auth.userType === UserTypes.IT) && state.setupMode.mode
});

export default connect(mapStateToProps, null)(InfoDialogWrapper);

InfoDialog.propTypes = {
  children: PropTypes.object,
  statusColor: PropTypes.object,
  header: PropTypes.any,
  footer: PropTypes.any,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  editHref: PropTypes.string,
  showEdit: PropTypes.bool
};

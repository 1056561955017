import React, { Component } from 'react';
import {
  CircularProgress, Typography, TextField, Button, Container, Grid
} from '@material-ui/core';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { authenticateUser } from '../../../redux/actions/authActions';

// Constants
import { DEFAULT, GREY } from '../../../constants';

class TwoFactor extends Component {

  constructor(props) {
    super(props);

    this.state = {
      duoCode: ''
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.authenticateUser(this.state.duoCode);
  }

  render() {
    return (
      <Container style={{ marginTop: '10%' }}>
        <h1
          style={{ color: GREY.dark, textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}
        >
          Two Factor Authenticate
        </h1>

        <form autoComplete='off' onSubmit={this.onSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant='h5' style={{ width: '100%', marginBottom: '5px', marginTop: '5px' }}>Use the Duo App on your phone to obtain an authentication code, then enter it below.</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h5' style={{ width: '100%', marginBottom: '5px', marginTop: '5px' }}>If you are unable to find the code, please contact IT.</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField required error={this.state.formError && this.state.buildingName === ''} name="duoCode" label="Duo Code" style={{ width: '100%', marginBottom: '15px' }} onChange={this.onChange} variant='filled' />
            </Grid>
            {/* Submit Button */}
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <Button type='submit' variant="contained" style={{ width: '200px', backgroundColor: this.props.statusColor.main, color: this.props.statusColor.text, marginBottom: '2em' }} disabled={this.props.isLoading}>
                Submit
                {this.props.isLoading && <CircularProgress size={25} style={{ color: this.props.statusColor.text, marginLeft: '1em' }} />}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  statusColor: state.status.schoolStatus ? state.status.schoolStatus.getColor() : DEFAULT,
  isLoading: state.auth.isLoading
});

const mapDispatchToProps = (dispatch) => ({
  authenticateUser: (token) => authenticateUser(dispatch, token)
});

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactor);

TwoFactor.propTypes = {
  authenticateUser: PropTypes.func,
  statusColor: PropTypes.object,
  isLoading: PropTypes.bool
};

import {
  IS_STATUS_LOADING,
  IS_STATUS_NOT_LOADING,
  UPDATE_ALERT_MODAL,
  UPDATE_SCHOOL_STATUS,
  STOP_SHOW_ALERT_ROOM
} from '../actions/statusActions';

import { AlertTypes } from '../../constants';

const initialState = {
  schoolStatus: AlertTypes.UNKNOWN,
  isLoading: false,
  showAlertModal: false,
  showAlertRoom: false,
  roomID: null
};

const status = (state = initialState, action) => {
  switch (action.type) {
    case IS_STATUS_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case IS_STATUS_NOT_LOADING:
      return {
        ...state,
        isLoading: false
      };
    case UPDATE_SCHOOL_STATUS:
      var alertStatus = Object.values(AlertTypes).find(alertStatus => action.status.getDashboardStatus() === alertStatus.getDashboardStatus()) || AlertTypes.UNKNOWN;
      var isNewStatus = alertStatus.getID() > state.schoolStatus.getID();

      return {
        ...state,
        schoolStatus: alertStatus,
        showAlertModal: alertStatus !== AlertTypes.NORMAL ? isNewStatus : false,
        showAlertRoom: action.roomID != null && isNewStatus && alertStatus !== AlertTypes.NORMAL && alertStatus !== AlertTypes.ALL_CLEAR,
        roomID: action.roomID
      };
    case UPDATE_ALERT_MODAL:
      return {
        ...state,
        showAlertModal: action.value
      };
    case STOP_SHOW_ALERT_ROOM:
      return {
        ...state,
        showAlertRoom: false,
        roomID: null
      };
    default:
      return state;
  }
};

export default status;

import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

// Icons
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Settings from '@material-ui/icons/Settings';

// Constants
import { GREY, GREEN, isNotNull } from '../constants';

export default function BadgeStatusWidget({ style = {}, badge }) {
  return (
    <Grid container direction='column' style={{ ...style }} justify='center'>

      <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
        <Typography variant='overline' style={{ color: GREY.dark }}>Badge Status</Typography>
      </Grid>

      {badge && <Widget badge={badge} />}
    </Grid>
  );
}

function Widget({ badge }) {
  return (
    <Grid item container direction='row' justify='center'>
      {/* --- ICON --- */}
      <Grid item style={{ display: 'flex', alignItems: 'center' }}>
        {badge.getIsConnected() ? <CheckCircleIcon style={{ color: GREEN.main, fontSize: 40 }} /> : <CancelIcon style={{ color: GREY.main, fontSize: 40 }} />}
      </Grid>

      {/* --- TEXT --- */}
      <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Grid container direction='column' justify='center'>
          {/* Connection Status */}
          <Grid item style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Typography variant='h6' style={{ marginLeft: '5px', color: GREY.dark }}>{badge.getIsConnected() ? 'Connected' : 'Not Connected'}</Typography>
          </Grid>

          {/* Battery Level */}
          {badge.getIsConnected() && badge.getBatteryLevel() !== null && badge.getBatteryLevel() !== undefined && <Grid item style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Typography variant='button' style={{ marginLeft: '5px', color: GREY.main, marginTop: '-7px' }}>Battery {Math.floor(badge.getBatteryLevel())}%</Typography>
          </Grid>}
        </Grid>
      </Grid>

      {isNotNull(badge.getFirmwareVersion()) &&
        <>
          <Grid item container direction='row' justify='center'>
            {/* --- ICON --- */}
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Settings style={{ color: GREEN.main, fontSize: 40 }} />
            </Grid>
            {/* --- TEXT --- */}
            <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Grid container direction='column' justify='center'>
                {/* Connection Status */}
                <Grid item style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Typography variant='h6' style={{ marginLeft: '5px', color: GREY.dark }}>{'v' + badge.getFirmwareVersion()}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      }
    </Grid>
  );
}

BadgeStatusWidget.propTypes = {
  style: PropTypes.object,
  badge: PropTypes.object.isRequired
};

Widget.propTypes = {
  badge: PropTypes.object.isRequired
};

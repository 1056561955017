import { createMuiTheme } from '@material-ui/core/styles';

// https://material-ui.com/customization/palette/
export default createMuiTheme({
  typography: {
    h1: {
      fontSize: '4.5rem',
      margin: '1em 0.5em 0.5em 0.5em',
      textAlign: 'center'
    },
    h2: {
      // fontSize: '2rem'
    },
    h3: {
      // fontSize: '1rem'
    },
    h4: {
      fontSize: '1.8rem'
    },
    h5: {
      fontSize: '1.5rem'
    },
    h6: {
      fontSize: '1.1rem'
    },
    body1: {
      // fontSize: '19px'
    },
    body2: {
      // fontSize: '16px'
    },
    subtitle1: {
      // fontSize: '21px',
    },
    button: {
      // fontSize: '18px',
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 970,
      lg: 1280,
      xl: 1920,
    },
  },
});

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Icons
import EditIcon from '@material-ui/icons/Edit';

// Components
import ListTable from '../../../components/ListTable';

// API
import { listStaff } from '../../../api';

// Constants
const COLUMNS = (setupMode) => [
  ...(setupMode ? [{
    title: 'Edit',
    render: rowData => (
      <Link to={'/admin/staff/' + rowData.getID()}><EditIcon /></Link>
    ),
    width: null,
    cellStyle: {
      width: 25
    }
  }] : []),
  {
    field: 'id',
    title: 'ID'
  },
  {
    field: 'firstName',
    title: 'First Name'
  },
  {
    field: 'lastName',
    title: 'Last Name'
  },
  {
    field: 'email',
    title: 'Email'
  },
  {
    field: 'phoneNumber',
    title: 'PhoneNumber'
  },
  {
    title: 'Staff Type',
    render: rowData => rowData?.getStaffType()?.getName(),
    customFilterAndSearch: (term, rowData) => rowData?.getStaffType()?.getName()?.toLowerCase().includes(term.toLowerCase())
  },
  {
    field: 'assignedBadge',
    title: 'Assigned Badge SN',
    render: rowData => rowData?.getBadge()?.getSerialNumber(),
    customFilterAndSearch: (term, rowData) => rowData?.getBadge()?.getSerialNumber()?.toLowerCase().includes(term.toLowerCase())
  },
  {
    field: 'assignedRoom.identifier',
    title: 'Assigned Room'
  }
];

class Staff extends Component {
  render() {
    return (
      <ListTable
        title='Staff'
        buttonLink='/admin/staff/create'
        buttonText='Create New'
        columns={COLUMNS(this.props.setupMode)}
        retrievalFunction={listStaff}
        hideButton={!this.props.setupMode}
      />
    );
  }
}

const mapStateToProps = state => ({
  setupMode: state.setupMode.mode || state.setupMode.isLoading
});

Staff.propTypes = {
  setupMode: PropTypes.bool.isRequired
};

export default connect(mapStateToProps, null)(Staff);

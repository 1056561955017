import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

// Constants
import { GREY, isNotNull } from '../constants';

export default function AlertUpdateWidget({ style = {}, updates }) {
  // If null or not an array, render nothing.
  if (!isNotNull(updates) || !Array.isArray(updates)) {
    return null;
  }
  updates = updates.filter(element => element.alertTypeID > 3);

  // If after filtering we have no elements, render nothing.
  if (updates.length <= 0) {
    return null;
  }

  return (
    <Grid container direction='column' style={{ ...style }} justify='center'>

      <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
        <Typography variant='overline' style={{ color: GREY.dark }}>Alert Updates</Typography>
      </Grid>

      {/** Comparison on alertStatus index is to ensure the status is more than a clear/normal/unknown */}
      {updates && updates.map((update, index) => <Widget key={index} update={update} />)}
    </Grid>
  );
}

function Widget({ update }) {
  var timestamp = update.getTimestamp().toFormat('hh:mm a MM/dd/yy');

  return (
    <Grid container direction='row' alignItems="center" justify="center" spacing={0} style={{ marginBottom: '10px' }}>
      <Grid item>
        <div style={{ height: '20px', width: '30px', backgroundColor: update.getAlertType().getColor().main }}></div>
      </Grid>

      <Grid xs={9} item style={{ display: 'flex', alignItems: 'center', paddingLeft: '20px' }}>
        <Grid container direction='column'>
          <Grid item style={{ textAlign: 'left' }}>
            <Typography variant='body1' style={{ color: update.getAlertType().getColor().dark, textTransform: 'uppercase', fontWeight: '600' }}>
              {update.getStaffMember()?.getName() != null ? 'Staff Member: ' + update.getStaffMember().getName() : 'Dashboard Update'}
            </Typography>
          </Grid>
        </Grid>

        <Grid xs={3} item>
          <Grid container direction='column'>
            <Grid item style={{ textAlign: 'left' }}>
              <Typography variant='body1'>
                {timestamp}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

AlertUpdateWidget.propTypes = {
  style: PropTypes.object,
  updates: PropTypes.array
};

Widget.propTypes = {
  update: PropTypes.object.isRequired
};
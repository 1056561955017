import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

// Components
import InfoPanel from './InfoPanel';

export default function DefaultInfoPanel({ style, message = 'Please select a card to view more info.' }) {
  return (
    <InfoPanel style={style}>
      <Typography variant='h6' style={{ textAlign: 'center' }}>{message}</Typography>
    </InfoPanel>
  );
}

DefaultInfoPanel.propTypes = {
  style: PropTypes.object,
  message: PropTypes.string
};
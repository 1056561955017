export default class HubStatusAcceptList {
  static thaw(hubStatusAcceptListObj) {
    if (hubStatusAcceptListObj == null) return null;
    const hubStatusAcceptList = new HubStatusAcceptList();
    if (hubStatusAcceptListObj.id != null) hubStatusAcceptList.setID(hubStatusAcceptListObj.id);
    if (hubStatusAcceptListObj.hubType != null) hubStatusAcceptList.setHubType(hubStatusAcceptListObj.hubType);
    if (hubStatusAcceptListObj.alertTypeId != null) hubStatusAcceptList.setAlertTypeId(hubStatusAcceptListObj.alertTypeId);
    return hubStatusAcceptList;
  }

  static thawList(hubStatusAcceptListObjList) {
    if (hubStatusAcceptListObjList == null) return [];
    var hubStatusAcceptLists = [];
    for (var i = 0; i < hubStatusAcceptListObjList.length; i++) {
      hubStatusAcceptLists.push(HubStatusAcceptList.thaw(hubStatusAcceptListObjList[i]));
    }
    return hubStatusAcceptLists;
  }

  static freeze(hubStatusAcceptList) {
    if (hubStatusAcceptList == null) return null;
    var hubStatusAcceptListObj = {};
    if (hubStatusAcceptList.getID() != null) hubStatusAcceptListObj.id = hubStatusAcceptList.getID();
    if (hubStatusAcceptList.getHubType() != null) hubStatusAcceptListObj.hubType = hubStatusAcceptList.getHubType();
    if (hubStatusAcceptList.getAlertTypeId() != null) hubStatusAcceptListObj.alertTypeId = hubStatusAcceptList.getAlertTypeId();
    return hubStatusAcceptListObj;
  }

  static freezeList(hubStatusAcceptLists) {
    if (hubStatusAcceptLists == null) return [];
    var hubStatusAcceptListObjList = [];
    for (var i = 0; i < hubStatusAcceptLists.length; i++) {
      hubStatusAcceptListObjList.push(HubStatusAcceptList.freeze(hubStatusAcceptLists[i]));
    }
    return hubStatusAcceptListObjList;
  }

  constructor() {
    this.setID(null);
    this.setHubType(null);
    this.setAlertTypeId(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setHubType(hubType) {
    this.hubType = hubType;
  }

  setAlertTypeId(alertTypeId) {
    this.alertTypeId = alertTypeId;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getHubType() {
    return this.hubType;
  }

  getAlertTypeId() {
    return this.alertTypeId;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

}

import { SCREEN_RESIZE } from '../actions/uiActions';
import { MOBILE_WIDTH, FULL_WIDTH } from '../../constants';

const initialState = {
  windowSize: {
    width: window.innerWidth,
    height: window.innerHeight
  },
  isMobile: window.innerWidth <= MOBILE_WIDTH,
  isFullWidth: window.innerWidth >= FULL_WIDTH
};

const ui = (state = initialState, action) => {
  switch (action.type) {
    case SCREEN_RESIZE:
      return {
        windowSize: {
          width: action.width,
          height: action.height
        },
        isMobile: action.width <= MOBILE_WIDTH,
        isFullWidth: action.width >= FULL_WIDTH
      };
    default:
      return state;
  }
};

export default ui;

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Typography, TextField, Button, MenuItem
} from '@material-ui/core';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { setSuccess, setAlert, clearErrors } from '../../redux/actions/alertActions';

// Components
import InfoButton from '../InfoButton';

// Server
import { createHub, listBuildings, listUnassignedHubs } from '../../api';

// Constants
import { DEFAULT, HubTypes } from '../../constants';
import MapHubLocationSelector from './MapHubLocationSelector';
const SERIAL_NUMBER_HELP = 'The serial number of the hub that you would like to assign.';
const HUB_TYPE_HELP = 'The type of hub that you are assigning.';
const ASSIGNED_ROOM_HELP = 'The room that the hub you are assigning will reside in.';
const NOTES_HELP = 'Any notes you would like to add to the hub.';

class HubForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      unassignedHubs: [],
      serialNumber: '',
      hubType: '',
      buildingID: '',
      floorID: '',
      assignedRoomID: '',
      notes: '',
      buildings: [],
      formError: false,
      redirectToHubs: false,
      pageIndex: 0,
      x: null,
      y: null,
      isLoading: false
    };
  }

  componentDidMount() {
    listUnassignedHubs().then((hubs) => {
      this.setState({ unassignedHubs: hubs });
    }).catch(error => {
      this.props.setError(error ? error : 'Error getting unassigned hubs.');
    });

    listBuildings().then((buildings) => {
      this.setState({ buildings: buildings });
      
      // If hub is passed in we should populate our state with its values.
      if (this.props.hub != null) {
        const hub = this.props.hub;
        this.setState({
          serialNumber: hub.getSerialNumber(),
          hubType: hub.getHubType() || '',
          floorID: hub.getFloorID() || '',
          buildingID: buildings.find(building => building.floors.find(floor => floor.id === hub.getFloorID()) != null)?.id || '',
          assignedRoomID: hub.getRoomID() || '',
          notes: hub.getNotes() || ''
        });
      }
    }).catch(error => {
      this.props.setError(error ?? 'Error getting buildings, floors, and rooms.');
    });
  }

  componentWillUnmount() {
    this.props.clearErrors();
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  hasFormError() {
    if (this.state.hubType === HubTypes.HALLWAY){
      return this.state.serialNumber === '' || this.state.hubType === '' || this.state.floorID === '' || this.state.buildingID === '' || this.state.notes === '';
    }
    else {
      return this.state.serialNumber === '' || this.state.hubType === '' || this.state.assignedRoomID === '' || this.state.floorID === '' || this.state.buildingID === '';
    }
  }

  nextPage = () => {
    const formError = this.hasFormError();
    this.setState({ formError });
    if (formError) return;

    this.setState({ pageIndex: 1 });
  }

  prevPage = () => {
    this.setState({ pageIndex: 0 });
  }

  submitForm = () => {
    // Ensure there is no form error
    const formError = this.hasFormError();
    this.setState({ formError });
    if (formError) return;

    this.setState({ isLoading: true });

    const { serialNumber, hubType, assignedRoomID, x, y, notes, floorID } = this.state;

    if (x == null || y == null) {
      this.props.setError('Please select a location for the hub.');
      return;
    }
    createHub({
      serialNumber: String(serialNumber),
      hubType,
      roomID: this.state.hubType === HubTypes.HALLWAY ? null : assignedRoomID,
      notes,
      coordinate: { x, y },
      floorID
    }).then(()=> {
      this.props.setSuccess('Successfully assigned new hub.');

      // Set state back to null
      this.setState({ isLoading: false, serialNumber: '', hubType: '', assignedRoomID: '', notes: '', buildingID: '', floorID: '', x: null, y: null, redirectToHubs: true });

    }).catch(error => {
      this.props.setError(error ? error : 'Error assigning new hub.');
      this.setState({ isLoading: false });
    });
  }

  render() {
    return this.state.redirectToHubs ? <Redirect to='/admin/hubs' /> : (
      <form noValidate autoComplete="off" style={{ width: '100%' }}>

        {this.state.pageIndex === 0 &&
        <>
          {/* Serial Number */}
          <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between', marginBottom: '10px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: (this.props.isMobile ? '100%' : '40%') }}>
              <Typography variant='h6'>Serial Number</Typography>
              <InfoButton name={'Serial Number'} body={SERIAL_NUMBER_HELP} color={this.props.statusColor} />
            </div>
            <TextField
              required
              select
              error={this.state.formError && this.state.serialNumber === ''}
              name="serialNumber"
              label="Serial Number"
              value={this.state.serialNumber}
              style={{ width: (this.props.isMobile ? '100%' : '60%') }}
              onChange={this.onChange}
              variant='filled'
              disabled={this.state.isLoading}>
              {this.state.unassignedHubs.map((hub) => (
                <MenuItem key={hub.getSerialNumber()} value={hub.getSerialNumber()}>
                  {hub.getSerialNumber()}
                </MenuItem>
              ))}
            </TextField>
          </div>

          {/* Hub Type */}
          <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between', marginBottom: '10px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: (this.props.isMobile ? '100%' : '40%') }}>
              <Typography variant='h6'>Hub Type</Typography>
              <InfoButton name={'Hub Type'} body={HUB_TYPE_HELP} color={this.props.statusColor} />
            </div>
            <TextField
              required
              select
              error={this.state.formError && this.state.hubType === ''}
              name="hubType"
              label="Hub Type"
              value={this.state.hubType}
              style={{ width: (this.props.isMobile ? '100%' : '60%') }}
              onChange={this.onChange}
              variant='filled'
              disabled={this.state.isLoading}>
              {Object.entries(HubTypes).map((type) => (
                <MenuItem key={type[1]} value={type[1]}>
                  {type[1]}
                </MenuItem>
              ))}
            </TextField>
          </div>

          {/* Building */}
          <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between', marginBottom: '10px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: (this.props.isMobile ? '100%' : '40%') }}>
              <Typography variant='h6'>Building</Typography>
              <InfoButton name={'Building'} body={'TODO'} color={this.props.statusColor} />
            </div>
            <TextField
              required
              select
              error={this.state.formError && this.state.buildingID === ''}
              name="buildingID"
              label="Building"
              value={this.state.buildingID}
              style={{ width: (this.props.isMobile ? '100%' : '60%') }}
              onChange={this.onChange}
              variant='filled'
              disabled={this.state.isLoading}>
              {this.state.buildings.map((building) => (
                <MenuItem key={building.id} value={building.id}>
                  {building.name}
                </MenuItem>
              ))}
            </TextField>
          </div>

          {/* Floor */}
          <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between', marginBottom: '10px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: (this.props.isMobile ? '100%' : '40%') }}>
              <Typography variant='h6'>Floor</Typography>
              <InfoButton name={'Floor'} body={'TODO'} color={this.props.statusColor} />
            </div>
            <TextField
              required
              select
              error={this.state.formError && this.state.floorID === ''}
              name="floorID"
              label="Floor"
              value={this.state.floorID}
              style={{ width: (this.props.isMobile ? '100%' : '60%') }}
              onChange={this.onChange}
              variant='filled'
              disabled={this.state.isLoading}>
              {this.state.buildings.find(building => building.id === this.state.buildingID)?.getFloors().map((floor) => (
                <MenuItem key={floor.id} value={floor.id}>
                  {floor.identifier}
                </MenuItem>
              ))}
            </TextField>
          </div>

          {/* Assigned Room */}
          {(this.state.hubType === HubTypes.ROOM || this.state.hubType === HubTypes.DOOR) &&
            <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between', marginBottom: '10px' }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: (this.props.isMobile ? '100%' : '40%') }}>
                <Typography variant='h6'>Assigned Room</Typography>
                <InfoButton name={'Assigned Room'} body={ASSIGNED_ROOM_HELP} color={this.props.statusColor} />
              </div>
              <TextField
                required
                select
                error={this.state.formError && this.state.assignedRoomID === ''}
                name="assignedRoomID"
                label="Assigned Room"
                value={this.state.assignedRoomID}
                style={{ width: (this.props.isMobile ? '100%' : '60%') }}
                onChange={this.onChange}
                variant='filled'
                disabled={this.state.isLoading}>
                {this.state.buildings.find(building => building.id === this.state.buildingID)?.getFloors().find(floor => floor.id === this.state.floorID)?.getRooms().map((room) => (
                  <MenuItem key={room.id} value={room.id}>
                    {room.identifier}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          }

          {/* Notes */}
          <div style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between', marginBottom: '10px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: (this.props.isMobile ? '100%' : '40%') }}>
              <Typography variant='h6'>Notes</Typography>
              <InfoButton name={'Notes'} body={NOTES_HELP} color={this.props.statusColor} />
            </div>
            <TextField
              multiline
              error={this.state.formError && this.state.notes === '' && this.state.hubType === 'Hallway'}
              required={this.state.hubType === 'Hallway'}
              rows={4}
              inputProps={{ maxLength: 255 }}
              name="notes"
              label="Notes"
              placeholder="Anything you might need to know about the hub. For example, for a Hallway Hub, you might want to remember where the hub is located."
              value={this.state.notes}
              style={{ width: (this.props.isMobile ? '100%' : '60%') }}
              onChange={this.onChange}
              variant='filled'
              disabled={this.state.isLoading}>
            </TextField>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <Button onClick={this.nextPage /*this.submitForm*/} variant="contained" style={{ width: '200px', height: '50px', margin: '30px', backgroundColor: this.props.statusColor.main, color: this.props.statusColor.text }}>Next</Button>
          </div>
        </>}

        {this.state.pageIndex === 1 &&
        <>
          <MapHubLocationSelector
            x={this.state.x}
            y={this.state.y}
            floorPlanImagePath={this.state.buildings.find(building => building.id === this.state.buildingID)?.floors.find(floor => floor.id === this.state.floorID)?.getFloorPlanImage()}
            onCoordinateSelection={this.state.isLoading ? (() => {}) : ((x, y) => this.setState({ x, y }))}
          />

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <Button onClick={this.prevPage} variant="contained" style={{ width: '200px', height: '50px', margin: '30px', backgroundColor: this.props.statusColor.main, color: this.props.statusColor.text }} disabled={this.state.isLoading}>Back</Button>
            <Button onClick={this.submitForm} variant="contained" style={{ width: '200px', height: '50px', margin: '30px', backgroundColor: this.props.statusColor.main, color: this.props.statusColor.text }} disabled={this.state.isLoading}>Save</Button>
          </div>
        </>}
      </form>
    );
  }
}

const mapStateToProps = state => ({
  statusColor: state.status.schoolStatus ? state.status.schoolStatus.getColor() : DEFAULT,
  isMobile: state.ui.isMobile,
  isFullWidth: state.ui.isFullWidth
});

const mapDispatchToProps = dispatch => ({
  setError: (msg) => setAlert(dispatch, msg),
  setSuccess: (msg) => setSuccess(dispatch, msg),
  clearErrors: () => clearErrors()
});

export default connect(mapStateToProps, mapDispatchToProps)(HubForm);

HubForm.propTypes = {
  setError: PropTypes.func,
  setSuccess: PropTypes.func,
  clearErrors: PropTypes.func,
  isMobile: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  statusColor: PropTypes.object,
  onSuccess: PropTypes.func,
  hub: PropTypes.object
};

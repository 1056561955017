import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
import { clearErrors, setAlert, setSuccess } from '../../../redux/actions/alertActions';

// Icons
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

// Components
import Table from '../../../components/Table';
import LineItem from '../../../components/LineItem';

// API
import { listRedAlertTextGroups, deleteRedAlertTextContact, updateRedAlertTextGroup } from '../../../api';
import { Button, Container, IconButton, Typography, TextField } from '@material-ui/core';
import { DEFAULT } from '../../../constants';

// Constants
const COLUMNS = ({ setupMode, onDelete }) => [
  ...(setupMode ? [{
    title: 'Delete',
    render: rowData => (
      <IconButton onClick={() => onDelete(rowData)}><DeleteIcon /></IconButton>
    ),
    width: null,
    cellStyle: {
      width: 25
    }
  }] : []),
  {
    field: 'name',
    title: 'Name'
  },
  {
    field: 'phoneNumber',
    title: 'Phone Number'
  }
];

class RedAlertTextGroups extends Component {

  constructor(props) {
    super(props);

    this.state = {
      redAlertTextGroups: [],
      messages: [],
    };
  }

  componentDidMount() {
    this.refreshRedAlertTextGroups();
  }

  refreshRedAlertTextGroups = () => {
    listRedAlertTextGroups().then(redAlertTextGroups => {
      this.setState({ redAlertTextGroups, messages: redAlertTextGroups.map(group => group.getMessage()) });
    }).catch(error => {
      this.props.setError(error ?? 'Unable to retrieve red alert text groups');
    });
  }

  onDelete = (contact) => {
    deleteRedAlertTextContact(contact.getID()).then(() => {
      this.refreshRedAlertTextGroups();
    }).catch(error => {
      this.props.setError(error ?? 'Unable to delete red alert text group');
    });
  }

  onSaveMessage = (index) => {
    if (index < 0 || index >= this.state.redAlertTextGroups.length || index >= this.state.messages.length) return;

    this.setState({ isLoading: true });
    updateRedAlertTextGroup(this.state.redAlertTextGroups[index].getID(), { message: this.state.messages[index] }).then(() => {
      this.setState({ isLoading: false });
      this.props.setSuccess('Red alert text message updated');
    }).catch(error => {
      this.props.setError(error ?? 'Unable to update red alert text message');
      this.setState({ isLoading: false });
    });
  }

  render() {
    return (
      <Container style={{ width: '100%' }}>
        <Typography variant='h1'>Red Alert Texts</Typography>

        {this.state.redAlertTextGroups.map((redAlertTextGroup, index) => (
          <div style={{ width: '100%' }} key={index}>
            <Typography variant='h2' style={{ marginTop: '1em', marginBottom: '0.5em' }}>{redAlertTextGroup.getName()}</Typography>
            <Typography variant='body1' style={{ color: 'red', fontWeight: 'bold', textAlign: 'center' }}>Note: Be sure to include the school name in every message.</Typography>
            {this.props.setupMode ? (
              <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '10px' }}>
                <TextField
                  required
                  label='Text Message Content'
                  disabled={this.state.isLoading}
                  style={{ width: '100%', marginRight: '10px' }}
                  onChange={(e) => this.setState({ messages: this.state.messages.map((message, i) => i === index ? e.target.value : message) })}
                  value={this.state.messages[index]}
                  variant='filled'
                  multiline
                  minRows={3}
                />

                <Button
                  variant='contained'
                  disabled={this.state.isLoading}
                  style={{ marginLeft: '10px', backgroundColor: this.props.statusColor.main, color: this.props.statusColor.text, margin: '5px 0px 5px 0px' }}
                  onClick={() => this.onSaveMessage(index)}
                >Save</Button>
              </div>

            ) : (
              <LineItem
                description={'Message'}
                value={redAlertTextGroup.getMessage()}
              />
            )}
            
            <Table
              title={
                !this.props.setupMode ? '' : (
                  <Button disabled={this.state.isLoading} variant='contained' component={Link} to={`/admin/red-alert-text-groups/${redAlertTextGroup.getID()}/add`} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: this.props.statusColor.main, color: this.props.statusColor.text, margin: '5px 0px 5px 0px' }}>
                    Add Contact
                    <AddIcon style={{ marginLeft: '5px' }} />
                  </Button>
                )
              }
              data={redAlertTextGroup.getContacts() ?? []}
              columns={COLUMNS({ setupMode: this.props.setupMode, onDelete: this.onDelete }).map((column) => { /* Workaround for a known material-table bug https://github.com/mbrn/material-table/issues/2451 */
                return { ...column };
              })}
              options={{
                pageSize: 10
              }}
            />
          </div>
        ))}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  setupMode: state.setupMode.mode || state.setupMode.isLoading,
  statusColor: state.status.schoolStatus ? state.status.schoolStatus.getColor() : DEFAULT
});

const mapDispatchToProps = dispatch => ({
  setSuccess: (msg) => setSuccess(dispatch, msg),
  setError: (msg) => {
    dispatch(clearErrors());
    setAlert(dispatch, msg);
  },
  clearErrors: () => dispatch(clearErrors())
});

RedAlertTextGroups.propTypes = {
  setupMode: PropTypes.bool.isRequired,
  statusColor: PropTypes.object.isRequired,
  setError: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(RedAlertTextGroups);

/*
 * Copyright © 2021 Lexcelon LLC <info@lexcelon.com>
 * Licensed for non-distributable use
 */
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

function LineItem({
  value,
  description,
  style,
  ...props
}) {
  return (
    <div style={{ marginTop: '1em', marginBottom: '1em', ...style }} {...props}>
      <Typography variant="body1">
        {value == null || value === '' ? '-----' : value}
      </Typography>
      <Typography variant="overline">{description}</Typography>
    </div>
  );
}

LineItem.propTypes = {
  description: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.any.isRequired
};

export default LineItem;
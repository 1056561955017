export default class RedAlertTextContact {
  static thaw(redAlertTextContactObj) {
    if (redAlertTextContactObj == null) return null;
    const redAlertTextContact = new RedAlertTextContact();
    if (redAlertTextContactObj.id != null) redAlertTextContact.setID(redAlertTextContactObj.id);
    if (redAlertTextContactObj.name != null) redAlertTextContact.setName(redAlertTextContactObj.name);
    if (redAlertTextContactObj.phoneNumber != null) redAlertTextContact.setPhoneNumber(redAlertTextContactObj.phoneNumber);
    if (redAlertTextContactObj.redAlertTextGroupId != null) redAlertTextContact.setRedAlertTextGroupId(redAlertTextContactObj.redAlertTextGroupId);
    return redAlertTextContact;
  }

  static thawList(redAlertTextContactObjList) {
    if (redAlertTextContactObjList == null) return [];
    var redAlertTextContacts = [];
    for (var i = 0; i < redAlertTextContactObjList.length; i++) {
      redAlertTextContacts.push(RedAlertTextContact.thaw(redAlertTextContactObjList[i]));
    }
    return redAlertTextContacts;
  }

  static freeze(redAlertTextContact) {
    if (redAlertTextContact == null) return null;
    var redAlertTextContactObj = {};
    if (redAlertTextContact.getID() != null) redAlertTextContactObj.id = redAlertTextContact.getID();
    if (redAlertTextContact.getName() != null) redAlertTextContactObj.name = redAlertTextContact.getName();
    if (redAlertTextContact.getPhoneNumber() != null) redAlertTextContactObj.phoneNumber = redAlertTextContact.getPhoneNumber();
    if (redAlertTextContact.getRedAlertTextGroupId() != null) redAlertTextContactObj.redAlertTextGroupId = redAlertTextContact.getRedAlertTextGroupId();
    return redAlertTextContactObj;
  }

  static freezeList(redAlertTextContacts) {
    if (redAlertTextContacts == null) return [];
    var redAlertTextContactObjList = [];
    for (var i = 0; i < redAlertTextContacts.length; i++) {
      redAlertTextContactObjList.push(RedAlertTextContact.freeze(redAlertTextContacts[i]));
    }
    return redAlertTextContactObjList;
  }

  constructor() {
    this.setID(null);
    this.setName(null);
    this.setPhoneNumber(null);
    this.setRedAlertTextGroupId(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setName(name) {
    this.name = name;
  }

  setPhoneNumber(phoneNumber) {
    this.phoneNumber = phoneNumber;
  }

  setRedAlertTextGroupId(redAlertTextGroupId) {
    this.redAlertTextGroupId = redAlertTextGroupId;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getName() {
    return this.name;
  }

  getPhoneNumber() {
    return this.phoneNumber;
  }

  getRedAlertTextGroupId() {
    return this.redAlertTextGroupId;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

}

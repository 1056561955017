import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { CheckCircle, Error, Warning, Info } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { DEFAULT } from '../constants';
import { AnnouncementSeverity } from '../redux/actions/announcementActions';

/**
 * @param {object} props
 * @param {string} [props.title]
 * @param {string} [props.message]
 * @param {keyof AnnouncementSeverity} [props.severity] Defaults to 'info'
 * @param {import('../constants').Color} [props.color] Defaults to *DEFAULT*
 * @param {(event: import('react').SyntheticEvent<Element, Event>) => void} [props.onClose]
 */
function AnnouncementBar({ title, message, severity = 'INFO', color = DEFAULT, onClose }) {
  return (
    <Alert
      severity={AnnouncementSeverity[severity]}
      style={{ color: color.text, backgroundColor: color.main, borderRadius: 0 }}
      onClose={onClose}
      iconMapping={{
        error: <Error htmlColor={color.text} />,
        success: <CheckCircle htmlColor={color.text} />,
        warning: <Warning htmlColor={color.text} />,
        info: <Info htmlColor={color.text} />,
      }}
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      {message}
    </Alert>
  );
}

AnnouncementBar.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  severity: PropTypes.string,
  color: PropTypes.object,
  onClose: PropTypes.func
};

export default AnnouncementBar;
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Circle, Line } from 'react-konva';

// Redux
import { connect } from 'react-redux';

// Components
import MapPanel from '../../components/MapPanel';

// Constants
import { DEFAULT } from '../../constants';

function isPointInPolygon(point, polygon) {
  let x = point.x, y = point.y;

  let isInside = false;
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    let xi = polygon[i].x, yi = polygon[i].y;
    let xj = polygon[j].x, yj = polygon[j].y;

    let intersect = ((yi > y) != (yj > y))
        && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
    if (intersect) isInside = !isInside;
  }

  return isInside;
}

function isPointInPolygonList(point, polygonList) {
  for (const polygon of polygonList) {
    if (isPointInPolygon(point, polygon)) {
      return true;
    }
  }
  return false;
}

class MapRoomZoneSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roomPolygons: []
    };
  }

  componentDidMount() {
    this.mapRoomPolygons();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.floor !== this.props.floor) {
      this.mapRoomPolygons();
    }
  }

  mapRoomPolygons = () => {
    if (this.props.floor != null) this.setState({ roomPolygons: this.props.floor.getRooms().map(room => room.getVertices().map(vertex => ({ x: vertex.x, y: vertex.y }))) });
  }

  renderPolygon = (vertices) => {
    if (vertices.length < 3) return null;

    const points = vertices.reduce((acc, vertex) => acc.concat([vertex.x, vertex.y]), []);
    return <Line points={points} fill={this.props.statusColor.light} opacity={0.5} closed stroke={this.props.statusColor.main} strokeWidth={5} />;
  }

  onCoordinateSelection = (x, y) => {
    // Check if coordinate is in an existing room to prevent overlapping rooms
    const point = { x, y };
    if (!isPointInPolygonList(point, this.state.roomPolygons)) this.props.onCoordinateSelection(x, y);
  }

  render() {
    return (
      <MapPanel floorPlanImagePath={this.props.floor?.getFloorPlanImage()} onClick={this.onCoordinateSelection} statusColor={DEFAULT}>
        {/* Other rooms */}
        {this.props.floor?.getRooms().map(room => (
          this.renderPolygon(room.getVertices())
        ))}

        {/* Lines and fill of polygon */}
        {this.renderPolygon(this.props.vertices)}

        {/* Selected vertices */}
        {this.props.vertices.map((vertex, index) => (
          <Circle key={index} x={vertex.x} y={vertex.y} radius={30} fill={this.props.statusColor.dark} />
        ))}
      </MapPanel>
    );
  }
}

const mapStateToProps = state => ({
  windowSize: state.ui.windowSize,
  statusColor: state.status.schoolStatus ? state.status.schoolStatus.getColor() : DEFAULT
});

export default connect(mapStateToProps, null)(MapRoomZoneSelector);

MapRoomZoneSelector.propTypes = {
  floor: PropTypes.object,
  floorPlanImagePath: PropTypes.string,
  onCoordinateSelection: PropTypes.func,
  vertices: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number
  })),
  statusColor: PropTypes.shape({
    main: PropTypes.string,
    text: PropTypes.string,
    light: PropTypes.string,
    dark: PropTypes.string
  })
};

const { RedAlertTextContact } = require('./');

export default class RedAlertTextGroup {
  static thaw(redAlertTextGroupObj) {
    if (redAlertTextGroupObj == null) return null;
    const redAlertTextGroup = new RedAlertTextGroup();
    if (redAlertTextGroupObj.id != null) redAlertTextGroup.setID(redAlertTextGroupObj.id);
    if (redAlertTextGroupObj.name != null) redAlertTextGroup.setName(redAlertTextGroupObj.name);
    if (redAlertTextGroupObj.message != null) redAlertTextGroup.setMessage(redAlertTextGroupObj.message);
    if (redAlertTextGroupObj.contacts != null) redAlertTextGroup.setContacts(RedAlertTextContact.thawList(redAlertTextGroupObj.contacts));
    return redAlertTextGroup;
  }

  static thawList(redAlertTextGroupObjList) {
    if (redAlertTextGroupObjList == null) return [];
    var redAlertTextGroups = [];
    for (var i = 0; i < redAlertTextGroupObjList.length; i++) {
      redAlertTextGroups.push(RedAlertTextGroup.thaw(redAlertTextGroupObjList[i]));
    }
    return redAlertTextGroups;
  }

  static freeze(redAlertTextGroup) {
    if (redAlertTextGroup == null) return null;
    var redAlertTextGroupObj = {};
    if (redAlertTextGroup.getID() != null) redAlertTextGroupObj.id = redAlertTextGroup.getID();
    if (redAlertTextGroup.getName() != null) redAlertTextGroupObj.name = redAlertTextGroup.getName();
    if (redAlertTextGroup.getMessage() != null) redAlertTextGroupObj.message = redAlertTextGroup.getMessage();
    if (redAlertTextGroup.getContacts() != null) redAlertTextGroupObj.contacts = RedAlertTextContact.freeze(redAlertTextGroup.getContacts());
    return redAlertTextGroupObj;
  }

  static freezeList(redAlertTextGroups) {
    if (redAlertTextGroups == null) return [];
    var redAlertTextGroupObjList = [];
    for (var i = 0; i < redAlertTextGroups.length; i++) {
      redAlertTextGroupObjList.push(RedAlertTextGroup.freeze(redAlertTextGroups[i]));
    }
    return redAlertTextGroupObjList;
  }

  constructor() {
    this.setID(null);
    this.setName(null);
    this.setMessage(null);
    this.setContacts(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setName(name) {
    this.name = name;
  }

  setMessage(message) {
    this.message = message;
  }

  setContacts(contacts) {
    this.contacts = contacts;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getName() {
    return this.name;
  }

  getMessage() {
    return this.message;
  }

  getContacts() {
    return this.contacts;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { CircularProgress, Container, Typography } from '@material-ui/core';

// Redux
import { connect } from 'react-redux';
import { setSuccess, clearErrors, setAlert } from '../../../../redux/actions/alertActions';

// API
import { getHub } from '../../../../api';

// Components
import LineItem from '../../../../components/LineItem';
import MapHubLocationSelector from '../../../../components/HubForm/MapHubLocationSelector';

class Hub extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectToHubs: false,
      hub: null,
      isLoading: false
    };
  }

  componentDidMount() {
    const hubID = this.props.match?.params?.id;

    this.setState({ isLoading: true });
    getHub(hubID).then(hub => {
      this.setState({ hub: hub, isLoading: false });
    }).catch(error => {
      this.setState({ isLoading: false });
      this.props.setError(error ? error : 'Error: Unable to retrieve hub.');
    });
  }

  componentWillUnmount() {
    this.props.clearErrors();
  }

  render() {
    return this.state.redirectToHubs || !this.props.setupMode ? <Redirect to='/admin/hubs' /> : (
      <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
          <Typography variant='h1'>Hub</Typography>
          {this.state.isLoading && <CircularProgress style={{ color: this.props.statusColor.main }} />}
        </div>

        {this.state.hub != null &&
        <div style={{ width: '100%' }}>
          <LineItem description='Hub Serial Number' value={this.state.hub.getSerialNumber()} />
          <LineItem description='Hub Type' value={this.state.hub.getHubType()} />
          <LineItem description='Room' value={this.state.hub.getRoom()?.getIdentifier()} />
          <LineItem description='Notes' value={this.state.hub.getNotes()} />

          <MapHubLocationSelector
            x={this.state.hub.getCoordinate()?.getX()}
            y={this.state.hub.getCoordinate()?.getY()}
            floorPlanImagePath={this.state.hub.getRoom()?.getFloor()?.getFloorPlanImage()}
          />
        </div>}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  statusColor: state.status.schoolStatus.getColor(),
  setupMode: state.setupMode.mode || state.setupMode.isLoading
});

const mapDispatchToProps = dispatch => ({
  setError: (msg) => setAlert(dispatch, msg),
  setSuccess: (msg) => setSuccess(dispatch, msg),
  clearErrors: () => dispatch(clearErrors())
});

Hub.propTypes = {
  setError: PropTypes.func,
  setSuccess: PropTypes.func,
  clearErrors: PropTypes.func,
  match: PropTypes.object.isRequired,
  statusColor: PropTypes.object.isRequired,
  setupMode: PropTypes.bool.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Hub));

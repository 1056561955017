import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import { DEFAULT } from '../../../constants';

// Redux
import { connect } from 'react-redux';
import { setSuccess, clearErrors, setAlert } from '../../../redux/actions/alertActions';

// Icons
import ViewIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';

// Components
import ListTable from '../../../components/ListTable';
import ConfirmationModal from '../../../components/ConfirmationModal';

// API
import { listHubs, deleteHub } from '../../../api';

// Constants
const COLUMNS = (setupMode, onDelete) => [
  ...(setupMode ? [
    {
      title: 'View',
      render: rowData => (
        <Link to={'/admin/hubs/' + rowData.getSerialNumber()}><ViewIcon /></Link>
      ),
      width: null,
      cellStyle: {
        width: 25
      }
    },
    {
      title: 'Delete',
      render: rowData => (
        <IconButton onClick={() => onDelete(rowData)}><DeleteIcon /></IconButton>
      ),
      width: null,
      cellStyle: {
        width: 25
      }
    }
  ] : []),
  {
    field: 'serialNumber',
    title: 'Serial Number'
  },
  {
    field: 'hubType',
    title: 'Hub Type'
  },
  {
    title: 'Room Identifier',
    render: rowData => (
      rowData.getRoom()?.getIdentifier()
    ),
    customFilterAndSearch: (term, rowData) => {
      let identifier = rowData?.getRoom()?.getIdentifier()?.toLowerCase();
      return identifier?.toLowerCase()?.includes(term.toLowerCase());
    }
  },
  {
    field: 'notes',
    title: 'Notes'
  },
  {
    render: rowData => (
      rowData.getLastSeenTimestamp()?.toLocaleString({ weekday: 'short', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' })
    ),
    title: 'Last Seen'
  },
  {
    title: 'Firmware Version',
    field: 'firmwareVersion'
  }
];

class Hubs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmDeleteHub: null,
      isLoading: false
    };
  }

  componentWillUnmount() {
    this.props.clearErrors();
  }

  onDelete = (hub) => {
    this.setState({ confirmDeleteHub: hub });
  }

  deleteHub = () => {
    this.setState({ isLoading: true });
    deleteHub(this.state.confirmDeleteHub.getSerialNumber()).then(() => {
      this.setState({ isLoading: false, confirmDeleteHub: null });
      this.props.setSuccess('Hub deleted successfully.');
      // Temp: Refresh page
      window.location.reload();
    }).catch(error => {
      this.setState({ isLoading: false });
      this.props.setError(error ?? 'Error: Unable to delete hub.');
    });
  }

  render() {
    return (
      <>
        <ConfirmationModal
          open={this.state.confirmDeleteHub != null}
          onClose={() => this.setState({ confirmDeleteHub: null })}
          onConfirm={this.deleteHub}
          color={this.props.statusColor}
          title={`Are you sure you want to delete hub ${this.state.confirmDeleteHub?.getSerialNumber()}?`}
          body={'The hub will no longer display or accept statuses.'}
          isLoading={this.state.isLoading}
        />

        <ListTable
          title='Hubs'
          buttonLink='/admin/hubs/assign'
          buttonText='Add New'
          columns={COLUMNS(this.props.setupMode, this.onDelete)}
          retrievalFunction={listHubs}
          hideButton={!this.props.setupMode}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  setupMode: state.setupMode.mode || state.setupMode.isLoading,
  statusColor: state.status.schoolStatus ? state.status.schoolStatus.getColor() : DEFAULT,
});

const mapDispatchToProps = dispatch => ({
  setError: (msg) => {
    dispatch(clearErrors());
    setAlert(dispatch, msg);
  },
  setSuccess: (msg) => setSuccess(dispatch, msg),
  clearErrors: () => dispatch(clearErrors())
});

Hubs.propTypes = {
  setupMode: PropTypes.bool.isRequired,
  statusColor: PropTypes.string.isRequired,
  setError: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(Hubs);

import React from 'react';
import { Dialog, DialogTitle, DialogActions, Typography, Button } from '@material-ui/core';
import PropTypes from 'prop-types';

// Constants
import { GREY, DEFAULT } from '../constants';

export default function ConfirmStatusModal({ closeModal, showModal, setStatus, desiredStatus, clearStatus }) {
  return (
    <Dialog
      open={showModal}
      onClose={closeModal}
      disableBackdropClick
      maxWidth={'xs'}
      style={{ padding: '15px' }}
    >
      {desiredStatus != null ?
        <DialogTitle><Typography variant="h4" style={{ textAlign: 'center' }}>Confirm <strong style={{ color: desiredStatus.color.main }}>{desiredStatus.name}</strong> Alert to entire school?</Typography></DialogTitle> :
        <DialogTitle><Typography variant="h4" style={{ textAlign: 'center' }}>Confirm clearing of statuses for entire school?</Typography></DialogTitle>
      }

      <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={closeModal} style={{ backgroundColor: GREY.main, color: GREY.text }}>
          Cancel
        </Button>
        {desiredStatus != null ?
          <Button variant="contained" onClick={() => setStatus(desiredStatus)} style={{ color: desiredStatus.color.text, backgroundColor: desiredStatus.color.main }}>Yes</Button> :
          <Button variant="contained" onClick={() => clearStatus()} style={{ color: DEFAULT.text, backgroundColor: DEFAULT.main }}>Yes</Button>
        }
      </DialogActions>
    </Dialog>
  );
}

ConfirmStatusModal.propTypes = {
  showModal: PropTypes.bool,
  closeModal: PropTypes.func,
  setStatus: PropTypes.func,
  desiredStatus: PropTypes.object,
  clearStatus: PropTypes.func
};
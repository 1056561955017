import React, { Component } from 'react';
import { Checkbox, Typography, TextField, Button, CircularProgress, Switch } from '@material-ui/core';
import PropTypes from 'prop-types';

// Components
import InfoButton from '../../components/InfoButton';
import ConfirmationModal from '../../components/ConfirmationModal';

// Redux
import { connect } from 'react-redux';
import { setSuccess, setAlert } from '../../redux/actions/alertActions';
import { checkSetupMode, turnOffSetupMode, turnOnSetupMode } from '../../redux/actions/setupModeActions';
import { AlertTypes } from '../../constants';

// Nav
import { getAdminSettings, patchAdminSettings, getSetupMode, toggleSetupMode } from '../../api';

const SETUP_MODE_INFO = 'This field determines if the SimulAlert application is in setup mode or not. If in setup mode, you are able to make system changes. Do not turn this on unless you are prepared for a significant vulnerability in the system.';

class EditAssignment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      settings: null,
      isLoading: false,
      updatedSettings: {},
      showConfirmationModal: false,
      setupMode: false,
      showSetupModeConfirmationModal: false
    };

    this.loadAdminSettings = this.loadAdminSettings.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.loadAdminSettings();

    getSetupMode().then((data) => {
      this.setState({
        setupMode: data.setupMode
      });
    }).catch(error => this.props.setError(error ? error : 'Error: Unable to retrieve setup mode.'));
  }

  loadAdminSettings() {
    getAdminSettings().then((settings) => {
      this.setState({
        settings: settings
      });
    })
      .catch(error => {
        this.props.setError(error ? error : 'Unable to retrieve settings.');
      });
  }

  onChange = (e) => {
    e.preventDefault();

    var updatedSettings = this.state.updatedSettings;
    updatedSettings[e.target.name] = this.state.settings.find(setting => setting.name === e.target.name).formType === 'Checkbox' ? (e.target.checked ? 1 : 0) : e.target.value;

    this.setState({ updatedSettings: updatedSettings });
  };

  onSetupModeChange = (e) => {
    e.preventDefault();
    this.setState({ showSetupModeConfirmationModal: true });
  }

  toggleSetupMode = () => {
    const setupMode = !this.state.setupMode;
    this.setState({ isLoading: true });
    toggleSetupMode(setupMode).then(() => {
      this.props.setSuccess('Successfully turned setup mode ' + (setupMode ? 'ON' : 'OFF'));
      this.setState({ isLoading: false, showSetupModeConfirmationModal: false, setupMode });
      if (setupMode) this.props.turnOnSetupMode();
      else this.props.turnOffSetupMode();
    }).catch(error => {
      this.props.setError(error ? error : 'Error: Unable to turn setup mode ' + (setupMode ? 'ON' : 'OFF'));

      getSetupMode().then((data) => {
        this.setState({
          setupMode: data.setupMode,
          isLoading: false
        });
        this.props.checkSetupMode();
      }).catch(error => {
        this.props.setError(error ? error : 'Error: Unable to retrieve setup mode.');
        this.setState({ isLoading: false });
        this.props.checkSetupMode();
      });
    });
  }

  submitChanges = () => {
    this.setState({ isLoading: true });
    patchAdminSettings(this.state.updatedSettings).then((res) => {
      if (res && res.data) {
        this.props.setSuccess(res.data);
        this.loadAdminSettings();
        this.setState({ updatedSettings: {} });
      }
      else {
        this.props.setError('Something went wrong. Please try again.');
      }
      this.setState({ isLoading: false, showConfirmationModal: false, updatedSettings: {} });
    })
      .catch((error) => {
        this.props.setError(error ? error : 'Error: Unable to save assignment.');
        this.setState({ isLoading: false, showConfirmationModal: false });
      });
  }

  render() {
    if (this.state.settings == null) {
      return null;
    }

    return (
      <>
        {/* Change Setup Mode */}
        <ConfirmationModal
          open={this.state.showSetupModeConfirmationModal}
          onClose={() => this.setState({ showSetupModeConfirmationModal: false })}
          onConfirm={this.toggleSetupMode}
          color={this.props.schoolAlertStatus.color}
          title={'Are you sure you want to ' + (this.state.setupMode ? 'turn off' : 'turn on') + ' setup mode?'}
          body={this.state.setupMode ? 'You will not be able to make any more changes to the system until setup mode has turned back on. Badge statuses will start to be accepted immediately.' : 'This is a security vulnerability. Alerts will no longer be accepted by badges until setup mode is turned back off. Please be sure to turn on setup mode at an appropriate time to ensure the safety of staff and students.'}
          isLoading={this.state.isLoading}
        />

        {/* Update Settings */}
        <ConfirmationModal
          open={this.state.showConfirmationModal}
          onClose={() => this.setState({ showConfirmationModal: false })}
          onConfirm={this.submitChanges}
          color={this.props.schoolAlertStatus.color}
          title={'Are you sure you want to make these changes?'}
          isLoading={this.state.isLoading}
        />

        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Typography variant='h3' style={{ margin: '25px 20px 50px 20px', textAlign: 'center' }}>Update Platform Settings</Typography>

          <div style={{ width: (this.props.isFullWidth ? '50%' : '90%'), display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between', border: '5px solid ' + this.props.schoolAlertStatus.color.main, padding: '1em', marginBottom: '2em' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: (this.props.isMobile ? '100%' : '40%') }}>
              <Typography variant='h6'>Setup Mode</Typography>
              <InfoButton name={'Setup Mode'} body={SETUP_MODE_INFO} color={this.props.schoolAlertStatus.color} />
            </div>
            <Switch
              checked={this.state.setupMode}
              onChange={this.onSetupModeChange}
              name={'SETUP_MODE'}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </div>

          <form noValidate autoComplete="off" style={{ width: (this.props.isFullWidth ? '50%' : '90%') }}>
            {this.state.settings && this.state.settings.map((setting, index) =>
              <div key={index} style={{ display: 'flex', flexDirection: (this.props.isMobile ? 'column' : 'row'), alignItems: (this.props.isMobile ? 'flex-start' : 'center'), justifyContent: 'space-between', marginBottom: '10px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: (this.props.isMobile ? '100%' : '40%') }}>
                  <Typography variant='h6'>{setting.friendlyName}</Typography>
                  <InfoButton name={setting.friendlyName} body={setting.description} color={this.props.schoolAlertStatus.color} />
                </div>
                {setting.formType === 'Checkbox' ?
                  <Checkbox
                    onChange={this.onChange}
                    name={setting.name}
                    defaultChecked={setting.value ? true : false}
                  /> :
                  <TextField
                    disabled={!this.state.setupMode && setting.setupModeOnly ? true : false}
                    name={setting.name}
                    type={setting.formType}
                    defaultValue={setting.value}
                    style={{ width: (this.props.isMobile ? '100%' : '60%') }}
                    onChange={this.onChange}
                    variant='filled'
                  />}
              </div>
            )}

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <Button onClick={() => this.setState({ showConfirmationModal: true })} variant="contained" style={{ width: '200px', height: '50px', margin: '30px', color: this.props.schoolAlertStatus.color.text, backgroundColor: this.props.schoolAlertStatus.color.main }} disabled={this.state.isLoading || !this.props.setupMode}>
                Save Changes
                {this.state.isLoading && <CircularProgress size={25} style={{ color: this.props.schoolAlertStatus.color.text, marginLeft: '1em' }} />}
              </Button>
            </div>
          </form>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  schoolAlertStatus: state.status.schoolStatus || AlertTypes.UNKNOWN,
  isMobile: state.ui.isMobile,
  setupMode: state.setupMode.mode || state.setupMode.isLoading
});

const mapDispatchToProps = (dispatch) => ({
  setError: (msg) => setAlert(dispatch, msg),
  setSuccess: (msg) => setSuccess(dispatch, msg),
  checkSetupMode: () => checkSetupMode(dispatch),
  turnOnSetupMode: () => turnOnSetupMode(dispatch),
  turnOffSetupMode: () => turnOffSetupMode(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAssignment);

EditAssignment.propTypes = {
  setError: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  checkSetupMode: PropTypes.func.isRequired,
  turnOnSetupMode: PropTypes.func.isRequired,
  turnOffSetupMode: PropTypes.func.isRequired,
  schoolAlertStatus: PropTypes.object,
  isMobile: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  setupMode: PropTypes.bool.isRequired
};

export default class Coordinate {
  static thaw(coordinateObj) {
    if (coordinateObj == null) return null;
    const coordinate = new Coordinate();
    if (coordinateObj.id != null) coordinate.setID(coordinateObj.id);
    if (coordinateObj.x != null) coordinate.setX(coordinateObj.x);
    if (coordinateObj.y != null) coordinate.setY(coordinateObj.y);
    return coordinate;
  }

  static thawList(coordinateObjList) {
    if (coordinateObjList == null) return [];
    var coordinates = [];
    for (var i = 0; i < coordinateObjList.length; i++) {
      coordinates.push(Coordinate.thaw(coordinateObjList[i]));
    }
    return coordinates;
  }

  static freeze(coordinate) {
    if (coordinate == null) return null;
    var coordinateObj = {};
    if (coordinate.getID() != null) coordinateObj.id = coordinate.getID();
    if (coordinate.getX() != null) coordinateObj.x = coordinate.getX();
    if (coordinate.getY() != null) coordinateObj.y = coordinate.getY();
    return coordinateObj;
  }

  static freezeList(coordinates) {
    if (coordinates == null) return [];
    var coordinateObjList = [];
    for (var i = 0; i < coordinates.length; i++) {
      coordinateObjList.push(Coordinate.freeze(coordinates[i]));
    }
    return coordinateObjList;
  }

  constructor() {
    this.setID(null);
    this.setX(null);
    this.setY(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setX(x) {
    this.x = x;
  }

  setY(y) {
    this.y = y;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getX() {
    return this.x;
  }

  getY() {
    return this.y;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

}

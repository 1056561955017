import React, { Component } from 'react';
import {
  Container, Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Components
import StaffMemberForm from '../../../../components/StaffMemberForm';

// Constants
import { DEFAULT } from '../../../../constants';

class CreateStaffMember extends Component {

  constructor(props) {
    super(props);

    this.state = {
      redirectToStaff: false
    };
  }

  render() {
    return this.state.redirectToStaff || !this.props.setupMode ? <Redirect to='/admin/staff' /> : (
      <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant='h1'>Add a Staff Member</Typography>
        <StaffMemberForm onSuccess={() => this.setState({ redirectToStaff: true })} />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  statusColor: state.status.schoolStatus ? state.status.schoolStatus.getColor() : DEFAULT,
  isMobile: state.ui.isMobile,
  isFullWidth: state.ui.isFullWidth,
  setupMode: state.setupMode.mode || state.setupMode.isLoading
});

export default connect(mapStateToProps, null)(CreateStaffMember);

CreateStaffMember.propTypes = {
  clearErrors: PropTypes.func,
  statusColor: PropTypes.object,
  isMobile: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  setupMode: PropTypes.bool.isRequired
};

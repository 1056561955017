import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { CircularProgress, Container, Typography } from '@material-ui/core';

// Redux
import { connect } from 'react-redux';
import { setSuccess, clearErrors, setAlert } from '../../../../redux/actions/alertActions';

// API
import { getStaffMember } from '../../../../api';

// Components
import StaffMemberForm from '../../../../components/StaffMemberForm';

class StaffMember extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectToStaff: false,
      staffMember: null,
      isLoading: false
    };
  }

  componentDidMount() {
    const staffMemberID = this.props.match?.params?.id;

    this.setState({ isLoading: true });
    getStaffMember(staffMemberID).then(staffMember => {
      this.setState({ staffMember: staffMember, isLoading: false });
    }).catch(error => {
      this.setState({ isLoading: false });
      this.props.setError(error ? error : 'Error: Unable to retrieve staff member.');
    });
  }

  render() {
    return this.state.redirectToStaff || !this.props.setupMode ? <Redirect to='/admin/staff' /> : (
      <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
          <Typography variant='h1'>Update Staff Member</Typography>
          {this.state.isLoading && <CircularProgress style={{ color: this.props.statusColor.main }} />}
        </div>

        {this.state.staffMember != null &&
        <StaffMemberForm onSuccess={() => this.setState({ redirectToStaff: true })} staffMember={this.state.staffMember} />}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  statusColor: state.status.schoolStatus.getColor(),
  setupMode: state.setupMode.mode || state.setupMode.isLoading
});

const mapDispatchToProps = dispatch => ({
  setError: (msg) => setAlert(dispatch, msg),
  setSuccess: (msg) => setSuccess(dispatch, msg),
  clearErrors: () => dispatch(clearErrors())
});

StaffMember.propTypes = {
  setError: PropTypes.func,
  setSuccess: PropTypes.func,
  clearErrors: PropTypes.func,
  setupMode: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  statusColor: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StaffMember));
